import React from 'react';
import cn from 'classnames';

// Components
import { Navigation } from '../../layout';

// Types
import { MainLayoutPropTypes } from './types';

export const MainLayout: React.FC<MainLayoutPropTypes> = ({ 
  children,
  className,
  theme = 'white',
  topMessage,
}) => (
  <React.Fragment>
    <div className="fixed top-0 left-0 right-0 z-30">
      {topMessage}
      <Navigation theme={theme} />
    </div>
    <div className={cn(className, "px-4 lg:px-8", {
      "pt-[64px] md:pt-[72px]": !topMessage,
      "pt-[182px] md:pt-[136px]": topMessage,
    })}>
      <div className="py-5 h-full">
        {children}
      </div>
    </div>
  </React.Fragment>
);