export const hideStringWithStars = (str: string) => {
  // Determine the length of the ID part that needs to be hidden
  const totalLength = str.length;
  const hideLength = Math.floor(totalLength / 2);

  // Get the start and end positions of the hidden part
  const start = Math.floor(totalLength / 4);
  const end = start + hideLength;

  // Hide the middle of the ID with '*' signs
  return `${str.substring(0, start)}${"*".repeat(hideLength)}${str.substring(
    end
  )}`;
};

type BrowserType = "Chrome" | "Safari" | "Other";
export function detectBrowser(): BrowserType {
  const userAgent: string = navigator.userAgent;

  // Check if browser is Chrome
  if (userAgent.match(/chrome|chromium|crios/i)) {
    return "Chrome";
  }
  // Check if browser is Safari
  else if (
    userAgent.match(/safari/i) &&
    !userAgent.match(/chrome|chromium|crios/i)
  ) {
    return "Safari";
  } else {
    return "Other";
  }
}

export const readCanisterId = (): string => {
  const setupJs = document.querySelector(
    "[data-canister-id]"
  ) as HTMLElement | null;
  if (!setupJs || setupJs.dataset.canisterId === undefined) {
    console.error("canisterId is undefined"); // TODO: abort further execution of this script
    return "aaaaa-aa";
  }

  return setupJs.dataset.canisterId;
};

export const truncateMiddle = (
  str: string,
  numCharsToRemove: number
): string => {
  const strLength = str.length;

  if (numCharsToRemove >= strLength) {
    return str;
  }

  const keepChars = strLength - numCharsToRemove;
  const halfKeepChars = Math.ceil(keepChars / 2);

  const start = str.substring(0, halfKeepChars);
  const end = str.substring(strLength - halfKeepChars);

  return `${start}......${end}`;
};

export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const formatNumberWithSpaces = (number: number) =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
