// import { useSiweIdentity } from "ic-use-siwe-identity";
// import { useAccount, useDisconnect } from "wagmi";
import { useInternetIdentity } from '../contexts/InternetIdentityContext';
import { Identity } from '@dfinity/agent';
import { useEffect, useState } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { logdna } from '../utils/logger';

interface UseIdentityReturn {
  principal: string | null;
  identity: Identity | null;
  isConnected: boolean;
  isInitializing: boolean;
  disconnect: () => Promise<void>;
  deviceId: string | null;
}

// function useEthereum() {
//   const { isConnected: e_isConnected } = useAccount();
//   const {
//     identity: e_identity,
//     clear: e_clear,
//     isInitializing: e_isInitializing,
//   } = useSiweIdentity();
//   const { disconnect: e_disconnect } = useDisconnect();

//   const disconnect = async () => {
//     await e_clear();
//     await e_disconnect();
//   };

//   return {
//     isConnected: e_isConnected,
//     identity: e_identity,
//     isInitializing: e_isInitializing,
//     disconnect,
//   };
// }


export function useIdentity(): UseIdentityReturn {
  // const {
  //   isConnected: e_isConnected,
  //   identity: e_identity,
  //   isInitializing: e_isInitializing,
  //   disconnect: e_disconnect,
  // } = useEthereum();

  const {
    identity: ii_identity,
    disconnect: ii_disconnect,
    isInitializing: ii_isInitializing,
    isConnected: ii_isConnected,
  } = useInternetIdentity();

  const identity = ii_identity; // || e_identity;
  const principal = identity && identity.getPrincipal().toText();

  const [deviceId, setDeviceId] = useState<string | null>(null);

  const disconnect = async () => {
    await ii_disconnect();
    //await e_disconnect();
    logdna.addContext({
      user: {
        principal: null,
      },
    });
  };

  useEffect(() => {
    if (principal) {
      logdna.addContext({
        user: {
          principal: principal,
        },
      });
    }
  }, [principal]);

  useEffect(() => {
    const loadDeviceId = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      setDeviceId(result.visitorId);
    };

    loadDeviceId();
  }, []);

  return {
    identity,
    principal,
    isConnected: ii_isConnected, // || e_isConnected
    isInitializing: ii_isInitializing, // || e_isInitializing
    deviceId,
    disconnect,
  };
}
