import React, { useState } from 'react';
import cn from 'classnames';

// Components
import { 
  DynamicIcon, 
  getColorByName,
} from '@modclub-app/uikit';
import { InfoPopup } from './InfoPopup';
import { Portal, Modal, Tooltip } from '../../uikit';

// Data
import { DATA_COMMON } from '../../../data/dataCommon';

export const openSpring = { type: "spring", stiffness: 200, damping: 30 };
export const closeSpring = { type: "spring", stiffness: 300, damping: 35 };

export const AboutDecideId: React.FC<{ color?: string; }> = ({ 
  color 
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const mainColor = color === 'white' ? 'C100' : 'F00';
  const { tooltip } = DATA_COMMON.about;

  const onHandlerClick = () => setIsOpen(!isOpen);

  return (
    <React.Fragment>
      <button
        onClick={onHandlerClick}
        className={cn(
          'flex gap-2 items-center justify-center',
          'hover:underline hover:text-C100'
        )}
      >
        <Tooltip text={tooltip}>
          <DynamicIcon
            iconName="DangerCircleIcon"
            stroke={getColorByName(mainColor)?.hex}
          />
        </Tooltip>
      </button>

      {isOpen && (
        <Portal>
          <Modal closeModal={onHandlerClick}>
            <InfoPopup />
          </Modal>
        </Portal>
      )}
    </React.Fragment>
  );
}