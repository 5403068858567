import React from "react";

// Components
import { Grid } from '../../layout';
import { Typography } from '@decide-ai/uikit';
import { AboutDecideId } from '../../uikit';
import { PictureDecor } from './PictureDecor';

// Data
import { DATA_COMMON } from "../../../data/dataCommon";

export const AuthProfileLayout: React.FC<{ children: React.ReactNode }> = ({ 
  children 
}) => (
  <div className='p-4 lg:p-2.5 h-screen'>
    <Grid>
      <div className='hidden lg:block lg:col-span-3'>
        <PictureDecor />
      </div>

      <div className='relative col-span-12 lg:col-span-9 flex items-center justify-center'>
        <div className='absolute top-0 right-0 lg:top-5 lg:right-5'>
          <AboutDecideId />
        </div>

        {children}

        <div className='absolute bottom-0 w-full flex justify-between px-5 lg:px-5'>
          <div>
            {process.env.DEV_ENV != "prod" && process.env.DEV_ENV != "production" && (
              <Typography 
                size="sm"
                color="F00"
                weight="light"
              >
                {`Deployment tag : ${process.env.DEPLOYMENT_TAG}`}
              </Typography>
            )}
          </div>
          <Typography 
            size="sm"
            color="F00"
            weight="light"
          >
            {DATA_COMMON.copyright}
          </Typography>
        </div>
      </div>
    </Grid>
  </div>
);