import { useEffect, useRef } from 'react';

// API
import { pollWithExponentialBackoff } from '../../../utils/pollWithExponentialBackoff';

// Hooks
import { useProfile } from '../../../hooks/useProfile';

// Constants
import { 
  EXPONENTIAL_BACKOFF_BASE_DELAY,
  EXPONENTIAL_BACKOFF_MAX_DELAY
} from '../../../utils/constant';

type PollRefType = {
  cancel: () => void;
};

export const useUpdateProfileStatus = () => {
  const { hardRefreshProfileCache, account } = useProfile();
  const pollRef = useRef<PollRefType | null>(null);

  useEffect(() => {
    let stepName = '';

    if (account?.currentOnboardingStep) {
      stepName = Object.keys(account?.currentOnboardingStep)[0];
    }
    
    const pollFunction = async () => {
      if (stepName === 'poh_uniqueness') {
        await hardRefreshProfileCache();
        return true;
      } else {
        return false;
      }
    };

    // Clear polling when dependencies change or component unmounts
    if (pollRef.current) {
      pollRef.current.cancel();
    }

    pollRef.current = pollWithExponentialBackoff(
      pollFunction, 
      EXPONENTIAL_BACKOFF_BASE_DELAY, 
      EXPONENTIAL_BACKOFF_MAX_DELAY
    );
    
    // Clearing the pool when unmounting a component
    return () => {
      if (pollRef.current) {
        pollRef.current.cancel();
      }
    }
  }, [account, hardRefreshProfileCache]);
}