import React from 'react';
import { motion } from 'framer-motion';

// Components
import { Typography } from '@modclub-app/uikit';

export const ProgressBar: React.FC<{ 
  text?: string 
}> = ({ text }) => (
  <div className="w-full flex flex-col gap-5 items-center justify-center">
    {text && (
      <Typography size="sm" color="F00">
        {text}
      </Typography>
    )}
    <div className="relative w-full h-2 bg-F110 rounded-full overflow-hidden">
      {[...Array(5)].map((_, index) => (
        <motion.div
          key={index}
          className="absolute w-full h-2 bg-F00 transform rotate-45"
          initial={{ x: '-100%' }}
          animate={{ x: '100%' }}
          transition={{
            repeat: Infinity,
            duration: 2,
            delay: index * 0.4,
          }}
          style={{ top: `${index * 4}rem` }}
        />
      ))}
    </div>
  </div>
)