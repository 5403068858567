import { useMemo } from 'react';
import { useProfile } from '../../../hooks/useProfile';
import { getAccountStatus } from "../../../utils/accout_util";
import { useTranslation } from 'react-i18next';

type InfoItemsList = {
  token?: string;
  status?: string;
}

export const useInfoItemsList = (): {
  infoItemsList: InfoItemsList,
    } => {
  const { isLoading, profile, account, principal } = useProfile();
  const { t } = useTranslation();

  const infoItemsList: InfoItemsList = useMemo<InfoItemsList>(() => {
    if (isLoading || !profile) return {};

    let items: InfoItemsList = {
      token: principal || '',
    };

    if (account) {
      const status = getAccountStatus(account.state, account.currentOnboardingStep);
      items = {
        ...items,
        status,
      };

      if ('rejected' in account.state) {
        let rejectReason = '';
        if (account.rejectReason && account.rejectReason.length > 0) {
          const reason = Object.keys(account.rejectReason[0])[0];
            switch (reason) {
              case 'failed_duplicate':
                rejectReason = '';
                break;
              case 'failed_age_policy':
                rejectReason = `: ${t('profile_status.failed_age_policy')}`;
                break;
              default:
                rejectReason = `: ${t('profile_status.unknown_error')}`;
          }
        }
        items.status = `${t('profile_status.failed')}${rejectReason}`;
      }

    }

    return items;
  }, [isLoading, profile, account, principal]);

  return {
    infoItemsList
  };
};