export const createHeaders = (
  additionalHeaders: { [key: string]: string } = {}
): HeadersInit => {
  const headers: { [key: string]: string } = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...additionalHeaders,
  };

  Object.keys(headers).forEach((key) => {
    if (headers[key] === undefined) {
      delete headers[key];
    }
  });

  return headers as HeadersInit;
};

export const createRequestOptions = (
  method: string,
  headers: HeadersInit,
  body: object
): RequestInit => ({
  method,
  headers,
  body: JSON.stringify(body),
});

export const handleResponse = async (response: Response) => {
  if (!response.ok) {
    // Try to get the response body as text or JSON
    const errorText = await response.text();
    throw new Error(`[${response.status}]  Message: ${errorText}`);
  }
  return response.json();
};

export const fetchStandard = async ({
  endpoint,
  method = "GET",
  body = {},
  additionalHeaders = {},
}: {
  endpoint: string;
  method?: string;
  body?: object;
  additionalHeaders?: { [key: string]: string };
}) => {
  try {
    const headers = createHeaders(additionalHeaders);
    const requestOptions: RequestInit = {
      method,
      headers,
      // Add body only if method is not GET or HEAD
      ...(method !== "GET" &&
        method !== "HEAD" && { body: JSON.stringify(body) }),
    };

    const response = await fetch(endpoint, requestOptions);
    return handleResponse(response);
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};
