export type InputField = {
  label: string;
  placeholder: string;
  name: string;
  value: string;
};

export type FormFields = {
  [key: string]: InputField;
};

export type Balance = {
  DCD: number | undefined;
  ICP: number | undefined;
}

export enum ValidationError {
  None = "",
  WalletRequired = "Wallet address is required.",
  AmountRequired = "Amount is required.",
  AmountInvalid = "Amount must be a positive number greater than zero.",
  AmountExceedsBalance = "Amount exceeds the available balance.",
  InsufficientBalance = "Insufficient balance. Unable to proceed.",
}