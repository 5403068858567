import React from "react";
import cn from 'classnames';

// Components
import { Typography, DangerTriangleIcon, getColorByName } from "@modclub-app/uikit";

// Types
import { MessagePlatePropTypes } from './types';

export const MessagePlate: React.FC<MessagePlatePropTypes> = ({ 
  title,
  alertText,
  children,
  position = "start",
}) => (
  <div className={cn(
    "bg-E00 flex flex-col justify-start md:flex-row md:items-center p-4 gap-4 md:gap-6", {
      "lg:justify-center": position === "center",
      "lg:justify-between": position === "between",
      "lg:justify-start": position === "start",
      "lg:justify-end": position === "end",
    }
  )}>
    <div className="flex items-center gap-2">
      <div className="bg-F00 rounded-full p-1.5">
        <DangerTriangleIcon 
          stroke={getColorByName('C00')?.hex} 
        />
      </div>
      <div className="flex flex-col">
        <Typography color="F00" size="base" weight="bold">{title}</Typography>
        <Typography color="F00" size="xs">{alertText}</Typography>
      </div>
    </div>
    {children}
  </div>
);