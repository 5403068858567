import { useNavigate } from "react-router-dom";
import { useProfile } from "./useProfile";
import { useIdentity } from "./useIdentity";

export const useLogout = () => {
  const navigate = useNavigate();

  // step 1. clear profile
  const { disconnect } = useIdentity();
  const { clearProfile } = useProfile();

  // step 2. disconnect identity from provider, II/Ethereum wallets/etc..
  const logout = async () => {
    clearProfile();
    await disconnect();
    navigate("/");
  };

  return logout;
};