export const DATA_NEW_PROFILE = {
  title: "Create New Profile",
  text: "By clicking I agree, you acknowledge the terms of service and privacy policy of Decide ID.",
  agreeText:
    'I agree to <a href="https://decideai.xyz/decide-id-terms" target="_blank">Terms of Service</a> and our <a href="https://decideai.xyz/decide-id-privacy" target="_blank">Privacy Policy</a>',
  formEmail: null,
  buttonSubmin: {
    buttonText: "Create profile",
    iconName: "ArrowRightIcon",
  },
  aboutDecideId: {
    title: "Do you know about Decide ID?",
    text: "Decide ID is the unique identifier we use to securely manage your account details and preferences. More information on how and why we use Decide ID will be available in the main dashboard.",
  },
};
