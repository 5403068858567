import React from 'react';

// Components
import { Typography } from '@modclub-app/uikit';

// Hooks
import { useSetInfoText } from '../customHooks/useSetInfoText';

type InfoTypes = {
  isLoading: boolean;
  isError: boolean;
}

export const Info: React.FC<InfoTypes> = ({ 
  isLoading, 
  isError 
}) => {
  const { title, subTitle } = useSetInfoText(isLoading, isError);
  return (
    <React.Fragment>
      <Typography color="F00" size="5xl" weight="medium">
        {title}
      </Typography>
      
      {subTitle && (
        <Typography color="F00" size="base">
          {subTitle}
        </Typography>
      )}
    </React.Fragment>
  );
}