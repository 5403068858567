import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

// Hooks
import { HandleTransferTokenErrorFunction, useWallet } from '../../../hooks/useWallet';

// Components
import { PaymentModal } from '../PaymentModal';
import { useIdentity } from "../../../hooks/useIdentity";
import { useConfig } from "../../../hooks/useConfig";
import { SuccessModalStep } from './SuccessModalStep';
import { WarningInfoStep } from './WarningInfoStep';
import { ModalLayout } from './ModalLayout';

export const ReverificationPaymentModal = () => {
  const [currentStep, setCurrentStep] = useState<'warning' | 'payment'>('warning');
  const { principal } = useIdentity();
  const { config } = useConfig();
  const { t } = useTranslation();

  const transferPohFeeError : HandleTransferTokenErrorFunction = (
    error: Error, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _variables: "DCD" | "ICP", 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _context: unknown
  ) => {  
    // TODO: MOD-1330 properly handle transferPohFeeError
    window.alert(error);
  };

  const { 
    balance, 
    refresh, 
    transferPohReverificationFee, 
    transferPohReverificationFeeStatus 
  } = useWallet(transferPohFeeError);

  return (
    <ModalLayout>
      {currentStep === 'warning' && (
        <WarningInfoStep 
          onClick={() => setCurrentStep('payment')} 
        />
      )}

      {currentStep === 'payment' && (
        <React.Fragment>
          {transferPohReverificationFeeStatus === 'success' ? (
            <SuccessModalStep />
          ) : (
            <PaymentModal 
              balance={balance?.DCD}
              onRefresh={refresh}
              onClickProcessPayment={transferPohReverificationFee}
              isProcessingPayment={transferPohReverificationFeeStatus === 'pending'}
              walletAddress={principal}
              options={[{ DCD: config?.poh_reverification_fee ?? -1, ICP: undefined }]}
              title={t('app.components.smartkit.reverification_payment_modal.title')}
              subTitle={t('app.components.smartkit.reverification_payment_modal.sub_title')}
              alertText={t('app.components.smartkit.reverification_payment_modal.alert_text')}
            />
          )}
        </React.Fragment>
      )}
    </ModalLayout>
  );
}