// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  color: var(--color-F00);
  background: var(--color-H110);
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./app/styles/global.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;;AAEA;EACE,UAAA;EACA,SAAA;EAEA,uBAAA;EACA,6BAAA;EAEA,kBAAA;EACA,gBAAA;EACA,eAAA;AADF","sourcesContent":["html {\n  scroll-behavior: smooth;\n}\n\nbody {\n  padding: 0;\n  margin: 0;\n  \n  color: var(--color-F00);\n  background: var(--color-H110);\n\n  font-style: normal;\n  font-weight: 400;\n  font-size: 1rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
