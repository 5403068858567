import React from 'react';

type LayoutTypes<
  Props = Record<string, never | React.ReactNode>
> = React.FC<Props & { children: React.ReactNode }>;

export const VerificationEmailLayout: LayoutTypes & {
  Wrapper: LayoutTypes;
  Logo: LayoutTypes;
  Info: LayoutTypes;
  SocialButtons: LayoutTypes;
  RadialGradient: LayoutTypes;
  Content: LayoutTypes;
} = ({ 
  children 
}) => (
  <div className="h-screen flex items-center justify-center relative overflow-hidden">
    {children}
  </div>
);

VerificationEmailLayout.Wrapper = ({ children }) => (
  <div className="flex flex-col items-center gap-10 max-w-xl relative z-10">
    {children}
  </div>
);

VerificationEmailLayout.RadialGradient = ({ children }) => (
  <div className="w-full h-full absolute left-1/2 top-0 -translate-x-1/2 -transpate-y-1/2 opacity-50">
    {children}
  </div>
);

VerificationEmailLayout.Logo = ({ children }) => (
  <div className="w-36">{children}</div>
);

VerificationEmailLayout.Info = ({ children }) => (
  <div className="flex flex-col text-center gap-3.5">
    {children}
  </div>
);

VerificationEmailLayout.SocialButtons = ({ children }) => (
  <div className="flex flex-col items-center gap-2">
    {children}
  </div>
);

VerificationEmailLayout.Content = ({ children }) => (
  <div className="h-[150px] flex items-center justify-center">
    {children}
  </div>
);