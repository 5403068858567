import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { motion, useAnimation } from "framer-motion";

// Components
import { RadialGradientElem } from './RadialGradientElem';
import { gradients } from './gradientsMap';

export const RadialGradients = () => {
  const controls = useAnimation();
  const [isAnimating, setIsAnimating] = useState(true);

  useEffect(() => {
    // If isAnimating is true, the interface starts animations;
    controls.start({
      scale: 1,
      rotate: -360,
      transition: {
        scale: { type: "spring", stiffness: 260, damping: 100, duration: 20 },
        rotate: { duration: 200, repeat: Infinity, ease: "linear" },
      }
    });

    // Set the timer to 10 seconds, after 10 seconds we clear the timer and stop the animation;
    const timer = setTimeout(() => {
      setIsAnimating(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, [controls]);

  useEffect(() => {
    // If isAnimating is false, the interface starts the animation;
    if (!isAnimating) {
      controls.stop();
    }
  }, [isAnimating, controls]);

  const initial = {
    scale: 0.85
  }

  return (
    <div className="w-screen h-screen flex items-center justify-center">
      <motion.div
        className='max-w-2xl w-full h-4/6 relative'
        initial={initial}
        animate={controls}
      >
        {gradients.map(item => (
          <div 
            key={item.gradientStyle}
            className={cn('absolute', item.position)}
          >
            <RadialGradientElem 
              gradientStyle={item.gradientStyle}
              duration={item.duration}
            />
          </div>
        ))}
      </motion.div>
    </div>
  );
}