import React from 'react';
import { GridPropTypes } from './types';

export const Grid: React.FC<GridPropTypes> = ({ children }) => {
  const childrenArray = React.Children.toArray(children);

  return (
    <div className="w-full h-full grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 gap-2 md:gap-4 lg:gap-5">
      {childrenArray.map((child, index) => (
        <React.Fragment key={index}>
          {child}
        </React.Fragment>
      ))}
    </div>
  );
}