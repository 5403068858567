import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type VerificationTypes = {
  title: string;
  subTitle: string;
}

export const useSetInfoText = (
  isLoader: boolean, 
  isError: boolean
) => {
  const [verificationTexts, setVerificationTexts] = useState<VerificationTypes>({
    title: '',
    subTitle: ''
  });
  const { t } = useTranslation();

  useEffect(() => {
    let obj = {
      title: t('app.pages.verification_email.verification_process.title'),
      subTitle: t('app.pages.verification_email.verification_process.sub_title')
    };

    if (isLoader) {
      obj = {
        title: t('app.pages.verification_email.verification_process.title'),
        subTitle: t('app.pages.verification_email.verification_process.sub_title')
      };
    } else if (isError) {
      obj = {
        title: t('app.pages.verification_email.verification_error.title'),
        subTitle: t('app.pages.verification_email.verification_error.sub_title')
      };
    }

    setVerificationTexts(obj);
  }, [isLoader, isError]);

  return {
    ...verificationTexts
  }
}