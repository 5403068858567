import { useState, useMemo } from 'react';
import { useNavigate } from "react-router";
import { useActors } from '../../../../hooks/actors';
import { useWallet } from '../../../../hooks/useWallet';
import { useProfile } from '../../../../hooks/useProfile';
import { useFeature, FeatureFlagNameStr } from "../../../../hooks/useFeature";
import { getAccountStatus } from "../../../../utils/accout_util";
import { useTranslation } from 'react-i18next';

export const useDecideIDCards = () => {
  const { decideid } = useActors();
  const { tokenRequiredAction, isLoading: isWalletDataLoading } = useWallet();
  const { refreshProfile, account } = useProfile();
  const { isFeatureEnabled } = useFeature();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showReverificationModal, setShowReverificationModal] = useState(false);
  const [showConfirmReverificationModal, setShowConfirmReverificationModal] = useState(false);
  const [isReverifyLoading, setReverifyLoading] = useState(false);

  const canReverify = tokenRequiredAction?.canStartReverification;
  const isEligibleForReverification = account && (
    getAccountStatus(account) === "Verified" || getAccountStatus(account) === "Try Again"
  );

  const shouldShowReverifyButton = useMemo(() => (
    isFeatureEnabled("REVERIFICATION_ENABLED" as FeatureFlagNameStr) && isEligibleForReverification
  ), [isFeatureEnabled, isEligibleForReverification]);

  const lastVerificationDate = useMemo(() => 
    account?.lastVerifiedAt 
      ? new Date(Number(account.lastVerifiedAt))
          .toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit', 
            year: 'numeric',
          })
      : t('app.components.smartkit.decide_id_card.last_verified_at.not_available'),
  [account?.lastVerifiedAt]);
  
  const handleConfirmReverification = async () => {
    if (!canReverify) {
      return;
    }

    setReverifyLoading(true);
    const result = await decideid.reverifyPoh();
    refreshProfile();

    setReverifyLoading(false);

    if ("ok" in result) {
      navigate("/poh");
    } else {
      console.error('Reverify Error:', result.err);
    }
  }

  const handleReverifyClick = () => {
    if (!canReverify) {
      console.error('Reverify Error:', 'Not enough tokens');
      setShowReverificationModal(true);
      return;
    }

    setShowConfirmReverificationModal(true);
  };

  const closeReverificationModal = () => setShowReverificationModal(false);
  const closeConfirmReverificationModal = () => setShowConfirmReverificationModal(false);

  return {
    decideid,
    isWalletDataLoading,
    canReverify,
    showReverificationModal,
    showConfirmReverificationModal,
    setShowReverificationModal,
    closeReverificationModal,
    closeConfirmReverificationModal,
    handleConfirmReverification,
    isReverifyLoading,
    setReverifyLoading,
    refreshProfile,
    account,
    lastVerificationDate,
    shouldShowReverifyButton,
    handleReverifyClick
  };
};