export const DATA_POH = {
  errorScreen: {
    title: "Sorry, your submission could not be processed",
    subTitle:
      "Your face could not be verified. Here are some helpful tips to get verified",
    tips: [
      "Turn your screen brightness up",
      "Look directly in the camera",
      "Make sure you are in a well lit area",
      "Make sure your face is clearly visible and centered",
      "For better accuracy, use a camera with higher resolution.",
      "Try placing yourself in front of a plain background",
    ],
  },
};
