import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Disclosure } from './Disclosure';
import { MainTitle } from './MainTitle';
import { MainSubTitle } from './MainSubTitle';
import { Layout, Footer, Navigation } from '../../components/layout';

type FaqItem = {
  title: string;
  description: string;
  list?: string[]
}

function FaqPage() {
  const { t } = useTranslation();
  const content = t('app.pages.faq.content', { returnObjects: true }) as FaqItem[];

  return (
    <React.Fragment>
      <Navigation theme='black' />
      <div className='pt-10'>
        <div className='p-[1.11vw] md:px-[2.22vw] lg:px-[2.7vw]'>
          <div className='pb-10 md:pb-20 xl:pb-[5.55vw]'>
            <MainTitle text={t('app.pages.faq.title')} />
            <MainSubTitle text={t('app.pages.faq.sub_title')} />
          </div>

          <div className="flex flex-col w-full justify-center items-center gap-2 xl:gap-[0.55vw]">
            {content.map(item => (
              <Disclosure
                key={item.title}
                {...item}
              />
            ))}
          </div>
        </div>
      </div>

      <Layout.Footer>
        <Footer />
      </Layout.Footer>
    </React.Fragment>
  );
}
export default FaqPage;

    