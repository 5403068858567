import React from 'react';

// Components
import { TermsPolicy } from '../../../components/uikit';
import { Checkbox } from '@modclub-app/uikit';

// Data
import { DATA_NEW_PROFILE } from '../../../data/dataNewProfile';

export const Agreement: React.FC<{ 
  handleCheck: (checked: boolean) => void; 
}> = ({ handleCheck }) => {
  const { agreeText } = DATA_NEW_PROFILE;
  return (
    <div className="flex items-center justify-center gap-2">
      <Checkbox 
        onChange={handleCheck} 
        theme="blue" 
      />

      <TermsPolicy text={agreeText} />
    </div>
  );
}