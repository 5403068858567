import React from 'react';

// Components
import { Modal, Portal } from '../../../components/uikit';
import { PageContentLayout } from './PageContentLayout';
import { IDCard } from './IDCard';
import { Deposit } from './Deposit';
import { Onboarding } from "../Onboarding";
import { DepositModal } from "../DepositModal";
import { useProfile } from "../../../hooks/useProfile";
import { ReverificationPaymentModal, WithdrawModal } from "../../../components/smartKit";
import { WalletDepositModal } from '../WalletDepositModal';

// Store / Hooks
import { useDepositStore } from '../../../store/useDepositStore';

export const PageContent = () => {
  const { account } = useProfile();

  // Registration fee modal
  const handleRegFeeModal = useDepositStore((state) => state.setIsOpenRegFeePaymentModal);
  const isOpenRegFeeModal = useDepositStore((state) => state.isOpenRegFeePaymentModal);

  // Deposit modal
  const handleDepositModal = useDepositStore((state) => state.setIsOpenDepositModal);
  const isOpenDepositModal = useDepositStore((state) => state.isOpenDepositModal);

  // Withdraw modal
  const handleWithdrawModal = useDepositStore((state) => state.setIsOpenWithdrawModal);
  const isOpenWithdrawModal = useDepositStore((state) => state.isOpenWithdrawModal);

  return (
    <PageContentLayout>
      <PageContentLayout.SectionOnBoarding>
        <Onboarding setShowPaymentModal={handleRegFeeModal} />
      </PageContentLayout.SectionOnBoarding>

      <PageContentLayout.SectionIDCard>
        <IDCard />
      </PageContentLayout.SectionIDCard>

      <PageContentLayout.SectionDeposit>
        <Deposit />
      </PageContentLayout.SectionDeposit>

      {isOpenRegFeeModal && (
        <Portal>
          <Modal closeModal={handleRegFeeModal}>
            {account?.onboardingType === 'reverification' ? (
              <ReverificationPaymentModal /> 
            ) : (
              <DepositModal />
            )}
          </Modal>
        </Portal>
      )}

      {isOpenDepositModal && (
        <Portal>
          <Modal closeModal={handleDepositModal}>
            <WalletDepositModal />
          </Modal>
        </Portal>
      )}

      {isOpenWithdrawModal && (
        <Portal>
          <Modal closeModal={handleWithdrawModal}>
            <WithdrawModal />
          </Modal>
        </Portal>
      )}
    </PageContentLayout>
  );
}