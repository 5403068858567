import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// Hooks
import { useProfile } from '../../../hooks/useProfile';

// Utils
import { 
  pollWithExponentialBackoff 
} from '../../../utils/pollWithExponentialBackoff';
import {
  EXPONENTIAL_BACKOFF_BASE_DELAY,
  EXPONENTIAL_BACKOFF_MAX_DELAY_SIGNUP
} from '../../../utils/constant';

export const useErrorSignup = () => {
  const navigate = useNavigate();
  const { isLoading, error } = useProfile();
  const pollRef = useRef<{ cancel: () => void } | null>(null);

  useEffect(() => {
    const pollFunction = async () => {
      if (!isLoading && error) {
        console.log('ERROR -> ', error.message);
        if (error.message === "Not registered." 
        || error.message.match("Call was rejected")) {
          navigate("/signup");
          return false;
        }
      }

      return true;
    }

    pollRef.current = pollWithExponentialBackoff(
      pollFunction, 
      EXPONENTIAL_BACKOFF_BASE_DELAY, 
      EXPONENTIAL_BACKOFF_MAX_DELAY_SIGNUP
    );

    return () => {
      if (pollRef.current) {
        pollRef.current.cancel();
      }
    };
  }, [isLoading, error, navigate]);
};