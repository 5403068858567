import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useWallet as useSolanaWallet } from "@solana/wallet-adapter-react";
import { Button } from "@modclub-app/uikit";

// Components
import ConnectButton from "./ConnectButton";
import { Modal, Portal } from '../../../components/uikit';
import LoginButton from "./LoginButton";


export function SolanaLogin() {
  const { t } = useTranslation();
  const { connected, disconnect } = useSolanaWallet();

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (connected) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [connected]);

  return (
    <div>
      {<ConnectButton setModalOpen={setIsModalOpen} />}
      
      {isModalOpen && (
        <Portal>
          <Modal closeModal={() => setIsModalOpen(false)}>
            <p>{t('app.pages.authorization.solana.solana_modal_text')}</p>
            <div className="grid grid-cols-1 gap-2">
              <LoginButton />
              <Button
                buttonText="Disconnect"
                onClick={disconnect}
                skin="inline"
                size="small"
                theme="black"
              />
            </div>
          </Modal>
        </Portal>
      )}
    </div>
  )
}