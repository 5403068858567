import React from "react";

// Hooks
import { useAddPohCandidate } from './customHooks/useAddPohCandidate';
import { useTranslation } from 'react-i18next';

const VerifiedCredentials = () => {
    useAddPohCandidate();
    const { t } = useTranslation();

    return (
      <div className="fixed inset-0 bg-[#0E0E0E] overflow-y-auto h-full w-full">
        {" "}
        {/* Modal Background */}
        <div className="relative top-20 mx-auto p-5 w-96 shadow-lg rounded-md bg-[#0E0E0E]">
          {" "}
          {/* Modal Container */}
          <div className="text-center">
            <div className="w-[116px] h-[110px] inline-block mb-[56px]">
              <img 
                src="modclub_logo.png" 
                alt="modclub_logo" 
                className="w-full h-auto"
              />
            </div>
    
            <h2 
              className="text-base text-white font-regular mb-[26px] text-center" 
              dangerouslySetInnerHTML={{ __html: t('app.pages.verified_credentials.title') }}
            />
    
            <div className="flex items-center justify-center">
              <span 
                className='animate-spin border border-solid rounded-full border-l-[#1B4444] border-[3px] w-9 h-9 border-white'
              />
            </div>
        </div>
      </div>
    </div>
  );
};

export default VerifiedCredentials;
