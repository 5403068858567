import React from 'react';

export const NavLogo: React.FC<{ isCollapsed: boolean; }> = ({ isCollapsed }) => (
  <div className="flex items-center h-[32px]">
    <img
      className="w-auto h-[32px]"
      src="/assets/decideid_logo_less_black.svg"
      alt="Compact Logo"
    />
    {!isCollapsed && (
      <img 
        className="ml-2 w-[74px]"
        src="/assets/decide_ai_text_black.svg"
        alt="text Logo"
      />
    )}
  
  </div>
);