import React from 'react';
import { useDeveloperProfile } from '../../hooks/useDeveloperProfile';
import { useAuth } from '../../hooks/useAuth';

function DeveloperDashboard() {
  const { profile, isLoading } = useDeveloperProfile();

  if (isLoading) {
    return <div className="h-screen flex justify-center items-center">Loading...</div>;
  }


  return (
    <div className="h-screen p-8">
      {profile && (
        <div>
          <h1 className="text-2xl font-bold mb-4">Your Developer Profile</h1>
          <pre className="bg-gray-100 p-4 rounded">
            {JSON.stringify(profile, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
}

export default DeveloperDashboard;
