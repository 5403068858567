/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": process.env.AWS_APP_REGION,
  "aws_cognito_identity_pool_id": process.env.AWS_APP_IDENTITYPOOL_ID,
  "aws_cognito_region": process.env.AWS_APP_REGION,
  "aws_user_pools_id": process.env.REACT_APP_USERPOOL_ID,
  "aws_user_pools_web_client_id": process.env.REACT_APP_WEBCLIENT_ID
  // "aws_project_region": "us-east-1",
  // "aws_cognito_identity_pool_id": "",
  // "aws_cognito_region": "us-east-1",
  // "aws_user_pools_id": "",
  // "aws_user_pools_web_client_id": ""
};
export default awsmobile;
