import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from "classnames";
import { motion } from 'framer-motion';

// Components
import { Typography, WebLayoutIcon, SimpleUserBodyIcon } from '@modclub-app/uikit';

type IconProps = {
  size?: number;
  fill?: string;
};

export interface DesktopNavItemTypes {
  name: string;
  alias: string;
  url: string;
  iconName: string;
};

const ICONS: Record<string, React.JSXElementConstructor<IconProps>> = {
  'WebLayoutIcon': WebLayoutIcon,
  'SimpleUserBodyIcon': SimpleUserBodyIcon,
}

export const MenuItem: React.FC<{ 
  index: number;
  isCollapsed: boolean;
  isActive: boolean;
} & DesktopNavItemTypes> = ({ 
  iconName,
  isActive,
  name,
  url,
  isCollapsed,
}) => {
  const CompIcon = ICONS[iconName]; 
  const textVariants = {
    expanded: { 
      opacity: 1, x: 0, display: 'block',
      transition: { duration: 0.5 }
    },
    collapsed: { 
      opacity: 0, x: -20, display: 'none',
      transition: { duration: 0.2 }
    },
  };

  const textColor = isActive ? "C00" : "F00";
  const iconFill = isActive ? '#fff' : '#000';
  const spanAnimate = isCollapsed ? 'collapsed' : 'expanded';

  return (
    <NavLink to={url} className={cn(
      "w-full flex items-center py-2 px-3 cursor-pointer rounded-full", {
      "bg-F00 text-C00 hover:bg-F20": isActive,
      "hover:bg-H100 hover:text-F00": !isActive,
    })}>
      <CompIcon 
        fill={iconFill}
        size={24} 
      />
      <motion.span
        className="ml-2.5"
        variants={textVariants}
        animate={spanAnimate}
        initial="expanded"
      >
        <Typography 
          size="base"
          tagName="span"
          whitespaceNowrap
          color={textColor}
        >{name}</Typography>
      </motion.span>
    </NavLink>
  );
}