import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { PersonalDataForm } from './PersonalDataForm';

// Layout
import { Layout } from '../../components/layout';
import { LoaderContainer, AnimatePulse } from '../../components/uikit';
import { BlockPlate } from '../../components/layout';

import { useProfileStore } from '../../store/useProfileStore';
import { useProfileData } from './customHooks/useProfileData';

const ProfilePage = () => {
  const { t } = useTranslation();
  const { profileData } = useProfileData();
  const { profileDataLoaded, setProfileData } = useProfileStore();

  useEffect(() => {
    if (profileData) {
      setProfileData(profileData);
    }
  }, [profileData]);

  return (
    <React.Fragment>
      <Layout.Section title={t('app.pages.profile.title')}>
        <LoaderContainer
          isLoading={!profileDataLoaded}
          transitionDuration={0.6}
          LoadingComponent={() => (
            <BlockPlate className="h-[478px] lg:h-[438px]">
              <AnimatePulse height="100%" bg="from-[#f0f4ff] to-[#f8f9fe]" />
            </BlockPlate>
          )}
          ErrorComponent={() => (
            <div className="text-red-600 text-center">
              {t('app.pages.profile.profile_data_loading_error')}
            </div>
          )}
        >
          <PersonalDataForm />
        </LoaderContainer>
      </Layout.Section>
    </React.Fragment>
  );
}

export default ProfilePage;