import React from 'react';
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export const SubTitle = () => {
  const { t } = useTranslation();

  return (
    <motion.div
      className='text-C100 font-neue-montreal font-light sm:text-[2.2vw] lg:text-[1.2vw]'
      key="floatingUpAnimation"
      variants={{
        hidden: { opacity: 0, y: 25 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={"visible"}
      transition={{ duration: 0.7, delay: 0.5 }}
    >
        {t('app.pages.landing.subtitle')}
    </motion.div>
  );
}
