import React from 'react';
import { Outlet } from 'react-router-dom';

function DeveloperLayout() {
  return (
    <div className="min-h-screen">
      <main>
        <Outlet />
      </main>
    </div>
  );
}

export default DeveloperLayout;
