import {
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import { useActors } from "./actors";
import {JWT_STORE_NAME} from '../utils/constant';

export function useToken() {
  const queryClient = useQueryClient();
  const { decideid } = useActors();

  // Retrieve the token from localStorage
  const tokenFromStorage = localStorage.getItem(JWT_STORE_NAME) || undefined;

  // Function to fetch the token
  // This function is only called manually.
  const fetchToken = async (): Promise<string> => {
    if (decideid) {
      const result = await decideid.getToken();
      if ("ok" in result) {
        const token = result.ok;
        localStorage.setItem(JWT_STORE_NAME, token);
        return token;
      } else if ("err" in result) {
        throw new Error(result.err);
      }
    }
    throw new Error("decideid is not ready or failed to fetch token");
  };

  // Set token manually (after login, for example)
  const setToken = (token: string) => {
    localStorage.setItem(JWT_STORE_NAME, token);
    queryClient.setQueryData(["token"], token); // Update the cache
  };

  // Using React Query to manage the token fetching and caching
  const { data: token, isLoading: isFetching } = useQuery<string, Error>({
    queryKey: ["token"],
    initialData: tokenFromStorage,
    queryFn: fetchToken,
    enabled: false, // Prevent automatic fetching on mount,
    retry: false, // // Do not retry on failure
  });

  // Function to manually refresh the token by fetching a new one from the server
  const refreshToken = async () => {
    try {
      const newToken = await fetchToken();
      setToken(newToken);
    } catch (error) {
      console.error("Failed to refresh token:", error);
      throw error;
    }
  };

  return {
    token,
    setToken,
    refreshToken,
    isFetching,
  };
}
