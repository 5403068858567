import React from "react";
import { useTranslation } from 'react-i18next';

// Hooks
import { useWallet } from '../../../hooks/useWallet';

// Components
import { Button } from '@modclub-app/uikit';
import { SimpleModal } from '../../../components/uikit';
import { PaymentModal } from '../../../components/smartKit';
import { useIdentity } from "../../../hooks/useIdentity";
import { useNavigate } from "react-router";

export const DepositModal = () => {
  const { principal } = useIdentity();
  const transferPohFeeError = (
    error: Error, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _variables: "DCD" | "ICP", 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _context: unknown
  ) => {  
    // TODO: MOD-1330 properly handle transferPohFeeError
    window.alert(error);
  };

  const { 
    balance, 
    refresh, 
    transferPohFee, 
    pohFee, 
    transferPohFeeStatus 
  } = useWallet(transferPohFeeError);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="max-w-xl w-full">
      {transferPohFeeStatus == 'success' ? (
        <SimpleModal 
          title={t('app.pages.main.success_modal.title')}
          subTitle={t('app.pages.main.success_modal.sub_title')}
          iconName="CheckCircleIcon"
        >
          <div className="max-w-xs m-auto w-full">
            <Button
              buttonText={t('app.pages.main.success_modal.success_button')}
              onClick={() => {
                navigate("/poh");
              }}
              rounded="lg"
              iconName="ArrowRightIcon"
              size="large"
              skin="standard"
              fullWidth
              theme="black"
            />
          </div>
        </SimpleModal>
      ) : (
        <PaymentModal 
          balance={balance?.DCD}
          onRefresh={refresh}
          onClickProcessPayment={transferPohFee}
          isProcessingPayment={transferPohFeeStatus == 'pending'}
          walletAddress={principal}
          options={[pohFee]}
          title={t('app.pages.main.deposit_modal.title')}
          subTitle={t('app.pages.main.deposit_modal.sub_title')}
          alertText={t('app.pages.main.deposit_modal.alert_text')}
        />
      )}
    </div>
  );
}