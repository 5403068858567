import React from "react";

// Hooks
import { useErrorSignup } from './customHooks/useErrorSignup';

// Components
import { SplashScreen } from '../../components/smartKit';
import { PageContainer } from './PageContainer';

import { useProfile } from "../../hooks/useProfile";
import { useTranslation } from 'react-i18next';

function MainPage() {
  useErrorSignup();
  const { profile, isLoading, error } = useProfile();
  const { t } = useTranslation();

  if (isLoading || !profile || error) {
    return (
      <div className="fixed top-0 left-0 bottom-0 right-0 w-full h-full">
        <SplashScreen
          titleDelay={0.1}
          titleDuration={0.4}
          title={t('app.pages.landing.loading-txt')}
          isVisible={isLoading}
        />
      </div>
    );
  }

  return (
    <PageContainer />
  );
}

export default MainPage;
