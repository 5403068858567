import React from "react";
import { motion, AnimatePresence } from "framer-motion";

// Components
import { More, Less } from './Svg';

// Types
import { DsclosureOpenType } from '../../types';

export const AnimationIcon: React.FC<DsclosureOpenType> = ({ isOpen }) => (
  <AnimatePresence initial={false} mode="wait">
    <motion.div
      key={isOpen ? "minus" : "plus"}
      initial={{
        rotate: isOpen ? -90 : 90,
      }}
      animate={{
        rotate: 0,
        transition: {
          type: "tween",
          duration: 0.15,
          ease: "circOut",
        },
      }}
      exit={{
        rotate: isOpen ? -90 : 90,
        transition: {
          type: "tween",
          duration: 0.15,
          ease: "circIn",
        },
      }}>
        <div className="w-[20px] h-[20px] xl:w-[1.38vw] xl:h-[1.38vw]">
          {isOpen ? <Less /> : <More />}
        </div>
      </motion.div>
  </AnimatePresence>
)