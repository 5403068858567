import React from 'react';
import cn from 'classnames';

export const BlockPlate: React.FC<{
  className?: string;
  children: React.ReactNode;
}> = ({ className, children }) => (
  <div className={cn(
    className, 
    "w-full h-full p-[1px] rounded-xl md:rounded-2xl lg:rounded-3xl overflow-hidden",
    "bg-gradient-to-br from-[#fff] to-[rgba(255, 255, 255, 0.3)]"
  )}>
    {children}
  </div>
)