import React from 'react';

// Components
import { BlockPlate } from '../../../components/layout';
import { ProfileLayout } from '../ProfileLayout';
import { EditMode } from '../EditMode';
import { ViewMode } from '../ViewMode';

import { useProfileStore } from '../../../store/useProfileStore';

export const PersonalDataForm = () => {
  const { isEditMode } = useProfileStore();
  return (
    <BlockPlate>
      <ProfileLayout>
        <ProfileLayout.ContentForm>
          {isEditMode ? (
            <EditMode />
          ) : (
            <ViewMode />
          )}
        </ProfileLayout.ContentForm>
      </ProfileLayout>
    </BlockPlate>
  );
}