import React from 'react';
import { motion } from "framer-motion";

import { useTranslation } from 'react-i18next';

export const Title = () => {
  const { t } = useTranslation();

  return (
    <motion.h1
      className='text-C100 font-neue-montreal leading-none font-bold  text-[4.125rem] md:text-[15.375vw] lg:text-[8.375vw]'
      variants={{
        hidden: { opacity: 0, y: 25 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={"visible"}
      transition={{ duration: 0.7, delay: 0.2 }}
    >
      {t('app.pages.landing.app_name')}
    </motion.h1>
  );
}
