import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import { NavLogo } from './NavLogo';
import { NavLayout } from  './NavLayout';
import { MenuItem } from './MenuItem';

// Store / Hooks
import { useNavStore } from '../../../../store/useNavStore';
import { DesktopNavItemTypes } from './MenuItem';

export const NavDesktop = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const isCollapsed = useNavStore((state) => state.isCollapsed);
  const navBarMenu = t('app.components.layout.nav_bar.menu', { returnObjects: true }) as DesktopNavItemTypes[];
  
  return (
    <NavLayout isCollapsed={isCollapsed}>
      <NavLayout.Logo>
        <NavLogo isCollapsed={isCollapsed} />
      </NavLayout.Logo>
      <NavLayout.Ul isCollapsed={isCollapsed}>
        {navBarMenu.map((item, index) => (
          <NavLayout.Li key={index} delayNum={index}>
            <MenuItem
              isActive={location.pathname === item.url.replace("#", "")}
              isCollapsed={isCollapsed} 
              index={index} 
              key={item.alias} 
              {...item} 
            />
          </NavLayout.Li>
        ))}
      </NavLayout.Ul>
    </NavLayout>
  );
}