import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Button } from '@modclub-app/uikit';
import { ReverificationPaymentModal, ConfirmReverificationModal } from '../../../smartKit';
import { Modal, Portal, LabelText } from '../../../uikit';
import { useDecideIDCards } from '../customHooks/useDecideIDCards';

export const Reverification = () => {
  const { t } = useTranslation();
  const {
    isWalletDataLoading,
    showReverificationModal,
    showConfirmReverificationModal,
    closeReverificationModal,
    closeConfirmReverificationModal,
    isReverifyLoading,
    lastVerificationDate,
    shouldShowReverifyButton,
    handleConfirmReverification,
    handleReverifyClick
  } = useDecideIDCards();

  return (
    <React.Fragment>
      <div className="flex gap-1 items-center justify-end">
        <LabelText 
          position="end" 
          label={t('app.components.smartkit.decide_id_card.last_verified_at.label')} 
          text={lastVerificationDate} 
        >
          {shouldShowReverifyButton && (
            <div className="pl-2 -mr-3.5">
              <Button
                buttonText={t('app.components.smartkit.decide_id_card.reverification_button')} 
                iconName="UserSwitchIcon"
                onClick={handleReverifyClick}
                disabled={isWalletDataLoading}
                loading={isReverifyLoading}
                rounded="full"
                size="small"
                skin="standard"
                theme="black"
              />
            </div>
          )}
        </LabelText>
      </div>
  
      {showReverificationModal && (
        <Portal>
          <Modal closeModal={closeReverificationModal}>
            <ReverificationPaymentModal />
          </Modal>
        </Portal>
      )}
  
      {showConfirmReverificationModal && (
        <Portal>
          <Modal closeModal={closeConfirmReverificationModal}>
            <ConfirmReverificationModal 
              onClick={handleConfirmReverification}
              isLoading={isReverifyLoading}
            />
          </Modal>
        </Portal>
      )}
    </React.Fragment>
  );
}