import React from "react";
import { useTranslation } from 'react-i18next';

// Components
import { Typography, Button } from "@modclub-app/uikit";
import { MessagePlate } from '../../../components/uikit';
import { BlockPlate } from '../../../components/layout';

// Hooks
import { useOnboarding } from '../customHooks/useOnboarding';
import { useUpdateProfileStatus } from '../customHooks/useUpdateProfileStatus';

interface OnboardingProps {
  setShowPaymentModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Onboarding: React.FC<OnboardingProps> = ({ setShowPaymentModal }) => {
  useUpdateProfileStatus();
  const { t } = useTranslation();

  const {
    stepName,
    alertText,
    handlerOnClick,
    isWalletLoading
  } = useOnboarding(setShowPaymentModal);

  if (!stepName || stepName === 'completed') {
    return null;
  }

  return (
    <BlockPlate>
      <MessagePlate
        title={t('app.pages.main.onboarding.title')}
        alertText={alertText}
        position="start"
      >
        <React.Fragment>
          {(stepName == 'poh_liveness') && (
            <Button
              buttonText={t('app.pages.main.onboarding.pohContinueText')}
              onClick={handlerOnClick}
              iconName={t('app.pages.main.onboarding.iconName')}
              size="medium"
              skin="standard"
              theme="black"
              disabled={isWalletLoading}
            />
          )}

          {(stepName === 'poh_uniqueness') && (
            <Typography color="F00">
              {alertText}
            </Typography>
          )}
        </React.Fragment>
      </MessagePlate>
    </BlockPlate>
  );
};