import React from 'react';
import cn from 'classnames';

type ReverificationModalLayoutTypes<
  Props = Record<string, never | React.ReactNode>
> = React.FC<Props & { children: React.ReactNode }>;

export const ModalLayout: ReverificationModalLayoutTypes & {
  WarningInfoContainer: ReverificationModalLayoutTypes;
  Fee: ReverificationModalLayoutTypes;
  Sum: ReverificationModalLayoutTypes;
  Button: ReverificationModalLayoutTypes<{ className?: string; }>;
  AdvantagesList: ReverificationModalLayoutTypes;
  Info: ReverificationModalLayoutTypes;
  Content: ReverificationModalLayoutTypes;
  MainTitle: ReverificationModalLayoutTypes;
  WarningPlate: ReverificationModalLayoutTypes;
} = ({ children }) => (
  <div className="w-full">
    {children}
  </div>
);

ModalLayout.WarningInfoContainer = ({ children }) => (
  <div className="flex flex-col items-center">
    {children}
  </div>
);

ModalLayout.MainTitle = ({ children }) => (
  <div className="pb-6 lg:pb-10 flex w-full justify-start">
    {children}
  </div>
);

ModalLayout.Content = ({ children }) => (
  <div className="flex flex-col justify-start lg:flex-row gap-10">
    {children}
  </div>
);

ModalLayout.Fee = ({ children }) => (
  <div className="flex flex-col gap-1">
    {children}
  </div>
);

ModalLayout.Sum = ({ children }) => (
  <div className="flex items-start gap-1">
    {children}
  </div>
);

ModalLayout.Info = ({ children }) => (
  <div className="flex flex-col gap-6">
    {children}
  </div>
);

ModalLayout.WarningPlate = ({ children }) => (
  <div className="pt-6">
    {children}
  </div>
);

ModalLayout.Button = ({ children, className }) => (
  <div className={cn("m-auto w-full", className)}>
    {children}
  </div>
);

ModalLayout.AdvantagesList = ({ children }) => (
  <div className="flex flex-col justify-start gap-3.5">
    {children}
  </div>
);