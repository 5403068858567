import React from 'react';

// Components
import { LabelText } from '../../../uikit';
import { useFeature } from '../../../../hooks/useFeature';

export const VerifiedStatus: React.FC<{ status: string; reputationLevel: string | null }> = ({ status, reputationLevel }) => {
  const { isFeatureEnabled } = useFeature();
  let color: string | null = null;
  if (status === 'Processing' || status === 'Onboarding') {
    color = 'bg-E50';
  } else if (status === 'Rejected' || status.startsWith('Failed')) {
    color = 'bg-D60';
  } else if (status === 'Verified') {
    color = 'bg-B50';
  }
  
  return (
    <div className="flex flex-col gap-2">
      <LabelText position="end" text={status} color={color} />
      {isFeatureEnabled("REPUTATION_ENABLED") && reputationLevel && <LabelText position="end" text={reputationLevel} color={color} /> }
    </div>
  ); 
}