import React, { useState } from 'react';

// Components
import { ButtonIcon, Typography } from '@modclub-app/uikit';

// Types
import { CopyableTextPropTypes } from './types';

export const CopyableText: React.FC<CopyableTextPropTypes> = ({ 
  textToCopy,
  buttonText,
  copyButton,
  children,
}) => {
  const [copySuccess, setCopySuccess] = useState<string>('');

  const copyToClipboard = async () => {
    if (!navigator.clipboard) {
      console.log('Clipboard API not supported');
      return;
    }

    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopySuccess('Copied!');
    } catch (err) {
      console.log(err);
    }

    setTimeout(() => setCopySuccess(''), 2000);
  };

  return (
    <div className="relative flex items-center space-x-2">
      {copyButton ? (
        <React.Fragment>
          {children ? children : (
            <Typography 
              color="G00" 
              size="lg" 
              weight="normal"
            >
              {buttonText}
            </Typography>
          )}
          <ButtonIcon
            iconName="CopyIcon"
            onClick={copyToClipboard}
            size={18}
            skin="standard"
            theme="black"
          />
        </React.Fragment>
      ) : (
        <button 
          onClick={copyToClipboard} 
          className="cursor-pointer hover:text-G00"
        >
          {buttonText}
        </button>
      )}
      {copySuccess && (
        <span className="absolute rounded-full px-2 bg-G50 -bottom-7 right-0">
          {copySuccess}
        </span>
      )}
    </div>
  );
};