import { useMemo } from 'react';
import { useProfile } from '../../../hooks/useProfile';
import { getAccountStatus } from "../../../utils/accout_util";
import { useTranslation } from 'react-i18next';

type InfoItemsList = {
  token?: string;
  status?: string;
}

export const useInfoItemsList = (): {
  infoItemsList: InfoItemsList,
    } => {
  const { isLoading, profile, account, principal } = useProfile();
  const { t } = useTranslation();

  const infoItemsList: InfoItemsList = useMemo<InfoItemsList>(() => {
    if (isLoading || !profile) return {};

    let items: InfoItemsList = {
      token: principal || '',
    };

    if (account) {
      const status = getAccountStatus(account);
      items = {
        ...items,
        status,
      };

      if ('rejected' in account.state) {
        let rejectReason = '';
        if (account.rejectReason && account.rejectReason.length > 0) {
          const reason = Object.keys(account.rejectReason[0])[0];
          switch (reason) {
            case 'failed_duplicate':
              rejectReason = '';
              break;
            case 'failed_age_policy':
              rejectReason = `${t('profile_reject_reason.failed_age_policy')}`;
              break;
            case 'failed_reverification_try_again':
              rejectReason = `${t('profile_reject_reason.failed_reverification_try_again')}`;
              break;
            case 'failed_reverification_not_same_person':
              rejectReason = `${t('profile_reject_reason.failed_reverification_not_same_person')}`;
              break;
            case 'failed_device_not_unique':
              rejectReason = `${t('profile_reject_reason.failed_device_not_unique')}`;
              break;
            default:
              rejectReason = `${t('profile_reject_reason.unknow_reject_reason')}`;
          }
        }
        if (rejectReason) {
          items.status = `${t('profile_status.failed')} : ${rejectReason}`;
        } else {
          items.status = `${t('profile_status.failed')}`;
        }
      }

    }

    return items;
  }, [isLoading, profile, account, principal]);

  return {
    infoItemsList
  };
};
