type PollFunction = () => Promise<boolean>;

export const pollWithExponentialBackoff = (
  pollFunction: PollFunction,
  baseDelay: number,
  maxDelay: number
) => {
  let delay = baseDelay;
  let timeoutId: number | NodeJS.Timer;

  const executePoll = async () => {
    const shouldContinue = await pollFunction();
    if (shouldContinue) {
      delay = Math.min(delay * 2, maxDelay);
      console.log(`Waiting ${delay / 1000} seconds before trying again...`);
      timeoutId = setTimeout(executePoll, delay);
    }
  };

  executePoll();

  return {
    cancel: () => clearTimeout(timeoutId)
  };
};
