import { useState } from 'react';

// Types / enum
import { ValidationError, Balance } from '../types';

export const useWithdrawValidation = (balance?: Balance, icrc1FeeDCD?: number | null) => {
  const [wallet, setWallet] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const [error, setError] = useState<ValidationError | null>(ValidationError.None);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  // Total balance including commission
  const total = (balance?.DCD ?? 0) + (icrc1FeeDCD ?? 0);

  // Check the sum
  const validateAmount = (amount: string): boolean => {
    const numericAmount = parseFloat(amount);
    const fee = icrc1FeeDCD ?? 0;
    const balanceAvailable = balance?.DCD ?? 0;

    if (isNaN(numericAmount) || numericAmount <= 0) {
      setError(ValidationError.AmountInvalid);
      return false;
    }
  
    /**
     * Check: the amount + commission must not exceed the balance
     * ----------
     * To ensure calculation accuracy when checking the amount with the commission, 
     * compare numbers with a small permissible error using Number.EPSILON. 
     * This accounts for floating-point precision issues.
     * */ 
    if (numericAmount + fee > balanceAvailable + Number.EPSILON) {
      setError(ValidationError.AmountExceedsBalance);
      return false;
    }

    setError(null);
    return true;
  };

  const checkIsButtonDisabled = (wallet: string, amount: string) => {
    const validAmount = validateAmount(amount);
    const isWalletValid = wallet.trim().length > 0;
    setIsButtonDisabled(!(validAmount && isWalletValid));
  };

  // Change handlers
  const handleWalletChange = (value: string) => {
    setWallet(value);
    
    // Check the button with the new value
    checkIsButtonDisabled(value, amount);
  };

  const handleAmountChange = (value: string) => {
    setAmount(value);
    
    // Check the button with the new value
    checkIsButtonDisabled(wallet, value);
  };

  const totalAmountWithFee = () => {
    const numericAmount = parseFloat(amount);
    return numericAmount > 0 ? numericAmount + (icrc1FeeDCD ?? 0) : 0;
  };

  return {
    wallet,
    amount,
    error,
    isButtonDisabled,
    total,
    totalAmountWithFee: totalAmountWithFee(),
    handleWalletChange,
    handleAmountChange,
  };
};
