import React, { useState } from "react";

// Coponents
import { Typography } from "@decide-ai/uikit";
import { AnimationIcon } from './AnimationIcon';
import { AnimationBody } from './AnimationBody';

// Types
import { DisclosurePropTypes } from '../types';

export const Disclosure: React.FC<DisclosurePropTypes> = ({
  title,
  description,
  list
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="flex flex-col w-full bg-[#EFEFEF] rounded-lg p-8 xl:p-[2.22vw] xl:rounded-[0.55vw] cursor-pointer"
      onClick={() => setIsOpen((prev) => !prev)}
    >
      <button
        aria-controls={title}
        aria-expanded={isOpen}
        className="flex justify-between text-left items-center w-full"
      >
        <Typography
           weight='medium'
           color='dark-01'
           size="2xl"
         >
          {title}
        </Typography>

        <AnimationIcon isOpen={isOpen}/>
      </button>

      <AnimationBody
        title={title}
        description={description}
        isOpen={isOpen}
        list={list?.map(item => item)}
      />
    </div>
  );
}
