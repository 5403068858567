import React from 'react';

// Components
import { Typography } from '@decide-ai/uikit';

export const MainTitle: React.FC<{ text: string;  }> = ({ text }) => (
  <div className='col-start-1 col-end-13 md:col-start-4'>
    <Typography
      size="4xl"
      weight='medium'
      color='dark-01'
    >
      {text}
    </Typography>
  </div>
);