import * as React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import AuthRouter from "./AppRoutes/AuthRouter";

import Landing from "./Landing";

import { InternetIdentityProvider } from "../contexts/InternetIdentityContext";
// import EthereumProvider from '../contexts/EthereumProvider';
import Actors from "../contexts/ActorsContext";

export default function AppRouter() {
  return (
    <HashRouter>
      <Routes>
        {/* Below routes are external and do not require authentication. */}
        <Route 
          path="/" 
          element={(<Landing />)} 
        />
  
        {/* The following routes necessitate authentication. */}
        <Route
          path="*"
          element={
            // <EthereumProvider>
              <InternetIdentityProvider>
                  <Actors>
                    <AuthRouter />
                  </Actors>
              </InternetIdentityProvider>
            // </EthereumProvider>
          }
        />
      </Routes>
    </HashRouter>
  );
}

