import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Hooks
import { useProfile } from '../../../hooks/useProfile';
import { useWallet } from "../../../hooks/useWallet";

export const useOnboarding = ( setShowPaymentModal: React.Dispatch<React.SetStateAction<boolean>> ) => {
  const navigate = useNavigate();
  const { account } = useProfile();
  const { t } = useTranslation();
  const { tokenRequiredAction: { canStartPoh, canStartReverification } = {}, isLoading } = useWallet();

  const handlerOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const canStart = account?.onboardingType === 'init' ? canStartPoh : canStartReverification;
    
    if (!canStart) {
      setShowPaymentModal(true);
    } else {
      navigate("/poh");
    }
  };

  const stepName = account?.currentOnboardingStep 
    ? Object.keys(account?.currentOnboardingStep)[0] : '';

  const alertText = stepName === 'poh_uniqueness' 
    ? t('app.pages.main.onboarding.reviewingText')
    : t('app.pages.main.onboarding.text');


  return {
    stepName,
    alertText,
    handlerOnClick,
    isWalletLoading:isLoading,
  };
};
