import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { GridV2 } from '../../../components/layout';
import { PartnerCard } from './PartnerCard';

type PartnerTypes = {
  title: string;
  subTitle: string;
  gradient: string;
  href: string;
  image: string;
};

export const Partners = () => {
  const { t } = useTranslation();
  const partners = t('app.pages.main.partners', { returnObjects: true }) as PartnerTypes[];
  return (
    <GridV2 columns={6}>
      {partners.map(item => (
        <div className="col-span-full xl:col-span-2" key={item.title}>
          <PartnerCard
            title={item.title}
            subTitle={item.subTitle}
            gradient={item.gradient}
            href={item.href}
            image={item.image}
          />
        </div>
      ))}
    </GridV2>
  );
}