import { create } from 'zustand';

import { isEmailValid } from '../utils/email_util';

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
}

type State = {
  profileData: FormData;
  formData: FormData;
  isButtonActive: boolean;
  isEditMode: boolean;
  profileDataLoaded: boolean; 
}

type Action = {
  setProfileData: (data: FormData) => void;
  handleChange: (name: string, value: string) => void;
  setEditMode: (isEdit: boolean) => void;
  setIsSaveButtonActive: (isActive: boolean) => void;
  updateButtonActiveState: () => void;
}

export const useProfileStore = create<State & Action>((set, get) => ({
  profileData: { firstName: '', lastName: '', email: '' },
  formData: { firstName: '', lastName: '', email: '' },
  isButtonActive: false,
  isEditMode: false,
  profileDataLoaded: false,

  setProfileData: (data) => {
    if (data) {
      set({ formData: data, profileData: data, profileDataLoaded: true });
      get().updateButtonActiveState();
    }
  },

  handleChange: (name: string, value: string) => {
    set((state) => ({
      formData: {
        ...state.formData,
        [name]: value,
      },
    }));
    get().updateButtonActiveState();
  },

  setEditMode: (isEdit) => {
    set({ isEditMode: isEdit });
  },

  setIsSaveButtonActive: (isActive) => {
    // Remove leading and trailing whitespace from email
    const email = get().formData.email;
    if (email) {
      set({ isButtonActive: isActive && isEmailValid(email) })
    } else {
      set({ isButtonActive: isActive });
    }
  },

  updateButtonActiveState: () => {
    const { formData, profileData } = get();

    const isDataChanged =
      formData.firstName !== profileData.firstName ||
      formData.lastName !== profileData.lastName ||
      formData.email !== profileData.email;

    const areFieldsFilled =
      formData.firstName.trim() !== '' ||
      formData.lastName.trim() !== '' ||
      formData.email.trim() !== '';

      get().setIsSaveButtonActive(isDataChanged && areFieldsFilled);
  },
}));
