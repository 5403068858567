import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Button } from '@modclub-app/uikit';
import { SimpleModal } from '../../../uikit';

export const SuccessStep: React.FC<{ onClick: () => void; }> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <SimpleModal
      title={t('app.components.smartkit.withdraw_modal.success_modal.title')}
      subTitle={t('app.components.smartkit.withdraw_modal.success_modal.sub_title')}
      iconName="CheckCircleIcon"
    >
      <Button
        buttonText={t('app.components.smartkit.withdraw_modal.success_modal.button_name')}
        onClick={onClick}
        rounded="lg"
        iconName="ArrowRightIcon"
        size="large"
        skin="standard"
        fullWidth
        theme="black"
      />
    </SimpleModal>
  );
}