import { useEffect, useState } from 'react';
import { useProfile } from '../../../hooks/useProfile';

export const useProfileData = () => {
  const { profile } = useProfile();
  const [profileData, setProfileData] = useState<null | {
    firstName: string;
    lastName: string;
    email: string;
  }>(null);

  useEffect(() => {
    if (profile) {
      setProfileData({
        firstName: profile.firstName || '',
        lastName: profile.lastName || '',
        email: profile.email[0] || '',
      });
    }
  }, [profile]);

  return {
    profileData,
  };
};