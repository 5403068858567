import React from "react";
import { useTranslation } from 'react-i18next';

// Components
import { Layout } from '../../components/layout';
import { PageContent } from "./PageContent";
import { Partners } from "./Partners";

function MainPage() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Layout.Section title={t('app.pages.main.dashboard_title')}>
        <PageContent />
      </Layout.Section>

      <Layout.Section title={t('app.pages.main.partners_title')}>
        <Partners />
      </Layout.Section>
    </React.Fragment>
  );
}

export default MainPage;