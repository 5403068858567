import React, { memo } from 'react';
import cn from 'classnames';

import { AnimatePulseTypes } from './types';

export const AnimatePulse: React.FC<AnimatePulseTypes> = memo(({
  count = 1,
  width = '100%',
  height = '20px',
  wrapper: Wrapper,
  bg,
  className,
}) => {
  const pulseBg = bg || "from-[#F9FAFC] to-[#FCDDDE]";
  const elements = Array.from({ length: count }, (_, index) => (
    <div
      key={index}
      className={cn(
        pulseBg,
        className,
        'bg-gradient-to-b rounded-lg animate-pulse'
      )}
      style={{ width, height }}
    />
  ));

  return Wrapper ? <Wrapper>{elements}</Wrapper> : <>{elements}</>;
});
