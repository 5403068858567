import { useEffect, useState } from "react";

// Hooks
import { useIdentity } from "../../../hooks/useIdentity";

// api
import fetchCreateLiveness from '../fetchersApi/fetchCreateLiveness';
import {useFetchStandard} from '../../../hooks/useFetchStandard';

export const useFetchCreateLiveness = (user_id: string) => {
  const [isRateLimitError, setRateLimitError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const endpoint = process.env.AWS_RECOGNITION_API_URL || '';
  const { deviceId } = useIdentity();

  const fetchStandard = useFetchStandard();

  const createLivenessSession = async () => {
    setLoading(true);

    try {
      const data = await fetchCreateLiveness(user_id, deviceId, endpoint, fetchStandard);

      if (data.error) {
        console.log('RATE LIMIT ERROR: ', data.error);
        setRateLimitError(true);
        setError(data.error);
      } else {
        console.log("CREATED SESSION", data.sessionId);
        setSessionId(data.sessionId);
      }
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        setError(e.message || 'An error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (deviceId) {
      createLivenessSession();
    }
  }, [endpoint, deviceId, user_id]);

  return {
    error,
    isLoading,
    sessionId,
    isRateLimitError,
    createLivenessSession,
  }
}
