import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from "i18next-http-backend";
import detector from 'i18next-browser-languagedetector';

const i18nInstance = i18n
    .use(detector)
    .use(HttpBackend)
    .use(initReactI18next);

export const initI18n = () => i18nInstance.init({
    fallbackLng: 'en',
    debug: true,
    interpolation: {
        escapeValue: false, // React already safes from xss
    },
    supportedLngs: ['en'],
    backend: {
        loadPath: './assets/locales/{{lng}}/translation.json',
    },
});

export default i18nInstance;
