import React from 'react';
import cn from "classnames";

type CardLayoutType<
  Props = Record<string, never | React.ReactNode>
> = React.FC<Props & { children: React.ReactNode; gradient?: string; }>;

export const CardLayout: CardLayoutType & {
  Icon: CardLayoutType;
  Info: CardLayoutType;
} = ({ children, gradient }) => (
  <div className={cn(gradient, "relative flex flex-col gap-4 p-3 lg:p-6 cursor-pointer group")}>
    {children}
  </div>
);

CardLayout.Icon = ({ children }) => (
  <div className="flex justify-end">
    {children}
  </div>
);

CardLayout.Info = ({ children }) => (
  <div className="flex flex-col gap-2 justify-start">
    {children}
  </div>
);