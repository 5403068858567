import React from 'react';

// Components
import { BlockPlate } from '../../../layout';

type DepositCardLayoutTypes<Props = Record<string, never | React.ReactNode>> = React.FC<Props & { children: React.ReactNode }>;

export const DepositCardLayout: DepositCardLayoutTypes & {
  ContentWrap: DepositCardLayoutTypes;
  Amount: DepositCardLayoutTypes;
  ButtonsGroup: DepositCardLayoutTypes;
} = ({ children }) => (
  <BlockPlate>
    {children}
  </BlockPlate>
);

DepositCardLayout.ContentWrap = ({ children }) => (
  <div className="flex flex-col justify-between h-full p-4 gap-12 lg:gap-0 lg:p-6 bg-C70">
    {children}
  </div>
);

DepositCardLayout.Amount = ({ children }) => (
  <div className="flex felx-row gap-2 items-end">
    {children}
  </div>
);

DepositCardLayout.ButtonsGroup = ({ children }) => (
  <div className="flex flex-wrap gap-2">
    {children}
  </div>
);