import React from 'react';
import {  Routes, Route } from 'react-router-dom';
import DeveloperLayout from './layout';
import { DeveloperAuthGuard } from './authentication';
import DeveloperDashboard from './pages/dashboard/dashboard';
function DeveloperRouter() {
  return (
      <Routes>
        {/* Developer Portal Routes */}
        <Route path="/" element={<DeveloperLayout />}>

          {/* Protected Routes */}
          <Route path="" element={
            <DeveloperAuthGuard>
              <DeveloperDashboard />
            </DeveloperAuthGuard>
          } />
          
        </Route>

        {/* 404 Route */}
        <Route path="*" element={<div>404 Not Found</div>} />
      </Routes>
  );
}

export default DeveloperRouter;
