import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Components
import { Form } from './Form';
import { AuthProfileLayout } from '../../components/layout';

// Utils
import { useProfile } from "../../utils";

const NewProfile = () => {
  const { isLoading, isSuccess } = useProfile();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && isSuccess) {
      navigate("/app");
    }
  }, [isLoading, isSuccess]);

  return (
    <AuthProfileLayout>
      <Form />
    </AuthProfileLayout>
  );
}

export default NewProfile;
