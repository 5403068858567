import React from 'react';
import cn from "classnames";

import { COLS_MAP } from './colsMap';
import { GridColsProps } from './types';

export const GridV2: React.FC<GridColsProps> = ({ 
  children,
  columns, 
  smColumns,
  mdColumns,
  lgColumns, 
  mobColumns,
  gap = "gap-4",
}) => {
  const gridTemplateCols = columns && COLS_MAP[columns];
  const mobGridCols = mobColumns && COLS_MAP[mobColumns];
  const smGridCols = smColumns && `sm:${COLS_MAP[smColumns]}`;
  const mdGridCols = mdColumns && `md:${COLS_MAP[mdColumns]}`;
  const lgGridCols = lgColumns && `lg:${COLS_MAP[lgColumns]}`;

  return (
    <div className={cn("grid", 
      gridTemplateCols, 
      mobGridCols, 
      smGridCols, 
      mdGridCols, 
      lgGridCols, 
      gap
    )}>
      {children}
    </div>
  );
};