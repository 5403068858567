import { useEffect } from "react";
import {
  useQuery,
  useMutation,
  useQueryClient,
  UseMutationResult,
  UseQueryResult,
} from "@tanstack/react-query";
import { useActors } from "./actors";
import { decideid_types } from "../canister_types";
import { useIdentity } from "./useIdentity";
import {useToken} from './useToken';

interface AccountInfo {
  account: decideid_types.Account,
  profile: decideid_types.Profile,
}

export function useProfile() {
  const queryClient = useQueryClient();
  const { decideid } = useActors();
  const { isConnected, principal } = useIdentity();
  const { setToken } = useToken();

  async function fetchProfile(): Promise<AccountInfo> {
    if (decideid) {
      const result = await decideid.getAccByCaller();
      if ("err" in result) {
        throw new Error(result.err);
      } else if ("ok" in result) {
        return {
          account: result.ok.acc,
          profile: result.ok.profile,
        };
      }
    }
    throw new Error("decideid is not ready");
  }

  async function updateProfileOnServer(
    newProfile: decideid_types.Profile
  ): Promise<decideid_types.Profile> {
    // Replace with your actual update logic
    console.log('Updated profile: ', newProfile);
    throw new Error("updateProfileOnServer function is not implemented.");
  }

  const {
    data: accountInfo,
    error,
    isError,
    isLoading,
    isSuccess,
  }: UseQueryResult<AccountInfo, Error> = useQuery({
    queryKey: ["profile"],
    queryFn: fetchProfile,
    retry: false, // Do not retry on failure
  });

  const updateProfileMutation: UseMutationResult<
    decideid_types.Profile,
    Error,
    decideid_types.Profile
  > = useMutation({
    mutationFn: updateProfileOnServer,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["profile"] });
    },
  });

  async function createProfileOnServer(userData: {
    firstName: string;
    lastName: string;
    email: string;
  }): Promise<string> {
    const { firstName, lastName, email } = userData;
    try {
      const result = await decideid.registerAccount([firstName], [lastName], [email]);
      // Process result
      if ("ok" in result) {
        const {decide_id, token} = result.ok;
        setToken(token);

        return decide_id;
      } else if ("err" in result) {
        throw new Error(result.err);
      } else {
        throw new Error("Unexpected result from registerAccount");
      }
    } catch (error) {
      console.error("Failed to create profile on server:", error);
      throw error;
    }
  }

  // TODO: should consider optimistic mutations
  const createProfileMutation = useMutation({
    mutationFn: createProfileOnServer,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["profile"] });
    },
  });

  const refreshProfile = () => {
    queryClient.invalidateQueries({ queryKey: ["profile"] });
  };

  const hardRefreshProfileCache = async (): Promise<void> => {
    await queryClient.invalidateQueries({ queryKey: ["profile"] });
    await queryClient.refetchQueries({ queryKey: ["profile"] });
  };

  const clearProfile = () => {
    queryClient.clear();
    queryClient.setQueryData(["profile"], null);
  };

  useEffect(() => {
    if (isConnected && decideid) {
      console.log(principal);
      refreshProfile();
    }
  }, [decideid, isConnected]);

  return {
    profile: accountInfo?.profile,
    account: accountInfo?.account,
    isLoading,
    isSuccess,
    isError,
    error,
    updateProfile: updateProfileMutation.mutate,
    createProfile: createProfileMutation.mutate,
    hardRefreshProfileCache,
    refreshProfile,
    clearProfile,
    principal,
  };
}
