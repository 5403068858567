import React from 'react';
import { Outlet } from 'react-router-dom';

// Components
import { Layout, NavBarV2, TopBar, Footer } from '../../components/layout';
import { ProgressBar } from '../../components/uikit';

import { useErrorSignup } from './customHooks/useErrorSignup';

function AppLayout() {
  const { isLoading, profile } = useErrorSignup();

  if (isLoading || !profile) {
    return (
      <div className="h-screen flex justify-center items-center">
        <div className="max-w-[240px] w-full">
          <ProgressBar text="Loading ..." />
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Layout>
        <Layout.Aside>
          <NavBarV2 />
        </Layout.Aside>

        <div className="col-span-full xl:col-span-12 xl:col-start-3 w-full">
          <Layout.Header>
            <TopBar />
          </Layout.Header>
          <Layout.Main>
            <Outlet />
          </Layout.Main>
        </div>
      </Layout>

      <Layout.Footer>
        <Footer />
      </Layout.Footer>
    </React.Fragment>
  );
}

export default AppLayout;