import React from 'react';
import { motion } from 'framer-motion';

// Types
import { MotionTextContainerPropTypes } from '../types';

export const MotionTextContainer: React.FC<MotionTextContainerPropTypes> = ({ 
  children, 
  delay = 0.4,
  duration = 0.6,
}) => {
  const variants = {
    hidden: { opacity: 0, scale: 0.8, filter: 'blur(10px)' },
    visible: { opacity: 1, scale: 1, filter: 'blur(0px)' },
  }
  const transition = { duration, delay }
  
  return (
    <motion.div
      variants={variants}
      initial="hidden"
      animate="visible"
      transition={transition}
    >
      {children}
    </motion.div>
  );
}
