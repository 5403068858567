import { useEffect } from "react";
import { useParams } from "react-router";
import { Principal } from "@dfinity/principal";
import { useActors } from "../../../hooks/actors";
import { useIdentity } from "../../../hooks/useIdentity";

export const useAddPohCandidate = () => {
  const { disconnect } = useIdentity();
  const { vcCandidateId } = useParams<{ vcCandidateId: string }>();
  const { decideid_assets } = useActors();

  useEffect(() => {
    console.log("decideid_assets::", decideid_assets);
    
    if (decideid_assets) {
      decideid_assets.add_poh_candidate(Principal.fromText(vcCandidateId))
      .then(res => {
        if (res.Ok != undefined) {
          alert("PoH Verified Credentials initialized.");
          
          if (window && window?.opener) {
            window.opener.postMessage({ type: "add_poh_candidate", isOk: true, isErr: false }, "*");
          }

          disconnect();
          window.close();
        } else {
          console.log("ADD_POH_CANDIDATE_RES::", res);
        }
      }).catch(e => {
        console.error("[ERROR]::[add_poh_candidate]::", e);
      });
    }
  }, [decideid_assets]);
}