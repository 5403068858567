import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import { useActors } from "./actors";
import { useIdentity } from "./useIdentity";
import { decideid_types } from "../canister_types";
import { FEATURE_REFRESH_INTERVAL } from "../utils/constant";

interface FeatureFlagMap {
  [key: string]: boolean;
}

export type FeatureFlagNameStr = keyof decideid_types.FeatureFlagName;

export function useFeature() {
  const queryClient = useQueryClient();
  const { decideid } = useActors();
  const { isConnected } = useIdentity();

  const fetchFeature = async (): Promise<FeatureFlagMap> => {
    if (decideid) {
      const allFlags = await decideid.getAllFeatureFlags();
      const featureFlags: FeatureFlagMap = {};

      for (const flag of allFlags) {
        featureFlags[flag.name] = flag.isEnabled;
      }

      return featureFlags;
    }
    return {}; 
  };

  const {
    data: featureFlags,
    error,
    isError,
    isLoading,
    isSuccess,
  }: UseQueryResult<FeatureFlagMap, Error> = useQuery({
    queryKey: ["Feature"],
    queryFn: fetchFeature,
    retry: false,
    refetchInterval: FEATURE_REFRESH_INTERVAL, // auto-refresh
    enabled: !!isConnected && !!decideid, // Only run if connected and decideid exists
  });

  const refreshFeature = async () => {
    await queryClient.invalidateQueries({ queryKey: ["Feature"] });
  };

  const isFeatureEnabled = (name: FeatureFlagNameStr): boolean => {
    return featureFlags?.[name] ?? false;
  };

  return {
    featureFlags,
    isLoading,
    isSuccess,
    isError,
    error,
    isFeatureEnabled,
    refreshFeature,
  };
}