import React, { ReactNode } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import cn from 'classnames';

interface LoaderContainerProps {
  isLoading: boolean;
  error?: Error | null;
  LoadingComponent: React.ElementType;
  ErrorComponent: React.ElementType;
  children: ReactNode;
  transitionDuration?: number;
  className?: string;
}

const MotionDiv: React.FC<
  Pick<LoaderContainerProps, 'children' | 'transitionDuration' | 'className'> & {
    name: string;
  }
> = ({ children, transitionDuration, name, className }) => {
  const animationVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <motion.div
      key={name}
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={animationVariants}
      transition={{ duration: transitionDuration }}
      className={cn(className, 'h-full')}
    >
      {children}
    </motion.div>
  );
}

export const LoaderContainer: React.FC<LoaderContainerProps> = ({
  isLoading,
  error,
  LoadingComponent,
  ErrorComponent,
  children,
  transitionDuration = 0.5,
  className,
}) => {
  return (
    <AnimatePresence>
      {isLoading ? (
        <MotionDiv
          name="loading"
          transitionDuration={transitionDuration}
          className={className}
        >
          <LoadingComponent />
        </MotionDiv>
      ) : error ? (
        <MotionDiv
          name="error"
          transitionDuration={transitionDuration}
          className={className}
        >
          <ErrorComponent />
        </MotionDiv>
      ) : (
        <MotionDiv
          name="content"
          transitionDuration={transitionDuration}
          className={className}
        >
          {children}
        </MotionDiv>
      )}
    </AnimatePresence>
  );
};
