import React from 'react';

// Component
import { GridV2 } from '../GridV2';
import { LinkColumns } from './LinkColumns';
import { Info } from './Info';
import { PromoBanner } from './PromoBanner';

export const Footer = () => (
  <div className="px-4 py-10 lg:px-10 lg:py-12">    
    <GridV2 lgColumns={12} mdColumns={8} mobColumns={4}>
      <div className="col-span-full xl:col-span-4">
        <Info />
      </div>

      <div className="col-span-full xl:col-span-6 xl:col-start-6">
        <LinkColumns />
      </div>

      <div className="col-span-full">
        <PromoBanner />
      </div>
    </GridV2>
  </div>
)