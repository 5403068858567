import React from 'react';

// Components
import { NavMobile } from './NavMobile';
import { NavDesktop } from './NavDesktop';

export const NavBarV2 = () => (
  <React.Fragment>
    <div className="block xl:hidden">
      <NavMobile />
    </div>
    <div className="hidden xl:block">
      <NavDesktop />
    </div>
  </React.Fragment>
);