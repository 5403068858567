import React from 'react';
import cn from 'classnames';

// Components
import { TextInput } from '@modclub-app/uikit';

// Types
import { FormTypes } from './types';

export const Form: React.FC<FormTypes> = ({ fields, onSubmit, onChange, classNames, children }) => {
  const [formData, setFormData] = React.useState<Record<string, string>>(
    fields.reduce((acc, field) => {
      acc[field.name] = '';
      return acc;
    }, {} as Record<string, string>)
  );

  const handleChange = (name: string, value: string) => {
    setFormData({ ...formData, [name]: value });
    if (onChange) {
      onChange(name, value);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form 
      onSubmit={handleSubmit} 
      className={cn(classNames, "space-y-4")}
    >
      {fields.map((field) => (
        <div key={field.name} className="flex flex-col">
          <TextInput 
            label={field.label}
            type={field.type || 'text'}
            placeholder={field.placeholder}
            inputId={field.name}
            name={field.name}
            defaultValue={field.value}
            onChange={(e) => handleChange(field.name, e.target.value)}
            theme="blue"
          />
        </div>
      ))}
      {children}
    </form>
  );
};
