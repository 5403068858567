import * as React from "react";
import { useInternetIdentity } from "../../../contexts/InternetIdentityContext";

// Components
import { Button } from '@modclub-app/uikit';
// import { EthereumLogin } from "../Ethereum/EthereumLogin";

// Data
import { useTranslation } from 'react-i18next';

export const Providers = () => {
  const { connect } = useInternetIdentity();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Button
        buttonText={t('app.pages.app.button_txt')}
        onClick={() => connect()}
        skin="standard"
        rounded="xl"
        size="large"
        theme="black"
        fullWidth
        spaceBetween
      >
        <span className="inline-block align-middle">
          <img
            src="/assets/internet-computer-icp-logo.svg"
            className="w-8"
            alt="logo"
          />
        </span>
      </Button>

      {/*
        * Data of comment July 23, 2024.
        * Button is hidden just for now, we will back to this issue later;
        * Need to get in component icOnly prop
        * */}
      {/* { icOnly ? null: <EthereumLogin/> } */}
    </React.Fragment>
  );
}
