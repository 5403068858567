import React from 'react';
import { motion } from 'framer-motion';

// Types
import { RadialGradientPropTypes } from '../../../smartKit/SplashScreen/types';

export const RadialGradientElem: React.FC<RadialGradientPropTypes> = ({ 
  gradientStyle, 
  duration 
}) => {
  const initial = { scale: 6 }
  const animate = { scale: [0.85, 1.1, 0.85], opacity: [0.5, 0.8, 0.5] }
  const transition = {
    duration: duration,
    repeat: Infinity,
    ease: "easeInOut"
  }

  return (
    <motion.div
      className="w-[58rem] h-[58rem]"
      initial={initial}
      animate={animate}
      transition={transition}
      style={{ backgroundImage: `${gradientStyle}` }}
    />
  );
}