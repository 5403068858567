import { decideid_types } from "../canister_types"

export const getAccountStatus = (accountState: decideid_types.AccountState, accountCurrentOnboardingStep: decideid_types.OnboardingStep) => {
    const state = Object.keys(accountState)[0];
    const onboardingStep = Object.keys(accountCurrentOnboardingStep)[0];
    if (state == "onboarding" && onboardingStep == "poh_uniqueness") {
      return "Processing";
    } else if (state == "onboarding" && onboardingStep == "poh_liveness") {
      return "Onboarding";
    } else if (state == "approved") {
      return "Verified";
    } else if (state == "rejected") {
      return "Rejected";
    }

};
  