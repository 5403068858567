import * as React from "react";
import { createRoot } from "react-dom/client";

// Styles
import "./styles/global.scss";
import './styles/colors.scss';
import './styles/tailwind.scss';
import './styles/amplify.scss';
import './pages/Authorization/Ethereum/rainbowkit_style.scss';

import App from "./App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { readCanisterId } from "./utils/util";
import { I18nextProvider } from 'react-i18next';
import i18nInstance, { initI18n } from '../i18n';
import {Suspense} from 'react';
import './utils/bigint-tojson';

const queryClient = new QueryClient();
const canisterId = readCanisterId();
console.log("[DEBUG]::[THIS_CANISTER_ID]::", canisterId);

/**
 * @dfinity/agent requires this. Can be removed once it's fixed
 */
window.global = window;

initI18n().then(() => {
    const root = createRoot(document.getElementById("app")!);

    root.render(
        <React.StrictMode>
            <I18nextProvider i18n={i18nInstance}>
                <QueryClientProvider client={queryClient}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <App />
                    </Suspense>
                </QueryClientProvider>
            </I18nextProvider>
        </React.StrictMode>
    );
});
