import React from 'react';
import '@aws-amplify/ui-react/styles.css';
import { Authenticator, CheckboxField, TextField, useAuthenticator } from '@aws-amplify/ui-react';
import NavBar from './components/nav/nav';
interface AuthGuardProps {
  children: React.ReactNode;
}

export function DeveloperAuthGuard({ children }: AuthGuardProps) {
  return (
    <Authenticator
      components={{
        SignUp: {
          FormFields() {
            const { validationErrors } = useAuthenticator();

            return (
              <>
                <Authenticator.SignUp.FormFields />
                <TextField
                  label="Company"
                  name="custom:company"
                  placeholder="Enter your company name"
                  required
                />
                <CheckboxField
                  errorMessage={validationErrors.acknowledgement as string}
                  hasError={!!validationErrors.acknowledgement}
                  name="acknowledgement"
                  value="yes"
                  label="I agree with the Terms and Conditions"
                />
              </>
            );
          },
        },
      }}
      services={{
        async validateCustomSignUp(formData) {
          if (!formData.acknowledgement) {
            return {
              acknowledgement: 'You must agree to the Terms and Conditions',
            };
          }
          if (!formData['custom:company']) {
            return {
              'custom:company': 'Company name is required',
            };
          }
        },
      }}
    >
      {({ signOut, user }) => (
        <div>
          <NavBar username={user?.signInDetails?.loginId} onSignOut={signOut} />
          {/* Protected content */}
          {/* Protected content */}
          {children}
        </div>
      )}
    </Authenticator>
  );
}