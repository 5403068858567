import React, { createContext, useContext, useState, useEffect, useCallback, ReactNode } from 'react';
import { AuthClient } from '@dfinity/auth-client';
import { Identity } from '@dfinity/agent';
import { useNavigate } from 'react-router';

import { decideid_assets_actor } from "../actors_by_env";
const derivationOrigin = `https://${decideid_assets_actor.canisterId}.icp0.io`;

interface UseInternetIdentityReturn {
  connect: () => void;
  disconnect: () => Promise<void>;
  identity: Identity | null;
  isConnected: boolean;
  isInitializing: boolean;
}

interface InternetIdentityProviderProps {
  children: ReactNode;
}

const InternetIdentityContext = createContext<UseInternetIdentityReturn | undefined>(undefined);

const detectBrowser = (): string => {
  const userAgent = window.navigator.userAgent;
  if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
    return "Safari";
  } else if (userAgent.includes("Chrome")) {
    return "Chrome";
  }
  return "Other";
};

export const InternetIdentityProvider: React.FC<InternetIdentityProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const [authClient, setAuthClient] = useState<AuthClient | null>(null);
  const [identity, setIdentity] = useState<Identity | null>(null);
  const [isInitializing, setIsInitializing] = useState<boolean>(true);

  const network = process.env.DFX_NETWORK || "local";
  let providerUrl = "https://identity.ic0.app";

  if (network === "local") {
    const browser = detectBrowser();
    if (browser === "Safari") {
      providerUrl = process.env.LOCAL_II_CANISTER_SAFARI || "";
    } else {
      providerUrl = process.env.LOCAL_II_CANISTER || "";
    }
  }

  useEffect(() => {
    async function initializeAuthClient() {
      const client = await AuthClient.create();
      setAuthClient(client);

      if (await client.isAuthenticated()) {
        setIdentity(client.getIdentity());
      }
      setIsInitializing(false);
    }

    initializeAuthClient();
  }, []);

  const connect = useCallback( () => {
    if (authClient) {
       authClient.login({
        identityProvider: providerUrl,
        onSuccess: () => {
          setIdentity(authClient.getIdentity());
          navigate('/app');
        },
        derivationOrigin: network !== "local"
          ? derivationOrigin
          : undefined,
      });
    }
  }, [authClient, providerUrl]);

  const disconnect = async () => {
    if (authClient) {
      await authClient.logout();
      setIdentity(null);
    }
  };

  return (
    <InternetIdentityContext.Provider
      value={{ connect, disconnect, isInitializing, identity, isConnected: !!identity }}
    >
      {children}
    </InternetIdentityContext.Provider>
  );
};

export const useInternetIdentity = (): UseInternetIdentityReturn => {
  const context = useContext(InternetIdentityContext);
  if (context === undefined) {
    throw new Error('useInternetIdentity must be used within an InternetIdentityProvider');
  }
  return context;
};
