import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useIdentity } from "./useIdentity";
import { useActors } from "./actors";
import { Principal } from "@dfinity/principal";
import { decidedid_actor } from "../actors_by_env";
import { ledger_types, decideid_types } from "../canister_types";
import { useConfig } from "./useConfig";
import { TOKENS_DECIMAL } from "../utils/constant";
import { useTranslation } from "react-i18next";
import { logdna } from '../utils/logger';

type WalletData = {
  balance: {
    DCD: number | undefined;
    ICP: number | undefined;
  };
  subWalletAddress: {
    DCD: ledger_types.Subaccount | undefined;
    ICP: ledger_types.Subaccount | undefined;
  };
  subWalletReverificationAddress: {
    DCD: ledger_types.Subaccount | undefined;
    ICP: ledger_types.Subaccount | undefined;
  };
  supportedTokens: string[];
  tokenRequiredAction: { 'canStartPoh': boolean; 'canStartReverification': boolean } | undefined;
  icrc1Fee: bigint;
};

type UseWalletInterface = WalletData & {
  isLoading: boolean;
  isFetching: boolean;
  error: Error | null; 
  pohFee: { DCD: number | undefined, ICP: number | undefined };
  transferPohFeeStatus: "error" | "idle" | "pending" | "success";
  withdrawAmountLoadingStatus: "error" | "idle" | "pending" | "success";
  transferPohFee: (token: 'DCD' | 'ICP') => Promise<void>;
  withdrawAmount: ({ walletAddress, transferAmount }: { walletAddress: string; transferAmount: number; }) => Promise<void>;
  transferPohReverificationFeeStatus: "error" | "idle" | "pending" | "success";
  transferPohReverificationFee: (token: 'DCD' | 'ICP') => Promise<void>;
  refresh: () => void;
  icrc1FeeDCD: number | null;
};

export function convertToDCD(
  amount: bigint,
  precision = 4
): number {
  const precisionFactor = BigInt(Math.pow(10, TOKENS_DECIMAL - precision));
  const adjustedAmount = amount / precisionFactor;
  
  // Convert to number if the value can be safely represented
  if (adjustedAmount < BigInt(Number.MAX_SAFE_INTEGER)) {
    return Number(adjustedAmount) / Math.pow(10, precision);
  } else {
    throw new Error("Amount is too large to convert to a safe number.");
  }
}

export function convertFromDCD(
  dcd: number,
): bigint {
  return BigInt(Math.round(dcd * Math.pow(10, 8)));
}

async function fetchWalletData(principal: string, ledger: ledger_types._SERVICE, decideid: decideid_types._SERVICE): Promise<WalletData> {
  const balancePromise = ledger.icrc1_balance_of({
    owner: Principal.fromText(principal),
    subaccount: [],
  });
  const subWalletAddressPromise = decideid.getSubWalletBlobByCaller("POH");
  const subWalletReverificationAddressPromise = decideid.getSubWalletBlobByCaller("POH_REVERIFICATION");
  const canStartPohPromise = decideid.checkTokenGate({ 'can_start_poh' : null });
  const canStartReverificationPromise = decideid.checkTokenGate({ 'can_start_reverification' : null });
  const icrc1FeeProm = ledger.icrc1_fee();
  const [balanceResult, subWalletAddressResult, subWalletReverificationAddressResult, canStartPoh, canStartReverification, icrc1Fee] = await Promise.all([
    balancePromise,
    subWalletAddressPromise,
    subWalletReverificationAddressPromise,
    canStartPohPromise,
    canStartReverificationPromise,
    icrc1FeeProm
  ]);

  const balance = {
    DCD: convertToDCD(balanceResult, 8),
    ICP: -1, // Not implemented
  };

  const subWalletAddress = {
    DCD: subWalletAddressResult[0], // The return is an array
    ICP: undefined,  // Not implemented
  };

  const subWalletReverificationAddress = {
    DCD: subWalletReverificationAddressResult[0], // The return is an array
    ICP: undefined,  // Not implemented
  };

  const supportedTokens = ['DCD']; // Implement if needed
  const tokenRequiredAction = {
    'canStartPoh': canStartPoh,
    'canStartReverification': canStartReverification
  };

  return {
    balance,
    subWalletAddress,
    subWalletReverificationAddress,
    supportedTokens,
    tokenRequiredAction,
    icrc1Fee
  };
}


function transferErrorToString(error: ledger_types.TransferError, t): string {
  if ('GenericError' in error) {
    return t('ledger_errors.generic_error', {
      message: error.GenericError.message,
      code: error.GenericError.error_code
    });
  } else if ('TemporarilyUnavailable' in error) {
    return t('ledger_errors.temporarily_unavailable');
  } else if ('BadBurn' in error) {
    return t('ledger_errors.bad_burn', { minBurnAmount: error.BadBurn.min_burn_amount });
  } else if ('Duplicate' in error) {
    return t('ledger_errors.duplicate', { duplicateOf: error.Duplicate.duplicate_of });
  } else if ('BadFee' in error) {
    return t('ledger_errors.bad_fee', { expectedFee: error.BadFee.expected_fee });
  } else if ('CreatedInFuture' in error) {
    return t('ledger_errors.created_in_future', { ledgerTime: error.CreatedInFuture.ledger_time });
  } else if ('TooOld' in error) {
    return t('ledger_errors.too_old');
  } else if ('InsufficientFunds' in error) {
    return t('ledger_errors.insufficient_funds', { balance: error.InsufficientFunds.balance });
  } else {
    return t('ledger_errors.unknown_error');
  }
}


// Define the types for handleTransferTokenError
export type HandleTransferTokenErrorFunction = (error: Error, variables: "DCD" | "ICP", context: unknown) => void;

export function useWallet(handleTransferTokenError?: HandleTransferTokenErrorFunction): UseWalletInterface {
  const queryClient = useQueryClient();
  const { principal } = useIdentity();
  const { ledger, decideid } = useActors();
  const { t } = useTranslation();

  const { data, error, isLoading, isFetching } = useQuery(
    {
      queryKey: ["walletData"],
      queryFn: () => fetchWalletData(principal, ledger, decideid),
      enabled: !!principal && !!decideid && !!ledger,
    }
  );
  const { config, isSuccess: isConfigLoadSuccess } = useConfig();

  const transferPohFeeMutation = useMutation(
    { 
      mutationKey: ['transferPohFee'],
      mutationFn: async (token: "DCD" | "ICP") => {
        if (isLoading || !data || !isConfigLoadSuccess) {
          throw Error("Loading error");
        }

        if (token == 'DCD') {
          const targetAcc: ledger_types.Account = {
            owner: Principal.fromText(decidedid_actor.canisterId),
            subaccount: [data.subWalletAddress.DCD],
          };

          const amount = convertFromDCD(config?.poh_verification_fee) - data.icrc1Fee
          const params: ledger_types.TransferArg = {
            to: targetAcc,
            fee: [],
            memo: [],
            from_subaccount: [],
            created_at_time: [],
            amount: amount,
          };

          logdna.info(`User (${principal}) attempting to transfer POH verification fee : ${amount}`);
          const result = await ledger.icrc1_transfer(params);
          if ('Ok' in result) {
            const blockIndex = result.Ok;
            logdna.info(`POH fee transfer successful for user (${principal}), block index: ${blockIndex}`);
            console.log('User Transfer successful, block index:', blockIndex);
          } else if ('Err' in result) {
            logdna.info(`POH fee transfer failed for user (${principal}), error: ${transferErrorToString(result.Err, t)}`);
            throw Error(transferErrorToString(result.Err, t));
          }
        } else {
          throw Error("Not Implemented.");
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["walletData"]
        });
      },
      onError(error, variables, context) {
        console.error('transferPohFeeMutation failed with error:', error);
        if (handleTransferTokenError) {
          handleTransferTokenError(error, variables, context);
        }
      },
    }
  );

  const refresh = () => {
    queryClient.invalidateQueries({
      queryKey: ["walletData"]
    });
  };
  

  const transferPohReverificationFeeMutation = useMutation(
    { 
      mutationKey: ['transferPohReverificationFee'],
      mutationFn: async (token: "DCD" | "ICP") => {
        if (isLoading || !data || !isConfigLoadSuccess) {
          throw Error("Loading error");
        }

        if (token == 'DCD') {
          const targetAcc: ledger_types.Account = {
            owner: Principal.fromText(decidedid_actor.canisterId),
            subaccount: [data.subWalletReverificationAddress.DCD],
          };

          const amount = convertFromDCD(config?.poh_reverification_fee) - data.icrc1Fee
          const params: ledger_types.TransferArg = {
            to: targetAcc,
            fee: [],
            memo: [],
            from_subaccount: [],
            created_at_time: [],
            amount: amount,
          };

          logdna.info(`User (${principal}) attempting to transfer POH reverification fee : ${amount}`);
          const result = await ledger.icrc1_transfer(params);
          if ('Ok' in result) {
            const blockIndex = result.Ok;
            logdna.info(`POH reverification fee transfer successful for user (${principal}), block index: ${blockIndex}`);
            console.log('User Transfer successful, block index:', blockIndex);
          } else if ('Err' in result) {
            logdna.info(`POH reverification fee transfer failed for user (${principal}), error: ${transferErrorToString(result.Err, t)}`);
            throw Error(transferErrorToString(result.Err, t));
          }
        } else {
          throw Error("Not Implemented.");
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["walletData"]
        });
      },
      onError(error, variables, context) {
        console.error('transferPohReverificationFeeMutation failed with error:', error);
        if (handleTransferTokenError) {
          handleTransferTokenError(error, variables, context);
        }
      },
    }
  );

  const withdrawMutation = useMutation({
    mutationKey: ['withdrawMutation'],
    mutationFn: async ({ 
      walletAddress, 
      transferAmount 
    }: { 
      walletAddress: string; 
      transferAmount: number 
    }) => {
      try {
        const targetAcc: ledger_types.Account = { 
          owner: Principal.fromText(walletAddress), 
          subaccount: [] 
        };
        const amount = convertFromDCD(transferAmount);
        const params: ledger_types.TransferArg = {
          to: targetAcc,
          fee: [],
          memo: [],
          from_subaccount: [],
          created_at_time: [],
          amount: amount,
        };
  
        const result = await ledger.icrc1_transfer(params);

        if ('Ok' in result) {
          console.log('withdrawMutation successful:', result.Ok);
          return result;
        } else if ('Err' in result) {
          throw new Error('withdrawMutation: An unexpected error occurred inside request for ledger.icrc1_transfer.');
        }
      } catch (error) {
        console.error('Withdrawal failed:', error);
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["walletData"]
      });
    },
    onError(error, variables, context) {
      console.error('Withdrawal mutation failed with error:', error);
      if (handleTransferTokenError) {
        handleTransferTokenError(error, variables, context);
      }
    },
  });
  

  return {
    supportedTokens: data?.supportedTokens || [],
    refresh,
    tokenRequiredAction : data?.tokenRequiredAction,
    balance: data?.balance || { DCD: undefined, ICP: undefined },
    pohFee: { DCD: config?.poh_verification_fee, ICP: undefined },
    icrc1FeeDCD: data?.icrc1Fee ? convertToDCD(data?.icrc1Fee, 8) : null,
    subWalletAddress: data?.subWalletAddress || { DCD: undefined, ICP: undefined },
    isLoading,
    isFetching,
    error,
    transferPohFee: transferPohFeeMutation.mutate,
    transferPohFeeStatus: transferPohFeeMutation.status,
    withdrawAmount: withdrawMutation.mutate,
    withdrawAmountLoadingStatus: withdrawMutation.status,
    transferPohReverificationFee: transferPohReverificationFeeMutation.mutate,
    transferPohReverificationFeeStatus: transferPohReverificationFeeMutation.status,
  };

}
