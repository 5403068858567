import React from 'react';

type LayoutTypes<
  Props = Record<string, never | React.ReactNode>
> = React.FC<Props & { children: React.ReactNode }>;

export const Layout: LayoutTypes & {
  Header: LayoutTypes,
  Aside: LayoutTypes,
  Main: LayoutTypes,
  Footer: LayoutTypes,
  Section: LayoutTypes<{ title?: string; }>,
} = ({ 
  children 
}) => (
  <div className="px-4 lg:px-5 xl:flex xl:flex-row gap-4">
    {children}
  </div>
);

Layout.Header = ({ children }) => (
  <header className="sticky top-0 w-full z-30">
    {children}
  </header>
);

Layout.Aside = ({ children }) => (
  <div className="xl:sticky xl:top-0 xl:h-screen xl:z-20">{children}</div>
);

Layout.Main = ({ children }) => (
  <main className="w-full overflow-y-auto pt-14 xl:pt-0 flex flex-col gap-16">{children}</main>
);

Layout.Footer = ({ children }) => (
  <footer className="w-full mt-6 xl:mt-14 bg-white">{children}</footer>
);

Layout.Section = ({ children, title }) => (
  <div className="flex flex-col gap-6">
    {title && (
      <div className="text-[48px]">{title}</div>
    )}
    {children}
  </div>
);