import React from "react";

// Hooks
import { useFetchCreateLiveness } from './customHooks/useFetchCreateLiveness';

// Components
import { LivenessProcess } from "./LivenessProcess";
import { ProgressBarInterface } from './ProgressBarInterface';
import { RateLimitErrorMessage } from './RateLimitErrorMessage';

// Types
import { FaceLivenessProps } from './types';

const FaceLiveness: React.FC<FaceLivenessProps> = ({ 
  faceLivenessAnalysisCb, 
  user_id,
}) => {
  const { 
    sessionId,
    isRateLimitError 
  } = useFetchCreateLiveness(user_id);
    
  return (
    <React.Fragment>
      {!sessionId && !isRateLimitError && (
        <ProgressBarInterface />
      )}

      {isRateLimitError && (
        <RateLimitErrorMessage />
      )}

      {sessionId && (
        <LivenessProcess 
          sessionId={sessionId}
          callback={faceLivenessAnalysisCb}
        />
      )}
    </React.Fragment>
  );
}

export default FaceLiveness;