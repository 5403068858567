import React from 'react';

// Components
import { DynamicIcon, getColorByName } from '@modclub-app/uikit';
import { TextSection } from '../TextSection';

// Types
import { SuccessModalPropTypes } from './types';

export const SuccessModal: React.FC<SuccessModalPropTypes> = ({ 
  title, 
  subTitle,
  children,
  iconName
}) => (
  <div className='flex flex-col items-center gap-6 w-full'>
    {iconName && (
      <DynamicIcon 
        size={80}
        strokeWidth={0.8}
        iconName={iconName}
        stroke={getColorByName('B00')?.hex}
      />
    )}

    <TextSection
      title={title}
      text={subTitle}
      textCenter
    />

    {children && children}
  </div>
);