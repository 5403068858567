import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Button } from '@modclub-app/uikit';
import { SimpleModal } from '../../../uikit';

export const ErrorStep: React.FC<{ 
  onClick: () => void; 
  title: string; 
  subTitle: string; 
}> = ({ onClick, title, subTitle }) => {
  const { t } = useTranslation();
  return (
    <SimpleModal
      title={title}
      subTitle={subTitle}
      iconName="DangerCircleIcon"
    >
      <Button
        buttonText={t('app.components.smartkit.withdraw_modal.error_modal.button_name')}
        onClick={onClick}
        rounded="lg"
        iconName="ArrowRightIcon"
        size="large"
        skin="standard"
        fullWidth
        theme="black"
      />
    </SimpleModal>
  );
}