import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@modclub-app/uikit';
import { Form } from '../../../components/smartKit';
import { ProfileLayout } from '../ProfileLayout';

import { FormFields } from './types';
import { useProfile } from '../../../hooks/useProfile';
import { useProfileStore } from '../../../store/useProfileStore';

export const EditMode = () => {
  const { updateProfile, updateProfileLoadingStatus } = useProfile();
  const { 
    isButtonActive, 
    handleChange,
    setEditMode,
    formData,
    setIsSaveButtonActive,
  } = useProfileStore();
  
  const { t } = useTranslation();
  const formField = t('app.pages.profile.form.main_fields', { 
    returnObjects: true,
  }) as FormFields;

  const handleSubmitForm = async () => {
    const emailArray: [] | [string] = formData.email ? [formData.email] as [string] : [];
    const profileData = { ...formData, email: emailArray };
    
    try {
      setIsSaveButtonActive(false);
      await updateProfile(profileData);
      setEditMode(false);
    } catch (error) {
      console.error('Failed to update profile:', error);
    }
  };

  return (
    <Form
      fields={[
        { name: 'firstName', ...formField['firstName'], value: formData['firstName'] },
        { name: 'lastName', ...formField['lastName'], value: formData['lastName'] },
        { name: 'email', ...formField['email'], value: formData['email'] },
      ]}
      onSubmit={handleSubmitForm}
      onChange={handleChange}
    >      
      <ProfileLayout.FormButton>
        <Button
          type="submit"
          onClick={handleSubmitForm}
          disabled={!isButtonActive}
          size="medium"
          skin="standard"
          theme="black"
          rounded="full"
          loading={updateProfileLoadingStatus === 'pending'}
          buttonText={t('app.pages.profile.form.action_form_button_text')}
        />
        <Button
          onClick={() => setEditMode(false)}
          rounded="full"
          size="medium"
          skin="inlineGrey"
          iconName="UndoLeftRoundSquareIcon"
          theme="black"
          buttonText={t('app.pages.profile.form.cancel_form_button_text')}
        />
      </ProfileLayout.FormButton>
    </Form>
  );
}