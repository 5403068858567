import React from "react";
import { motion, useScroll, useTransform } from 'framer-motion';
import { useState } from 'react';
// Hooks
import { useInfoItemsList } from '../customHooks/useInfoItemsList';

// Components
import { 
  CardId, 
  RadialGradients, 
  Modal, 
  Portal 
} from '../../../components/uikit';
import { MainLayout } from '../../../components/layout';
import { Onboarding } from "../Onboarding";
import { DepositModal } from "../DepositModal";

export const PageContainer = () => {
  const { scrollY } = useScroll();
  const { infoItemsList } = useInfoItemsList();
  
  const [ showPaymentModal, setShowPaymentModal ] = useState<boolean>(false);

  const backgroundY = useTransform(scrollY, [0, 500], [0, -100]);
  const contentY = useTransform(scrollY, [0, 500], [0, -200]);

  return (
    <MainLayout
      theme="black"
      className="relative min-h-screen h-svh overflow-hidden"
      topMessage={(
        <Onboarding setShowPaymentModal={setShowPaymentModal}/>
      )}
    >
      <div className="h-full flex items-center justify-center">
        <motion.div 
          className="absolute top-0 left-0 w-full h-full"
          style={{ y: backgroundY }}
        >
          <RadialGradients />
        </motion.div>

        <motion.div 
          className="relative z-20 flex justify-center flex-col items-center gap-10"
          style={{ y: contentY }}
        >
          <CardId 
            status={infoItemsList.status}
            decideId={infoItemsList.token}
          />
        </motion.div>
      </div>

    {/* Deposit Modal */}
    { showPaymentModal && (
      <Portal>
        <Modal closeModal={() => setShowPaymentModal(false)}>
          <DepositModal />
        </Modal>
      </Portal>
    )}
    </MainLayout>
  );
}