import React from 'react';
import { Button } from "@modclub-app/uikit";
import { useTranslation } from 'react-i18next';

export default function LoginButton() {
  const { t } = useTranslation();
  return <Button
      disabled={true}
      onClick={()=>{}}
      buttonText={t('app.pages.authorization.solana.sign_in')}
      skin="inline"
      size="small"
      theme="black"
    />;
}
