import React from 'react';
import { motion } from "framer-motion";

// Components
import { Picture } from './Pucture';
import { CardLabel } from './CardLabel';
import { CopyableText } from '../../uikit';
import { CardStatus } from './CardStatus';
import { Typography } from '@modclub-app/uikit';

// Types
import { CardIdPropTypes } from './types';

// Utils
import { truncateMiddle } from '../../../utils/util';

// Data
import { DATA_COMMON } from '../../../data/dataCommon';

export const CardId: React.FC<CardIdPropTypes> = ({ 
  decideId,
  status 
}) => {
  const { bottomDecorImage, labels } = DATA_COMMON.cardId;
  const variants = {
    hidden: { opacity: 0, scale: 0.8, filter: 'blur(10px)' },
    visible: { opacity: 1, scale: 1, filter: 'blur(0px)' },
  }
  const transition = { duration: 0.6, delay: 0.4 }

  console.log('decideId -> ', decideId);

  return (
    <motion.div 
      className='bg-C20 rounded-3xl max-w-sm px-5 pt-4 pb-6 flex flex-col gap-4 w-full drop-shadow-md'
      variants={variants}
      initial="hidden"
      animate="visible"
      transition={transition}
    > 
      <div className='flex flex-col text-center gap-3'>
        <Picture />
        <Typography
          size="2xl"
          weight="medium"
          color="F00"
        >
          {DATA_COMMON.cardId.title}
        </Typography>
      </div>

      <div className='flex items-center justify-center flex-col gap-3'>

        {decideId && (
          <CardLabel label={labels.decideId}>
            <CopyableText
              buttonText={truncateMiddle(decideId, 45)}
              textToCopy={decideId}
            />
          </CardLabel>
        )}

        <CardLabel label={labels.verification}>
          <Typography 
            color="F00" 
            size="base" 
            weight="medium"
          >
            Proof of Personhood
          </Typography>
        </CardLabel>

        {status && (
          <CardLabel label={labels.status}>
            <CardStatus status={status} />
          </CardLabel>
        )}
      </div>

      <div className='flex justify-center pt-4'>
        <img src={bottomDecorImage} alt="decor_image" />
      </div>
    </motion.div>
  );
}