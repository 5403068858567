import React from 'react';

// Components
import { Typography } from '@decide-ai/uikit';


export const MainSubTitle: React.FC<{ text: string; }> = ({ text }) => (
  <div className='col-start-1 col-end-13 md:col-start-6'>
    <Typography color='dark-01' size="2xl">
      {text}
    </Typography>
  </div>
);