import React from 'react';
import { motion } from 'framer-motion';
import cn from 'classnames';

type NavLayoutTypes<Props = Record<string, never | React.ReactNode>> = React.FC<Props & { children: React.ReactNode; }>;

export const NavLayout: NavLayoutTypes<{ isCollapsed: boolean; }> & {
  Ul: NavLayoutTypes<{ isCollapsed: boolean; }>;
  Li: NavLayoutTypes<{ delayNum: number; }>;
  Logo: NavLayoutTypes;
} = ({
  children,
  isCollapsed,
}) => {
  const navVariants = {
    expanded: { width: '200px', transition: { duration: 0.2, ease: "easeInOut" } },
    collapsed: { width: '60px', transition: { duration: 0.2, ease: "easeInOut" } },
  };

  return (
    <motion.nav className={
      cn(
        "flex justify-between p-4",
        "static py-2 px-4 flex-col",
        "bg-H110 "
      )}
      initial="expanded"
      animate={isCollapsed ? 'collapsed' : 'expanded'}
      variants={navVariants}
    >
      {children}
    </motion.nav>
  );
}

NavLayout.Ul = ({ children }) => (
  <ul className="flex flex-col gap-2.5 py-3.5 -ml-3 -mr-3">
    {children}
  </ul>
);

NavLayout.Li = ({ children, delayNum }) => (
  <motion.li
    initial={{ x: -10, opacity: 0 }}
    animate={{ x: 0, opacity: 1 }}
    transition={{ delay: delayNum * 0.1 }}
  >
    {children}
  </motion.li >
);

NavLayout.Logo = ({ children }) => (
  <div className="py-2 -ml-1">{children}</div>
);