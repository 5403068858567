import * as React from "react";
import { Route, Routes } from "react-router-dom"; // Use Routes instead of Switch
import { useActors } from "../../utils";

// Pages
import Main from "../Main";
import NewProfile from "../NewProfile";
import Logout from "../Logout";
import Authorization from '../Authorization';
import PoH from "../Poh";
import VerifiedCredentials from '../VerifiedCredentials';
import { useIdentity } from "../../hooks/useIdentity";
import { useFeature } from "../../hooks/useFeature";
import { useWallet } from "../../hooks/useWallet";

export default function AuthRouter() {
  const { isInitializing, identity } = useIdentity();
  const { isLoading: isLoadingFeatures } = useFeature();
  const { decideid } = useActors();

  // Just to async preload wallet data here, to improve the user experience. 
  useWallet();
  
  if (isInitializing) {
    return null;
  }

  const auth = new URLSearchParams(window.location.search).get('login');
  if (!identity) {
    return <Authorization icOnly={auth === 'ic'} />;
  }

  if (!decideid) {
    return <p>Spinning... Init actors</p>;
  }

  // TODO: We do not need this isLoadingFeature
  if (isLoadingFeatures) {
    return null;
  }

  return (
    <div>
      <Routes>
        <Route path="/app" element={<Main />} />
        <Route path="/poh" element={<PoH />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/signup" element={<NewProfile />} />
        <Route path="/vc/:vcCandidateId" element={<VerifiedCredentials />} />
      </Routes>
    </div>
  );
}
