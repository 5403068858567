import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { QRWalletAddress, TextSection } from '../../../components/uikit';

// Hooks
import { useIdentity } from "../../../hooks/useIdentity";

export const WalletDepositModal = () => {
  const { t } = useTranslation();
  const { principal } = useIdentity();

  return (
    <div className="max-w-xl w-full">
      <div className='flex flex-col gap-10 w-full'>
        <TextSection
          title={t('app.pages.main.wallet_address_modal.title')}
          text={t('app.pages.main.wallet_address_modal.sub_title')}
          textCenter
        />

        {principal && (
          <QRWalletAddress walletAddress={principal} />
        )}
      </div>
    </div>
  );
}