import * as React from "react";
import { Route, Routes } from "react-router-dom"; // Use Routes instead of Switch
import { useActors } from "../../utils";

// Pages / components
import MainPage from "../App";
import ProfilePage from '../Profile';
import NewProfile from "../NewProfile";
import FaqPage from "../FAQ";
import Logout from "../Logout";
import Authorization from '../Authentication';
import AppLayout from "../AppLayout";
import PoH from "../Poh";
import VerifiedCredentials from '../VerifiedCredentials';
import { useIdentity } from "../../hooks/useIdentity";
import { useFeature } from "../../hooks/useFeature";
import { useWallet } from "../../hooks/useWallet";
import VerificationEmailPage from '../VerificationEmail';

export default function AuthRouter() {
  const { isInitializing, identity } = useIdentity();
  const { isLoading: isLoadingFeatures } = useFeature();
  const { decideid } = useActors();

  // Just to async preload wallet data here, to improve the user experience. 
  useWallet();
  
  if (isInitializing) {
    return null;
  }

  if (!identity) {
    return <Authorization />;
  }

  if (!decideid) {
    return null;
  }

  // TODO: We do not need this isLoadingFeature
  if (isLoadingFeatures) {
    return null;
  }

  return (
    <div>
      <Routes>
        <Route path="/app" element={<AppLayout />}>
          <Route index element={<MainPage />} />
          <Route path="profile" element={<ProfilePage />} />
        </Route>
        <Route path="/poh" element={<PoH />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/signup" element={<NewProfile />} />
        <Route path="/vc/:vcCandidateId" element={<VerifiedCredentials />} />
        <Route path="/verify-email/:tokenId" element={<VerificationEmailPage />} />
        <Route path="/faq" element={<FaqPage />} />
      </Routes>
    </div>
  );
}
