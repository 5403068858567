 
import React from "react";
import {
  ActorProvider,
  InterceptorErrorData,
  InterceptorRequestData,
  createActorContext,
  createUseActorHook,
  isIdentityExpiredError,
} from "ic-use-actor";
import { useIdentity } from "../hooks/useIdentity";
import { ReactNode } from "react";
import { decidedid_actor, decideid_assets_actor, ledger_actor } from "../actors_by_env";
import { decideid_types, decideid_assets_types,ledger_types  } from "../canister_types";
  
const decideidCanisterId = decidedid_actor.canisterId;
const decideidIdlFactory = decidedid_actor.idlFactory;
const decideIdActorContext = createActorContext<decideid_types._SERVICE>();
export const useDecideidActor = createUseActorHook<decideid_types._SERVICE>(decideIdActorContext);

const decideidAssetsCanisterId = decideid_assets_actor.canisterId;
const decideidAssetsIdlFactory = decideid_assets_actor.idlFactory;
const decideIdAssetsActorContext = createActorContext<decideid_assets_types._SERVICE>();
export const useDecideidAssetsActor = createUseActorHook<decideid_assets_types._SERVICE>(decideIdAssetsActorContext);

const ledgerCanisterId = ledger_actor.canisterId;
const ledgerIdlFactory = ledger_actor.idlFactory;
const ledgerActorContext = createActorContext<ledger_types._SERVICE>();
export const useLedgerActor = createUseActorHook<ledger_types._SERVICE>(ledgerActorContext);
  
const Actors: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { identity, disconnect } = useIdentity();

  const errorLog = (error: unknown) => {
    if (typeof error === "object" && error !== null && "message" in error) {
      console.error("handleResponseError:", error.message as string);
    }
  };

  const handleResponseError = (data: InterceptorErrorData) => {
    console.error("onResponseError", data.error);
    if (isIdentityExpiredError(data.error)) {
      console.error("Login expired...");
      setTimeout(() => {
        disconnect(); // Clears the identity from the state and local storage. Effectively "logs the user out".
        window.location.reload(); // Reload the page to reset the UI.
      }, 1000);
      return;
    }

    if (typeof data === "object" && data !== null && "message" in data) {
      errorLog(data);
    }
  };

  const handleRequest = (data: InterceptorRequestData) => {
    console.log("Actors request: ", data.methodName, data.args);
    return data.args;
  };

  return (
    <ActorProvider<decideid_types._SERVICE>
      canisterId={decideidCanisterId}
      context={decideIdActorContext}
      identity={identity}
      idlFactory={decideidIdlFactory}
      onRequest={handleRequest}
      onRequestError={(error) => errorLog(error)}
      onResponseError={handleResponseError}
    >
      <ActorProvider<decideid_assets_types._SERVICE>
        canisterId={decideidAssetsCanisterId}
        context={decideIdAssetsActorContext}
        identity={identity}
        idlFactory={decideidAssetsIdlFactory}
        onRequest={handleRequest}
        onRequestError={(error) => errorLog(error)}
        onResponseError={handleResponseError}
      >
        <ActorProvider<ledger_types._SERVICE>
          canisterId={ledgerCanisterId}
          context={ledgerActorContext}
          identity={identity}
          idlFactory={ledgerIdlFactory}
          onRequest={handleRequest}
          onRequestError={(error) => errorLog(error)}
          onResponseError={handleResponseError}
        >
          {children}
        </ActorProvider>
      </ActorProvider>
    </ActorProvider>
  );
}

export default Actors;