import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Typography } from '@modclub-app/uikit';

type LinksList = {
  name: string;
  url: string;
}

type ColList = {
  col_name: string;
  col_list: LinksList[];
}

export const LinkColumns = () => {
  const { t } = useTranslation();
  const linksList = t('app.components.layout.footer.links_list', { returnObjects: true }) as ColList[];

  return (
    <div className="flex flex-wrap xl:flex-row gap-6 justify-between">
      {linksList.map(col => (
        <div key={col.col_name} className="flex flex-col gap-6">
          <Typography weight="medium" color="F00">{col.col_name}</Typography>
          
          <div className="flex flex-col gap-3.5">
            {col.col_list.map(link => (
              <Typography size="sm" color="F30" key={link.name}>
                <a target="_blank" className="hover:text-G00 hover:underline" href={link.url} rel="noreferrer" >{link.name}</a>
              </Typography>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}