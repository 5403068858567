import { useEffect, useState, useMemo } from 'react';
import { VerificationStatus, useProfile } from '../../../hooks/useProfile';

const cachedStatuses = new Map<string, VerificationStatus>();

export const useEmailVerification = (email: string) => {
  const [verificationStatusLoading, setVerificationStatusLoading] = useState<boolean>(false);
  const [verificationStatus, setVerificationStatus] = useState<VerificationStatus | null>(null);
  const [isVerificationButtonLoading, setIsVerificationButtonLoading] = useState<boolean>(false);
  const { getVerificationStatus, sendVerificationEmail } = useProfile();

  useEffect(() => {
    if (!email) return;

    const cachedStatus = cachedStatuses.get(email);
    if (cachedStatus) {
      setVerificationStatus(cachedStatus);
    }

    setVerificationStatusLoading(!cachedStatus);

    getVerificationStatus(email)
      .then((status) => {
        const cachedStatus = cachedStatuses.get(email);
        if (status !== cachedStatus) {
          setVerificationStatus(status);
          cachedStatuses.set(email, status);
        }
      })
      .catch((error) => {
        console.error('Error fetching verification status:', error);
      })
      .finally(() => {
        setVerificationStatusLoading(false);
      });
  }, [email]);

  const handleSendVerificationEmail = async () => {
    try {
      setIsVerificationButtonLoading(true);
      const result = await sendVerificationEmail();

      if (result === VerificationStatus.SentVerificationSuccess) {
        setVerificationStatus(VerificationStatus.InProgress);
        const updatedStatus = await getVerificationStatus(email);
        setVerificationStatus(updatedStatus);
      } else {
        setVerificationStatus(VerificationStatus.StatusError);
      }
    } catch (error) {
      console.error('Error sending verification email:', error);
      setVerificationStatus(VerificationStatus.StatusError);
    } finally {
      setIsVerificationButtonLoading(false);
    }
  };

  const notVerifiedOrExpired = useMemo(() =>
    verificationStatus === VerificationStatus.NotVerified ||
    verificationStatus === VerificationStatus.TokenExpired,
  [verificationStatus]);

  const statusBgColor = useMemo(() => {
    if (!verificationStatus) return null;

    if (notVerifiedOrExpired) return "bg-G80";
    if (verificationStatus === VerificationStatus.InProgress) return "bg-E00";
    if (verificationStatus === VerificationStatus.Verified) return "bg-B80";
    return "bg-D80";
  }, [verificationStatus, notVerifiedOrExpired]);

  const renderStatusMessage = useMemo(() => {
    switch (verificationStatus) {
      case VerificationStatus.InProgress:
        return 'in_progress';
      case VerificationStatus.Verified:
        return 'verified';
      case VerificationStatus.TokenExpired:
        return 'token_expired';
      case VerificationStatus.StatusError:
        return 'status_error';
      default:
        return null;
    }
  }, [verificationStatus]);

  return {
    verificationStatus,
    statusBgColor,
    renderStatusMessage,
    verificationStatusLoading,
    showResendVerification: notVerifiedOrExpired,
    sendVerificationEmail: handleSendVerificationEmail,
    isVerificationButtonLoading,
  };
};
