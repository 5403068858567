import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

// Components
import { MenuItem } from '../MenuItem';
import { LogOutButton } from '../LogOutButton';

// Types
import { DesktopNavItemTypes } from '../../NavDesktop/MenuItem';

type MenuListProps = {
  isOpen: boolean;
  onCloseMenu: () => void;
};

const variantsUl = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

export const MenuList: React.FC<MenuListProps> = ({ isOpen, onCloseMenu }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navBarMenu = t('app.components.layout.nav_bar.menu', { returnObjects: true }) as DesktopNavItemTypes[];
  return (
    <motion.ul
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      variants={variantsUl}
      className={cn(
        "fixed top-[48px] bottom-0 right-0 left-0 bg-H110 z-40",
        "flex flex-col w-full gap-4",
        "p-4",
        "xl:hidden",
        {
          "opacity-0 pointer-events-none": !isOpen,
          "opacity-100 pointer-events-auto": isOpen,
        }
      )}
    >
      {navBarMenu.map(item => {
        const itemUrl = item.url.replace("#", "");
        const isActive = location.pathname === itemUrl || location.pathname === `/${itemUrl}`;
        return (
          <MenuItem
            key={item.alias} 
            isActive={isActive}
            onClick={onCloseMenu}
            {...item} 
          />
        )
      })}

      <LogOutButton />
    </motion.ul>
  );
}
