import React from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from "react-qr-code";

// Components
import { Typography } from '@modclub-app/uikit';
import { CopyableText } from '../../../uikit';

// Utils
import { truncateMiddle } from '../../../../utils/util';

// Types
import { DecideIDSectionPropTypes } from '../types';

export const DecideIDSection: React.FC<DecideIDSectionPropTypes> = ({ 
  walletAddress 
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-3 w-full md:w-1/2">
      <div className="h-auto w-full max-w-[240px] mx-auto my-0">
        <QRCode
          className="h-auto max-w-full w-full"
          value={walletAddress}
          viewBox={`0 0 240 240`}
        />
      </div>


      <div className="flex flex-col items-center justify-center">
        <Typography size="lg" color="F00">
          {t('app.components.smartkit.payment_modal.decide_id_section.title')}
        </Typography>
        <CopyableText 
          buttonText={truncateMiddle(walletAddress, 45)}
          textToCopy={walletAddress} 
          copyButton
        />
      </div>
    </div>
  );
}