// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rTzB9vHoxd20zBL_TFfi a {
  color: var(--color-G00);
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./app/components/uikit/TermsPolicy/styles.module.scss"],"names":[],"mappings":"AACE;EACE,uBAAA;EACA,0BAAA;AAAJ","sourcesContent":[".term-policy {\n  & a {\n    color: var(--color-G00);\n    text-decoration: underline;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"term-policy": `rTzB9vHoxd20zBL_TFfi`
};
export default ___CSS_LOADER_EXPORT___;
