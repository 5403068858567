import React from "react";
import { motion, AnimatePresence } from "framer-motion";

// Components
import { Typography } from "@decide-ai/uikit";

// Types
import { AnimationBodyPropTypes } from '../../types';

export const AnimationBody: React.FC<AnimationBodyPropTypes> = ({ 
  description,
  title,
  isOpen,
  list,
}) => (
  <AnimatePresence mode="wait">
    <motion.div
      id={title}
      initial={{
        height: 0,
        opacity: 0,
      }}
      animate={isOpen ? {
        height: "auto",
        opacity: 1,
      }: ({
        height: 0,
        opacity: 0,
      })}
      className="pointer-events-none"
    >
      <Typography tagName="div" color='dark-01' size="base">
        <div className="pt-5 leading-snug w-full md:max-w-3xl">
          {description}
        </div>
      </Typography>

      {list && (
        <ul className='flex flex-col pt-2 gap-2'>
          {list.map((listItem, listIndex) => (
            <li key={listIndex} className='flex'>
              <Typography
                color='dark-01'
                size='lg'
              >
                <span className="leading-snug w-full md:max-w-3xl">
                  {`${listIndex + 1}.`} {listItem}
                </span>
              </Typography> 
            </li>
          ))}               
        </ul>
      )}
    </motion.div>
  </AnimatePresence>
);