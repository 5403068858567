import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// Types
import { MotionFadeProps } from '../types';

export const MotionFade: React.FC<MotionFadeProps> = ({
  delay = 0,
  duration = 0.9,
  exitDuration = 0.5,
  isVisible,
  children,
}) => {
  const variants = {
    hidden: { opacity: 0, scale: 0.8, filter: 'blur(10px)' },
    visible: { opacity: 1, scale: 1, filter: 'blur(0px)' },
    exit: { opacity: 0, scale: 0.8, filter: 'blur(10px)' },
  }

  const transition = {
    duration: duration,
    delay: delay,
    when: "beforeChildren",
    staggerChildren: 0.3,
    ease: "easeInOut",
    opacity: {
      duration: isVisible ? duration : exitDuration,
    }
  }

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          variants={variants}
          initial="hidden"
          animate="visible"
          exit="exit"
          transition={transition}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default MotionFade;
