import React, { useState } from 'react';

// Components
import { MenuToggle } from './MenuToggle';
import { MenuList } from './MenuList';
import { Logo } from '../../../uikit'

export const NavMobile = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleToggleMenu = () => setIsOpen(prev => !prev);
  return (
    <div className="fixed top-0 right-0 left-0 z-30 bg-H110">
      <div className="flex p-2 items-center justify-between">
        <Logo theme="black" />
        <MenuToggle isOpen={isOpen} onToggle={handleToggleMenu} />
      </div>
      <MenuList isOpen={isOpen} onCloseMenu={handleToggleMenu} />
    </div>
  );
};