import { create } from 'zustand'
import { devtools } from 'zustand/middleware';

type State = {
  isCollapsed: boolean;
}

type Action = {
  setIsCollapsedNavBar: () => void;
}

export const useNavStore = create<State & Action>()(
  devtools((set) => ({
    isCollapsed: false,
    setIsCollapsedNavBar: () =>
      set((state) => ({
        ...state,
        isCollapsed: !state.isCollapsed,
      })),
  }))
);