import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from './useAuth';

interface DeveloperProfile {
  id: string;
  email: string;
  company?: string;
  createdAt: string;
  updatedAt: string;
}

interface UpdateDeveloperProfileData {
  developer_id: string;
  name: string;
}

export function useDeveloperProfile() {
  const { getAuthHeader } = useAuth();
  const queryClient = useQueryClient();
  const API_URL = process.env.AWS_RECOGNITION_API_URL || '';
  
  if (!API_URL) {
    console.error('AWS_RECOGNITION_API_URL is not defined');
  }

  const fetchDeveloperProfile = async (): Promise<DeveloperProfile> => {
    const headers = await getAuthHeader();
    if (!headers) throw new Error('Not authenticated');

    const response = await fetch(`${API_URL}/developer`, {
      headers
    });

    if (!response.ok) {
      throw new Error('Failed to fetch developer profile');
    }

    return response.json();
  };

  const { data: profile, isLoading, error } = useQuery({
    queryKey: ['developerProfile'],
    queryFn: fetchDeveloperProfile,
    retry: false,
  });

  const updateDeveloperProfile = useMutation({
    mutationFn: async (data: UpdateDeveloperProfileData) => {
      const headers = await getAuthHeader();
      if (!headers) throw new Error('Not authenticated');

      const response = await fetch(`${API_URL}/developer`, {
        method: 'PUT',
        headers: {
          ...headers,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error('Failed to update developer profile');
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['developerProfile'] });
    },
  });

  return {
    profile,
    isLoading,
    error,
    updateDeveloperProfile: updateDeveloperProfile.mutateAsync,
  };
}
