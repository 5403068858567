import React from 'react';

// Components
import { Typography } from '@modclub-app/uikit';

const Loader = () => {
  return (
    <div className="flex items-center justify-start">
      <div className="relative flex items-center justify-start">
        {[...Array(16)].map((_, i) => (
          <div
            key={i}
            className="bg-F00 h-6 origin-bottom transform animate-moveStretch"
            style={{
              width: `${16 / i}px`,
              animationDelay: `${i * 0.1}s`,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export const ProgressBar: React.FC<{ 
  text?: string 
}> = ({ text }) => (
  <div className="w-full flex flex-col gap-5 items-center justify-center">
    {text && (
      <Typography size="sm" color="F00">
        {text}
      </Typography>
    )}
    <Loader />
  </div>
)