import React from 'react';
import { useNavigate } from 'react-router-dom';

// Hooks
import { useProfile } from '../../../hooks/useProfile';
import { useWallet } from "../../../hooks/useWallet";

// Data
import { DATA_COMMON } from '../../../data/dataCommon';

export const useOnboarding = ( setShowPaymentModal: React.Dispatch<React.SetStateAction<boolean>> ) => {
  const navigate = useNavigate();
  const { account } = useProfile();
  const { tokenRequiredAction: { canStartPoh } = {}, isLoading } = useWallet();

  const { 
    title, 
    text, 
    pohContinueText, 
    reviewingText, 
    iconName,
  } = DATA_COMMON.onboarding;

  const handlerOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!canStartPoh) {
      setShowPaymentModal(true);
    } else {
      navigate("/poh");
    }
  };

  const stepName = account?.currentOnboardingStep 
    ? Object.keys(account?.currentOnboardingStep)[0] : '';

  const alertText = stepName === 'poh_uniqueness' ? reviewingText : text;


  return {
    stepName,
    title,
    alertText,
    pohContinueText,
    iconName,
    handlerOnClick,
    isWalletLoading:isLoading,
  };
};
