import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { RadialGradients, LabelText } from '../../uikit';
import { ImageDecor } from './ImageDecor';
import { DecideIDCardLayout } from './DecideIDCardLayout';
import { Reverification } from './Reverification';
import { VerifiedStatus } from './VerifiedStatus';
// Utils
import { truncateMiddle } from '../../../utils/util';

export const DecideIDCard: React.FC<{ 
  decideId?: string;
  status?: string;
  reputationLevel: string | null;
}> = ({ status, decideId, reputationLevel }) => {
  const { t } = useTranslation();
  return (
    <DecideIDCardLayout>
      <DecideIDCardLayout.RadialGradient>
        <RadialGradients />
      </DecideIDCardLayout.RadialGradient>
      <ImageDecor />
      <DecideIDCardLayout.ContentWrap>
        {status && (
          <VerifiedStatus status={status} reputationLevel={reputationLevel} />
        )}
        <DecideIDCardLayout.LablesList>
          <LabelText 
            position="end" 
            label={t('app.components.smartkit.decide_id_card.verification.label')} 
            text={t('app.components.smartkit.decide_id_card.verification.text')} 
          />
          {decideId && (
            <LabelText
              position="end"
              label={t('app.components.smartkit.decide_id_card.token.label')} 
              textToCopy={decideId} 
              text={truncateMiddle(decideId, 35)} 
            />
          )}
          <Reverification />
        </DecideIDCardLayout.LablesList>
      </DecideIDCardLayout.ContentWrap>
    </DecideIDCardLayout>
  );
}