import React, { useState } from "react";
import { Amplify } from 'aws-amplify';
import { useTheme } from '@aws-amplify/ui-react';

// Components
import FaceLiveness from './FaceLiveness';
import DetectionResult from './DetectionResult';
import { Navigation } from '../../components/layout';

import awsexports from './aws-exports';
import { FaceLivenessResult } from "./types";
import { useIdentity } from "../../hooks/useIdentity";
import { useNavigate } from "react-router-dom";

// Constants
import { ANALYSIS_STATUS_PASS } from '../../utils/constant';
import { useWallet } from "../../hooks/useWallet";

Amplify.configure(awsexports);

const PoH: React.FC = () => {
  const { principal } = useIdentity();
  const { tokens } = useTheme();
  const navigate = useNavigate();
  const { tokenRequiredAction: { canStartPoh, canStartReverification } = {}, isLoading } = useWallet();

  console.log(tokens);

  const [faceLivenessAnalysis, setFaceLivenessAnalysis] = useState<FaceLivenessResult | null>(null);

  const getfaceLivenessAnalysis = (analysis: FaceLivenessResult | null) => {
    if (analysis !== null) {
      if (analysis.Status == ANALYSIS_STATUS_PASS) {
        navigate("/app")
      }
      setFaceLivenessAnalysis(analysis);
    }
  }

  const tryagain = () => {
    setFaceLivenessAnalysis(null);
  }

  if (isLoading) {
    // TODO: MOD-1330 better Loading indicator
    return <>Loading ...</>;
  }

  if (!canStartPoh && !canStartReverification) {
    // TODO: MOD-1330 better UI design
    return null;
  }

  return <React.Fragment>
      <Navigation theme="black" />
      <div className="flex flex-row justify-center items-center content-start flex-nowrap gap-4">
        <div className="max-h-[600px] h-[600px] w-[740px] max-w-[740px] p-4 md:p-0">
          {faceLivenessAnalysis && faceLivenessAnalysis.Status ? (
            <DetectionResult faceLivenessAnalysis={faceLivenessAnalysis} tryagain={tryagain} />
          ) : (
            principal && <FaceLiveness 
              faceLivenessAnalysisCb={getfaceLivenessAnalysis}
              user_id={principal}
            />
          )}
        </div>
      </div>
    </React.Fragment>
}

export default PoH;
