import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Button } from '@modclub-app/uikit';
import { TextSection } from '../../uikit';
import { Form } from '../Form';
import { TotalAmount } from './TotalAmount';
import { SuccessStep } from './SuccessStep';
import { ErrorStep } from './ErrorStep';

// Hooks
import { useWallet, HandleTransferTokenErrorFunction } from '../../../hooks/useWallet';
import { useWithdrawValidation } from './customHooks/useWithdrawValidation';

// Types
import { InputField } from './types';

export const WithdrawModal: React.FC = () => {
  const [withdrawModalStepName, setWithdrawModalStepName] = useState<string>('withdraw');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { t } = useTranslation();

  const transferPohFeeError : HandleTransferTokenErrorFunction = (
    error: Error, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _variables: "DCD" | "ICP", 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _context: unknown
  ) => { 
    console.error('TRANSFER POH FEE:: ', error);
    
    setErrorMessage(error.message);
    setWithdrawModalStepName('error');
    handleAmountChange('');
    handleWalletChange('');
  };

  const { 
    balance, 
    icrc1FeeDCD, 
    withdrawAmount, 
    withdrawAmountLoadingStatus 
  } = useWallet(transferPohFeeError);

  const {
    wallet,
    amount,
    error,
    isButtonDisabled,
    totalAmountWithFee,
    handleWalletChange,
    handleAmountChange,
  } = useWithdrawValidation(
    { DCD: balance?.DCD ?? 0, ICP: balance?.ICP ?? 0 },
    icrc1FeeDCD,
  );

  useEffect(() => {
    if (withdrawAmountLoadingStatus === 'success' 
      || withdrawAmountLoadingStatus === 'error') {
      setWithdrawModalStepName(withdrawAmountLoadingStatus);
      handleAmountChange('');
      handleWalletChange('');
    }
  }, [withdrawAmountLoadingStatus]);

  const handleChange = (name: string, value: string) => {
    if (name === 'wallet') handleWalletChange(value);
    if (name === 'amount') handleAmountChange(value);
  };

  const handleSubmit = async () => await withdrawAmount({
    walletAddress: wallet, 
    transferAmount: Number(amount),
  });

  const handleBackToWithdrawOnClick = () => setWithdrawModalStepName('withdraw');

  const formFields: InputField[] = [
    { 
      name: 'wallet', 
      label: t('app.components.smartkit.withdraw_modal.form_fields.principal.label'), 
      placeholder: t('app.components.smartkit.withdraw_modal.form_fields.principal.placeholder'), 
      value: wallet 
    },
    { 
      name: 'amount', 
      label: t('app.components.smartkit.withdraw_modal.form_fields.amount.label'),
      placeholder: t('app.components.smartkit.withdraw_modal.form_fields.amount.placeholder'),
      value: amount 
    },
  ];

  return (
    <div className="w-full">
      <div className="flex flex-col gap-10 w-full">
        {withdrawModalStepName === 'success' && (
          <SuccessStep onClick={handleBackToWithdrawOnClick}/>
        )}

        {withdrawModalStepName === 'error' && (
          <ErrorStep 
            onClick={handleBackToWithdrawOnClick}
            title={t('app.components.smartkit.withdraw_modal.error_modal.title')}
            subTitle={errorMessage 
              ? errorMessage 
              : t('app.components.smartkit.withdraw_modal.error_modal.sub_title')
            }
          />
        )}

        {withdrawModalStepName === 'withdraw' && (
          <React.Fragment>
            <TextSection 
              title={t('app.components.smartkit.withdraw_modal.title')}
              text={t('app.components.smartkit.withdraw_modal.sub_title')}
              textCenter
            />
            <div className="flex flex-col gap-4">
              <Form
                fields={formFields}
                onSubmit={handleSubmit}
                onChange={(name, value) => handleChange(name, value)}
              >
                <div className="flex flex-col gap-6 lg:gap-10">
                  <TotalAmount 
                    totalAmountWithFee={totalAmountWithFee}
                    icrc1FeeDCD={icrc1FeeDCD}
                    error={error} 
                  />
                  <Button
                    buttonText="Submit withdraw"
                    onClick={handleSubmit}
                    rounded="lg"
                    disabled={isButtonDisabled}
                    iconName="ArrowRightIcon"
                    size="large"
                    skin="standard"
                    loading={withdrawAmountLoadingStatus === 'pending'}
                    fullWidth
                    theme="black"
                  />
                </div>
              </Form>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
