import React from "react";

// Components
import { ProgressBar } from '../../../components/uikit';

export const ProgressBarInterface = () => (
  <div className="h-full flex justify-center items-center">
    <div className="max-w-[240px] w-full">
      <ProgressBar text="Loading verification process ..." />
    </div>
  </div>
)