import React from 'react';

// Components
import { GridV2 } from '../../../../components/layout';

type PageContentTypes<
  Props = Record<string, never | React.ReactNode>
> = React.FC<Props & { children: React.ReactNode }>;

export const PageContentLayout: PageContentTypes & {
  SectionIDCard: PageContentTypes,
  SectionDeposit: PageContentTypes,
  SectionOnBoarding: PageContentTypes,
} = ({ 
  children 
}) => (
  <GridV2 columns={6}>
    {children}
  </GridV2>
);

PageContentLayout.SectionIDCard = ({ children }) => (
  <div className="col-span-full lg:col-span-4">
    {children}
  </div>
);

PageContentLayout.SectionDeposit = ({ children }) => (
  <div className="col-span-full lg:col-span-2">
    {children}
  </div>
);

PageContentLayout.SectionOnBoarding = ({ children }) => (
  <div className="col-span-full">
    {children}
  </div>
);