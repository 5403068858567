import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Typography } from '@modclub-app/uikit';
import { ModalLayout } from '../../ModalLayout';

export const ReVerificationFee: React.FC<{ feeSum: number; }> = ({ feeSum }) => {
  const { t } = useTranslation();
  return (
    <ModalLayout.Fee>
      <Typography size="sm" color="F60" whitespaceNowrap weight="normal">
        {t('app.components.smartkit.reverification_payment_modal.warning_step.fee_text')}
      </Typography>
      <ModalLayout.Sum>
        <Typography size="7xl" color="F00" weight="bold">{feeSum}</Typography>
        <Typography size="2xl" color="F00" weight="normal">
          {t('app.components.smartkit.reverification_payment_modal.warning_step.unit')}
        </Typography>
      </ModalLayout.Sum>
    </ModalLayout.Fee>
  );
}