import React, { useState } from "react";

// Components
import { TextSection } from '../../../components/uikit';
import { Button } from '@modclub-app/uikit';
import { Agreement } from '../Agreement';

// Utils
import { useProfile } from "../../../utils";

// Data
import { DATA_NEW_PROFILE } from '../../../data/dataNewProfile';

export const Form = () => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const { createProfile } = useProfile();
  
  const { title, text, buttonSubmin } = DATA_NEW_PROFILE;

  // Set the checkbox to checked and store the value in the state 
  // for the button, the button is enabled if true, disabled if false
  const handleCheck = (value: boolean) => setIsChecked(value);

  // The function creates a profile and saves 
  // the isSubmitted variable to display the preloader.
  const handleCreateProfile = async () => {
    if (isChecked && !isSubmitted) {
      setIsSubmitted(true);
      createProfile({ firstName: "", lastName: "", email: "" });
    }
  };

  const buttonText = isSubmitted 
    ? 'Loading...' : buttonSubmin.buttonText;

  return (
    <div className='flex flex-col gap-10 max-w-md'>
      <TextSection 
        title={title}
        text={text}
        textCenter
      />

      <Agreement handleCheck={handleCheck} />
      
      <Button
        buttonText={buttonText}
        iconName={buttonSubmin.iconName}
        onClick={handleCreateProfile}
        disabled={!isChecked}
        size="large"
        skin="standard"
        theme="black"
        loading={isSubmitted}
        fullWidth
        spaceBetween
      />
    </div>
  );
}
