import React from 'react';

type LayoutTypes<
  Props = Record<string, never | React.ReactNode>
> = React.FC<Props & { children: React.ReactNode }>;

export const ProfileLayout: LayoutTypes & {
  ContentForm: LayoutTypes;
  FormButton: LayoutTypes;
} = ({ 
  children 
}) => (
  <div className="overflow-hidden w-full h-full relative p-6">
    {children}
  </div>
);

ProfileLayout.ContentForm = ({ children }) => (
  <div className="max-w-lg relative z-10">{children}</div>
);

ProfileLayout.FormButton = ({ children }) => (
  <div className="pt-4 flex flex-col xl:flex-row gap-2">
    {children}
  </div>
);