import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { BlockPlate } from '../../layout';
import { LabelText } from '../../uikit';
import { Typography, Button } from '@modclub-app/uikit';
import { DepositCardLayout } from './DepositCardLayout';

// Utils
import { roundNumber } from '../../../utils/util';

export const DepositCard: React.FC<{
  amount?: number;
  unit: string;
  onClick?: () => void;
  onClickWithdraw?: () => void;
}> = ({ amount, unit, onClick, onClickWithdraw }) => {
  const { t } = useTranslation();
  return (
    <BlockPlate>
      <DepositCardLayout.ContentWrap>
        <LabelText 
          position="start" 
          color="bg-H100" 
          text={t('app.components.smartkit.deposit_card.title')}
        />
        
        <DepositCardLayout.Amount>
          <Typography color="F00">
            <span className="text-[6.25vw] md:text-[4.25vw] lg:text-[3.33vw]">
              {amount ? roundNumber(amount, 3) : 0}
            </span>
          </Typography>
          <Typography color="F00" size="2xl">{unit}</Typography>
        </DepositCardLayout.Amount>

        <DepositCardLayout.ButtonsGroup>
          <Button
            buttonText="Deposit"
            iconName="CardSendIcon"
            onClick={onClick}
            rounded="full"
            size="medium"
            skin="standard"
            theme="black"
            disabled={!onClick}
          />
          <Button
            buttonText="Withdraw"
            iconName="CardReciveIcon"
            onClick={onClickWithdraw}
            rounded="full"
            size="medium"
            skin="standard"
            theme="black"
            disabled={amount === 0 && !onClickWithdraw}
          />
        </DepositCardLayout.ButtonsGroup>
      </DepositCardLayout.ContentWrap>
    </BlockPlate>
  );
}