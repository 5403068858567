import React from 'react';

// Components
import { LabelText } from '../../../uikit';

export const VerifiedStatus: React.FC<{ status: string; }> = ({ status }) => {
  let color: string | null = null;
  if (status === 'Processing' || status === 'Onboarding') {
    color = 'bg-E50';
  } else if (status === 'Rejected' || status.startsWith('Failed')) {
    color = 'bg-D60';
  } else if (status === 'Verified') {
    color = 'bg-B50';
  }
  
  return (
    <LabelText position="end" text={status} color={color} />
  ); 
}