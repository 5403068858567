import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Typography, IconText } from '@modclub-app/uikit';

export const InfoItem: React.FC<{ 
  label: string; 
  text: string;
}> = ({ label, text }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-1.5">
      <Typography size="xs" color="F00">{label}</Typography>
      <Typography 
        size="xl"
        color="F00"
      >
        {text || (
          <div className="flex justify-start pt-1">
            <IconText
              iconName="DangerCircleIcon"
              iconStroke="D20"
              text={`${label.replace(":", "")} ${t('app.pages.profile.view_mode.info_item_not_specified_text')}`}
              textColor="D20"
              textSize="sm"
            />
          </div>
        )}
      </Typography>
    </div>
  );
}