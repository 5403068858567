import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { ModalLayout } from '../ModalLayout';
import { SimpleModal } from '../../../uikit';
import { Button } from '@modclub-app/uikit';

type ConfirmReverificationModalTypes = {
  onClick?: () => void;
  isLoading?: boolean;
};

export const ConfirmReverificationModal: React.FC<ConfirmReverificationModalTypes> = ({ 
  onClick,
  isLoading,
}) => {
  const { t } = useTranslation();
  return (
    <SimpleModal 
      title={t('app.components.smartkit.confirm_reverification_modal.title')}
      subTitle={t('app.components.smartkit.confirm_reverification_modal.sub_title')}
      iconName="DangerCircleIcon"
      iconColor="F00"
    >
      <ModalLayout.Button>
        <Button
          buttonText={t('app.components.smartkit.confirm_reverification_modal.button_text')}
          onClick={onClick}
          rounded="lg"
          size="large"
          skin="standard"
          fullWidth
          theme="black"
          loading={isLoading}
        />
      </ModalLayout.Button>
    </SimpleModal>
  );
}