export const DATA_SOCIAL = [{
  name: 'X',
  url: 'https://x.com/DecideAI_',
  alias: 'x_white'
}, {
  name: 'Discord',
  url: 'https://discord.gg/decideai',
  alias: 'discord_white'
}, {
  name: 'Telegram',
  url: 'https://t.me/Decide_AI',
  alias: 'telegram_white'
}]