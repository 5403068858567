import React from 'react';
import { Picture } from '@modclub-app/uikit';

export const Logo: React.FC<{ 
  theme?: 'black' | 'white'; 
  size?: 'full' | 'less';
  className?: string;
}> = ({ theme = 'black', size = 'full', className }) => (
  <Picture
    className={className}
    src={`/assets/decideid_logo_${size}_${theme}.svg`}
    alt="decide_id_logo" 
  />
);