import React from "react";

// Components
import { Typography, DangerTriangleIcon, getColorByName } from "@modclub-app/uikit";

// Types
import { MessagePlatePropTypes } from './types';

export const MessagePlate: React.FC<MessagePlatePropTypes> = ({ 
  title,
  alertText,
  children,
}) => (
  <div className="bg-E00 flex flex-col md:flex-row md:items-center md:justify-center p-4 gap-4 md:gap-6">
    <div className="flex items-center gap-2">
      <DangerTriangleIcon 
        stroke={getColorByName('F00')?.hex} 
      />
      <div className="flex flex-col">
        <Typography color="F00" size="base" weight="bold">{title}</Typography>
        <Typography color="F00" size="xs">{alertText}</Typography>
      </div>
    </div>
    {children}
  </div>
);