import { decideid_types } from "../canister_types";

export const getAccountStatus = (account: decideid_types.Account): string | undefined => {
  if (!account) return;

  const state = Object.keys(account.state)[0];
  const onboardingStep = Object.keys(account.currentOnboardingStep)[0];
  const rejectReasonDict = account?.rejectReason?.[0];
  const rejectReason = rejectReasonDict ? Object.keys(rejectReasonDict)[0] : undefined;

  switch (state) {
    case "onboarding":
      switch (onboardingStep) {
        case "poh_uniqueness":
          return "Processing";
        case "poh_liveness":
          return "Onboarding";
        default:
          return undefined;
      }
    case "approved":
      return "Verified";
    case "rejected":
      return rejectReason === "failed_reverification_try_again" ? "Try Again" : "Rejected";
    default:
      return undefined;
  }
};
