import logdna from '@logdna/browser';

const ENV_MAPPINGS: { [key: string]: string } = {
  production: 'prod',
  prod: 'prod',
  dev: 'dev',
  qa: 'staging',
  local: 'local',
};

const DEV_ENV = process.env.DEV_ENV || '';
const env = ENV_MAPPINGS[DEV_ENV];

logdna.init(process.env.LOGDNA_INGESTION_KEY, {
  console: false,
  disabled: env === 'local',
  hooks: {
    beforeSend: [
      ({ message, level, lineContext }) => {
        if (typeof message === 'string') {
          message = `[browser ${env}] ${message}`;
        }
        return { message, level, lineContext };
      },
    ],
  },
  globalErrorHandlers: false
});

export { logdna };
