import React from "react";
import { Link } from "react-router-dom";
import cn from 'classnames';

// Hooks
import { useLogout } from "../../../hooks/useLogout";

// Components
import { Button } from '@modclub-app/uikit';
import { Logo, AboutDecideId } from '../../uikit';

// Types
import { ThemeType } from './types';

export const Navigation: React.FC<ThemeType> = ({ 
  theme = 'white'
}) => {
  const logout = useLogout();
  return (
    <div className={cn(
      'flex items-center justify-between',
      'w-full p-4 lg:p-5',
      'backdrop-blur-md bg-C100/0.3'
      )}>
      <div className='w-[135px] h-[32px]'>
        <Link to="/app">
          <Logo theme={theme} />
        </Link>
      </div>

      <div className="flex items-center gap-4">
        <AboutDecideId color={theme} />
        
        <Button
          buttonText="Logout"
          iconName="LogoutIcon"
          onClick={logout}
          rounded="lg"
          size="small"
          skin="inline"
          spaceBetween
          theme="blue"
        />
      </div>
    </div>
  );
}