import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { DepositCard } from '../../../../components/smartKit';
import { LoaderContainer, AnimatePulse } from '../../../../components/uikit';
import { BlockPlate } from '../../../../components/layout';

// Hooks
import { useWallet } from '../../../../hooks/useWallet';

// Store
import { useDepositStore } from '../../../../store/useDepositStore';

export const Deposit = () => {
  const { balance } = useWallet();
  const { t } = useTranslation();
  const isLoading = balance?.DCD === undefined;
  const handleDepositModal = useDepositStore((state) => state.setIsOpenDepositModal);
  const handleWithdrawModal = useDepositStore((state) => state.setIsOpenWithdrawModal);

  return (
    <LoaderContainer
      isLoading={isLoading}
      transitionDuration={1}
      LoadingComponent={() => (
        <BlockPlate className="h-[478px] lg:h-[438px]">
          <AnimatePulse height="100%" bg="from-[#f0f4ff] to-[#f8f9fe]" />
        </BlockPlate>
      )}
      ErrorComponent={() => (
        <div className="text-red-600 text-center">
          {t('app.pages.main.deposit_loading_error')}
        </div>
      )}
    >
      <DepositCard 
        onClickWithdraw={handleWithdrawModal}
        onClick={handleDepositModal}
        amount={balance?.DCD} 
        unit="DCD" 
      />
    </LoaderContainer>
  );
}