import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';

// Components
import { Typography } from '@modclub-app/uikit';

// Types
import { RefreshIndicatorPropTypes } from './types';

const UpdateSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#000000" viewBox="0 0 256 256"><path d="M222,48V96a6,6,0,0,1-6,6H168a6,6,0,0,1,0-12h33.52L183.47,72a81.51,81.51,0,0,0-57.53-24h-.46A81.5,81.5,0,0,0,68.19,71.28a6,6,0,1,1-8.38-8.58,93.38,93.38,0,0,1,65.67-26.76H126a93.45,93.45,0,0,1,66,27.53l18,18V48a6,6,0,0,1,12,0ZM187.81,184.72a81.5,81.5,0,0,1-57.29,23.34h-.46a81.51,81.51,0,0,1-57.53-24L54.48,166H88a6,6,0,0,0,0-12H40a6,6,0,0,0-6,6v48a6,6,0,0,0,12,0V174.48l18,18.05a93.45,93.45,0,0,0,66,27.53h.52a93.38,93.38,0,0,0,65.67-26.76,6,6,0,1,0-8.38-8.58Z"></path></svg>
);

export const RefreshIndicator: React.FC<RefreshIndicatorPropTypes> = ({ 
  value, 
  onRefresh, 
  isSpinning
}) => {
  const controls = useAnimation();

  useEffect(() => {
    if (isSpinning) {
      controls.start({
        rotate: [0, 360],
        transition: {
          duration: 1,
          ease: 'linear',
          repeat: Infinity
        }
      });
    } else {
      controls.stop();
    }
  }, [isSpinning, controls]);

  const handleClick = async () =>  {
    if (onRefresh) {
      onRefresh();
    }
  };

  return (
    <div className="flex items-center space-x-2">
      <motion.div
        className="cursor-pointer"
        onClick={handleClick}
        animate={controls}
      >
        <UpdateSvg />
      </motion.div>
      <Typography 
        size="sm"
        color='F00'
      >{value}</Typography>
    </div>
  );
};
