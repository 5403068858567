import React from "react";

import { PageLoader } from '../../components/uikit';
import { useLogout } from "../../hooks/useLogout";

const Logout = () => {
  const logout = useLogout();
  // Call the logout function as soon as the component mounts
  React.useEffect(() => {
    logout();
  }, []);
  
  return (
    <PageLoader label="Loading ..." />
  );
};

export default Logout;
