import React from 'react';

// Components
import { Button, ButtonIcon } from '@modclub-app/uikit';

// Hooks / Stores
import { useLogout } from "../../../hooks/useLogout";
import { useNavStore } from '../../../store/useNavStore';

export const TopBar = () => {
  const logout = useLogout();
  const handlerCollapsedNavBar = useNavStore((state) => state.setIsCollapsedNavBar);

  return (
    <div className="hidden xl:flex w-full items-center justify-end xl:justify-between py-4 bg-H110">
      <ButtonIcon
        iconName="RoundSortHorizontalIcon"
        onClick={handlerCollapsedNavBar}
        rounded="full"
        size={24}
        skin="grey"
        theme="black"
      />
      <Button
        buttonText="Log out"
        iconName="LogoutV3Icon"
        onClick={logout}
        rounded="full"
        size="small"
        skin="standard"
        theme="blue"
      />
    </div>
  );
}