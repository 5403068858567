export function isEmailValid(email: string): boolean {
  // Rules adapted from the following resources:
  // - https://cheatsheetseries.owasp.org/cheatsheets/Input_Validation_Cheat_Sheet.html#email-address-validation
  // - https://thecopenhagenbook.com/email-verification
  //
  // Note:
  // - We do not support internationalized domain names (IDNs)
  //   https://en.wikipedia.org/wiki/Internationalized_domain_name
  // - We do not support emoji domain names
  //   https://en.wikipedia.org/wiki/Emoji_domain

  // Remove leading and trailing whitespace
  email = email.trim();

  // No more than 255 characters and not empty
  if (email.length > 255 || email.length === 0) {
    return false;
  }

  // Must contain an at sign
  const atSignIndex = email.indexOf('@');
  if (atSignIndex === -1) {
    return false;
  }

  // Must contain a domain
  const domain = email.slice(atSignIndex + 1);
  if (domain.length === 0) {
    return false;
  }

  // Must contain a dot
  const dotIndex = domain.indexOf('.');
  if (dotIndex === -1) {
    return false;
  }

  // Must not contain double dots
  const doubleDotIndex = domain.indexOf('..', dotIndex);
  if (doubleDotIndex !== -1) {
    return false;
  }

  // Each label (not dot part) must
  // 1. be alphanumeric
  // 2. be a hyphen (but the label cannot start or end with a hyphen)
  // 3. be at most 63 characters
  // 4. be at least 1 character
  const labels = domain.split('.');
  for (const label of labels) {
    const labelLength = label.length;
    // Be at most 63 characters and not empty
    if (labelLength > 63 || labelLength === 0) {
      return false;
    }
    // Cannot start or end with a hyphen
    if (label[0] === '-' || label[labelLength - 1] === '-') {
      return false;
    }
    // Be alphanumeric or a hyphen
    // Note: We would change the following for block to support
    // internationalized domain names (IDNs), and emoji domain names
    for (const char of label) {
      if (!/^[a-zA-Z0-9-]$/.test(char)) {
        return false;
      }
    }
  }

  // Must contain an address/local part
  const address = email.slice(0, atSignIndex);
  if (address.length === 0) {
    return false;
  }
  return true;
}
