import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useInternetIdentity } from "../../contexts/InternetIdentityContext";

// Components
import { AuthProfileLayout } from '../../components/layout';
import { TextSection, TermsPolicy } from '../../components/uikit';
import { Providers } from './Providers';


const Authorization = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { identity } = useInternetIdentity();
  
  useEffect(() => { navigate('/app'); }, [identity]);

  const isVCrequest = window && window.location.hash.includes("#/vc/");
  const text = isVCrequest 
    ? t('app.pages.app.is_vc_request_text') 
    : t('app.pages.app.regular_text');

  return (
    <AuthProfileLayout>
      <div className='flex flex-col gap-10 max-w-md'>
        <TextSection
          title={t('app.pages.app.title')}
          text={text}
          textCenter
        />
        <div className='flex flex-col gap-2.5'>
          {/** icOnly need to add as a prop in Providers to display Ethereum button */}
          <Providers />
        </div>

        <TermsPolicy text={t('app.pages.app.policy_and_term')} />
      </div>
    </AuthProfileLayout>
  );
}

export default Authorization;
