import React from "react";
import { motion, useScroll, useTransform } from "framer-motion";

// Components
import { PictureDecor } from "./PictureDecor";
import { CustomPageStyles, Logo, AboutDecideId } from "../../components/uikit";
import { Title } from "./Title";
import { SubTitle } from "./SubTitle";
import { MainButton } from "./MainButton";
import { UserCounter } from "./UserCounter";
import { DeveloperLoginButton } from "../../components/uikit/DeveloperLoginButton";
  
const Landing = () => {
  const { scrollY } = useScroll();

  const backgroundY = useTransform(scrollY, [0, 500], [0, -100]);
  const contentY = useTransform(scrollY, [0, 500], [0, -200]);

  return (
    <React.Fragment>
      <div className="relative flex items-center justify-center w-full min-h-screen overflow-hidden p-5">
        <CustomPageStyles mainBgColor="#000" mainColor="#fff" />

        <motion.div
          style={{ y: backgroundY }}
          className="absolute top-0 left-0 right-0 bottom-0 w-full h-full"
        >
          <PictureDecor />
        </motion.div>

        <div className="flex items-center justify-between fixed top-5 left-5 right-5">
          <div className="w-[135px] h-[32px]">
            <Logo theme="white" />
          </div>
          <div className="flex items-center gap-2">
            <DeveloperLoginButton />
            <AboutDecideId color="white" />
          </div>
        </div>

        <motion.div
          className="flex flex-col items-center justify-center"
          style={{ y: contentY }}
        >
          <div className="py-8 lg:py-6 text-center">
            <Title />
            <SubTitle />
          </div>

          <MainButton />

          <UserCounter />
        </motion.div>
      </div>
    </React.Fragment>
  );
};

export default Landing;
