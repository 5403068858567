export const gradients = [{
  position: 'top-[30px] left-1/2 -translate-x-1/2',
  duration: 18,
  gradientStyle: 'radial-gradient(circle 28rem, #E871EB, transparent 100%)'
}, {
  position: 'left-[30px] bottom-0',
  duration: 18,
  gradientStyle: 'radial-gradient(circle 28rem, #355BE0, transparent 100%)'
}, {
  position: 'right-[30px] bottom-0',
  duration: 18,
  gradientStyle: 'radial-gradient(circle 28rem, #59DDAD, transparent 100%)'
}, {
  position: 'right-[30px] top-0',
  duration: 18,
  gradientStyle: 'radial-gradient(circle 25rem, #FDC39F, transparent 100%)'
}, {
  position: 'right-[30px] left-0',
  duration: 18,
  gradientStyle: 'radial-gradient(circle 25rem, #924BD2, transparent 100%)'
}, {
  position: 'right-[30px] bottom-0',
  duration: 18,
  gradientStyle: 'radial-gradient(circle 25rem, #B8FFE5, transparent 100%)'
}];