import React from 'react';

// Components
import { Button } from '@modclub-app/uikit';
import { RestartAmount } from '../RestartAmount';
import { PaymentOptions } from '../PaymentOptions';
import { useTranslation } from 'react-i18next';

// Types
import { 
  PaymentFormPropTypes
} from '../types';
import { useWallet } from '../../../../hooks/useWallet';

export const PaymentForm: React.FC<PaymentFormPropTypes> = ({ 
  balance,
  onRefresh,
  options,
  isProcessingPayment,
  onClickProcessPayment,
}) => {
  const { t } = useTranslation();
  const { isFetching, isLoading } = useWallet();
  return (
    <div className='flex flex-col gap-3 w-full md:w-1/2'>
      <RestartAmount 
        balance={balance} 
        onRefresh={onRefresh}
      />
      <PaymentOptions options={options} />
      <Button
        buttonText={t('app.components.smartkit.payment_modal.payment_form.button')}
        onClick={() => {
          onClickProcessPayment('DCD')
        }}
        rounded="lg"
        size="large"
        skin="standard"
        theme="black"
        loading={isLoading || isFetching || isProcessingPayment }
        disabled={isLoading || isFetching || isProcessingPayment }
      />
    </div>
  );
};