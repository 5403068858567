import React from "react";
import { useTranslation } from 'react-i18next';

// Hooks
import { useFetchCreateLiveness } from './customHooks/useFetchCreateLiveness';

// Components
import { LivenessProcess } from "./LivenessProcess";
import { ProgressBarInterface } from './ProgressBarInterface';
import { ErrorMessage } from './ErrorMessage';

// Types
import { FaceLivenessProps } from './types';

const FaceLiveness: React.FC<FaceLivenessProps> = ({ 
  faceLivenessAnalysisCb, 
  user_id,
}) => {
  const {
    error,
    sessionId,
    isRateLimitError 
  } = useFetchCreateLiveness(user_id);
  const { t } = useTranslation();
    
  return (
    <React.Fragment>
      {!sessionId && !isRateLimitError && !error && (
        <ProgressBarInterface />
      )}

      {isRateLimitError && (
        <ErrorMessage 
          title={t('app.pages.poh.face_liveness.rate_limit_error.title')}
          message={t('app.pages.poh.face_liveness.rate_limit_error.message')}
        />
      )}

      {error && (
        <ErrorMessage 
          title={error.title}
          message={error.message}
        />
      )}

      {sessionId && (
        <LivenessProcess 
          sessionId={sessionId}
          callback={faceLivenessAnalysisCb}
        />
      )}
    </React.Fragment>
  );
}

export default FaceLiveness;