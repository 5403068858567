import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { AnimatePulse, LoaderContainer } from '../../../../components/uikit';
import { DecideIDCard } from '../../../../components/smartKit';
import { BlockPlate } from '../../../../components/layout';

// Hooks
import { useInfoItemsList } from '../../customHooks/useInfoItemsList';

export const IDCard = () => {
  const { infoItemsList } = useInfoItemsList();
  const { t } = useTranslation();

  return (
    <LoaderContainer 
      isLoading={!infoItemsList.token}
      transitionDuration={1}
      LoadingComponent={() => (
        <BlockPlate className="h-[478px] lg:h-[438px]">
          <AnimatePulse height="100%" bg="from-[#f0f4ff] to-[#f8f9fe]" />
        </BlockPlate>
      )}
      ErrorComponent={() => (
        <div className="text-red-600 text-center">
          {t('app.pages.main.id_card_loading_error')}
        </div>
      )}
    >
      <DecideIDCard 
        status={infoItemsList.status}
        decideId={infoItemsList.token}
        reputationLevel={infoItemsList.reputationLevel}
      />
    </LoaderContainer>
  );
}