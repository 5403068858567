import React from "react";
import { useNavigate } from "react-router-dom";

// Component
import { DangerCircleIcon, Button, Typography, getColorByName } from "@modclub-app/uikit";

export const RateLimitErrorMessage = () => {
  const navigate = useNavigate();
  const makeRedirectonMain = () => navigate("/app");
  return (
    <div className="flex flex-col gap-5 max-w-sm m-auto">
      <div className="flex items-center gap-2 bg-E00 p-4 rounded-lg">
        <DangerCircleIcon 
          stroke={getColorByName("F00")?.hex} 
          strokeWidth={1.2}
        />
        <div className="flex flex-col">
          <Typography size="lg" color="F00" weight="medium">
            Rate limit exceeded
          </Typography>
          <Typography size="sm" color="F00">
            Please wait a few minutes and try again.
          </Typography>
        </div>
      </div>
      <Button
        buttonText="Back to main page"
        fullWidth
        spaceBetween
        iconName="UndoRightRoundSquareIcon"
        onClick={makeRedirectonMain}
        size="medium"
        skin="standard"
        theme="black"
      />
    </div>
  );
}