import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export const Tooltip: React.FC<{ 
  text: string; 
  children: React.ReactNode 
}> = ({ text, children }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ display: 'inline-block', position: 'relative' }}
    >
      {children}
      <AnimatePresence>
        {isHovered && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className='absolute top-[100%] right-[50%] mr-1 p-3 bg-F00 text-C00 rounded-lg whitespace-nowrap z-20'
          >
            {text}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
