import React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// Components
import { Spinner, Button, CheckCircleIcon, getColorByName } from '@modclub-app/uikit';

export const VerificationContent: React.FC<{ 
  isLoading: boolean; 
  isError: boolean 
}> = ({ isLoading, isError }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const redirectToAppPage = () => navigate("/app");
  
  return (
    <React.Fragment>
      {isLoading && (
        <Spinner size="medium" skin="black" />
      )}
  
      {isError && (
        <Button
          buttonText={t('app.pages.verification_email.verification_error.botton_text')}
          iconName="ForwardV2Icon"
          onClick={redirectToAppPage}
          rounded="full"
          size="large"
          skin="standard"
          theme="black"
        />
      )}
  
      {(!isLoading && !isError) && (
        <CheckCircleIcon 
          size={36}
          stroke={getColorByName('F00')?.hex}
        />
      )}
    </React.Fragment>
  );
}