import { useState, useEffect, useRef } from 'react';
import { useProfile } from "../../../hooks/useProfile";
import { useNavigate } from 'react-router-dom';

export const useTokenVerification = (tokenId: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const navigate = useNavigate();
  const { confirmEmail } = useProfile();

  /**
   * Start a timer after receiving a successful result to show the user that the check was successful. 
   * If remove the timer, the redirection will happen immediately. In this case, 
   * the user will not understand whether the operation was successful or not.
   */
  const startTimer = () => {
    timer.current = setTimeout(() => {
      navigate("/app");
    }, 1000);
  };

  const verifyToken = async () => {
    setIsLoading(true);
    try {
      const result = await confirmEmail(tokenId);
      if (result.status === "success") {
        setIsLoading(false);
        startTimer();
      } else if (result.status === "error") {
        setError(result.message);
        setIsLoading(false);
      }
    } catch (err) {
      console.error('ERROR in useTokenVerification:', err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    verifyToken();

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [tokenId]);

  return {
    isLoading,
    error,
  };
};
