import React from 'react';
import { useTranslation } from 'react-i18next';

export const ErrorComponent = () => {
  const { t } = useTranslation();
  return (
    <div className="text-red-600 text-center">
      {t('app.pages.profile.view_mode.status_loading_error')}
    </div>
  );
}