import React from "react";

import AppRouter from './pages';
import { useConfig } from './hooks/useConfig';

export default function AppContainer() {
  const { refreshConfig, isLoading } = useConfig();

  refreshConfig(); // Init the config

  if (isLoading) {
    // Consider adding a loading indicator or waiting page to improve user experience during config init.
    return null;
  }

  return (
    <React.Fragment>
      <AppRouter />
    </React.Fragment>
  );
}
