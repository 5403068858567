import React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Button } from '@modclub-app/uikit';
import { InfoItem } from './InfoItem';
import { ProfileLayout } from '../ProfileLayout';
import { EmailVerification } from './EmailVerification';

import { useProfileStore } from '../../../store/useProfileStore';

type ProfileViewModeTypes = {
  firstName: string;
  lastName: string;
  email: string;
};

export const ViewMode = () => {
  const { formData, setEditMode, profileDataLoaded } = useProfileStore();
  const { t } = useTranslation();
  const profileViewModeData = t('app.pages.profile.view_mode.view_mode_data', { 
    returnObjects: true,
  }) as ProfileViewModeTypes[];

  return (
    <div className="flex flex-col space-y-4">
      <ul className="flex flex-col gap-6">
        {Object.keys(formData).map((item, key) => (
          <li key={key}>
            {item === 'email' ? (
              <EmailVerification 
                label={profileViewModeData[item]} 
                email={formData[item]}
              />
            ) : (
              <InfoItem
                label={profileViewModeData[item]} 
                text={formData[item]}
              />
            )}
          </li>
        ))}
      </ul>
      <ProfileLayout.FormButton>
        <Button
          buttonText="Edit mode"
          iconName="PenV2Icon"
          disabled={!profileDataLoaded}
          onClick={() => setEditMode(true)}
          rounded="full"
          size="medium"
          skin="inlineGrey"
          theme="black"
        />
      </ProfileLayout.FormButton>
    </div>
  );
}