import React from 'react';
import { AnimatePresence } from 'framer-motion';

// Components
import { RadialGradients } from '../../uikit';
import { TextSection } from './TextSection';
import { MotionFade } from './MotionFade';

// Types
import { SplashScreenPropTypes } from './types';

export const SplashScreen: React.FC<SplashScreenPropTypes> = ({ 
  title, 
  text,
  isVisible,
  titleDelay,
  titleDuration,
}) => (
  <AnimatePresence>
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-white">
      <MotionFade isVisible={isVisible}>
        {(title || text) && (
          <TextSection 
            titleDelay={titleDelay}
            titleDuration={titleDuration}
            title={title} 
            text={text} 
          />
        )}
        <RadialGradients />
      </MotionFade>
    </div>
  </AnimatePresence>
);