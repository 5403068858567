import React from "react";
import '@aws-amplify/ui-react/styles.css';

// Types
import { FaceLivenessResult } from './types'

// Components
import { 
  Button,
  Typography,
  DynamicIcon, 
  getColorByName,
} from "@modclub-app/uikit";

// Data
import { DATA_POH } from "../../data/dataPoh";

interface DetectionResultProps {
  faceLivenessAnalysis: FaceLivenessResult;
  tryagain: () => void;
}

const DetectionResult: React.FC<DetectionResultProps> = ({ faceLivenessAnalysis, tryagain }) => (
  <div className="flex flex-col gap-10 max-w-md m-auto">
    <div className="flex flex-col gap-3 text-center">
      <Typography size="2xl" color="F00">{DATA_POH.errorScreen.title}</Typography>
      <Typography size="sm" color="F60">{DATA_POH.errorScreen.subTitle}</Typography>
    </div>
    <div className="flex flex-col gap-2">
      {DATA_POH.errorScreen.tips.map(item => (
        <div 
          key={item} 
          className="flex items-center gap-2 p-4 bg-C20 rounded-lg"
        >
          <DynamicIcon 
            iconName="DangerTriangleIcon"
            stroke={getColorByName("F00")?.hex}
          />
          <Typography size="base" color="F00">
            {item}
          </Typography>
        </div>
      ))}
    </div>
    {(faceLivenessAnalysis.Status === "Fail") && (
      <Button
        buttonText="Try Again"
        fullWidth
        spaceBetween
        iconName="RestartSquareIcon"
        onClick={tryagain}
        size="medium"
        skin="standard"
        theme="black"
      />
    )}
  </div>
);

export default DetectionResult;
