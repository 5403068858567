import React from 'react';

// Components
import { AnimatePulse } from '../../../../components/uikit';
import { InfoItem } from '../InfoItem';

type EmailVerificationProps = {
  label: string;
  email: string;
};

export const LoadingComponent: React.FC<EmailVerificationProps> = ({ 
  label,
  email,
}) => (
  <div className="flex flex-col rounded-lg gap-2 p-3 relative w-[512px] h-[160px]">
    <AnimatePulse 
      className="absolute top-0 left-0 right-0 bottom-0" 
      height="100%" 
      bg="from-[#f6f5fa] to-[#F6F5FA]" 
    />
    <div className="relative z-10">
      <InfoItem text={email} label={label} />
    </div>
  </div>
);