import React from "react";

// Components
import { Spinner, Typography } from '@modclub-app/uikit';

// Types
import { PageLoaderPropTypes } from './types';

export const PageLoader: React.FC<PageLoaderPropTypes> = ({ label }) => (
  <div className="flex flex-col items-center justify-center gap-3">
    <Spinner skin="black" />
    <Typography
      size="base"
      color="F00"
    >
      {label}
    </Typography>
  </div>
);