export const DATA_COMMON = {
  cardId: {
    title: "Decide ID Profile",
    image: "/assets/card_decor.svg",
    bottomDecorImage: "assets/card_bottom_decor.png",
    labels: {
      status: "Status:",
      decideId: "Decide ID:",
      verification: "Verification:",
    },
  },
  loaderText: "Loading",
  landing: {
    title: "Decide ID",
    text: "Unique Identity Verification for Trustworthy Digital Engagements",
    buttonText: "Start your journey",
  },
  about: {
    tooltip: "Want to know more about Decide ID?",
    modal: {
      title: 'About Decide ID',
      text: "Decide ID is at the heart of our Decide AI system, ensuring trust and security through our unique Proof of Personhood (PoP) methodology. When you join, you'll go through a verification process where you provide multiple proofs and complete a special challenge to confirm your identity. This makes sure that every user is real, unique, and not a bot. Your Principal ID, a unique on-chain identifier, is created during this process. It can be used across various applications that require verified personhood, ensuring a secure and seamless integration into any app’s sign-up or login process. Plus, our use of Zero-Knowledge proofs means your private data, like biometric information, remains confidential and safe. With Decide ID, enjoy a flexible, secure, and privacy-first way to verify and engage online."
    }
  },
  copyright: '©2024 DecideAI'
}
