import React from 'react';
import classNames from 'classnames/bind';

// Components
import { Typography } from '@modclub-app/uikit';

// Styles
import styles from './styles.module.scss';
const cn = classNames.bind(styles);

export const TermsPolicy = ({ text }: { text: string }) => (
  <div className='text-center'>
    <Typography 
      color="F00"
      size='sm'
    >
      <div
        className={cn('term-policy')}
        dangerouslySetInnerHTML={{ 
          __html: text
        }} 
      />
    </Typography>
  </div>
);