import React from 'react';

import { ButtonIcon } from '@modclub-app/uikit';

type MenuToggleProps = {
  isOpen: boolean;
  onToggle: () => void;
};

export const MenuToggle: React.FC<MenuToggleProps> = ({ 
  isOpen, 
  onToggle 
}) => (
  <ButtonIcon
    iconName={isOpen ? "CrossIcon" : "HamburgerMenuIcon"}
    onClick={onToggle}
    rounded="full"
    size={24}
    skin="grey"
    theme="black"
  />
);
