import React from 'react';
import cn from "classnames";

export const ImageDecor = () => (
  <div className={cn(
    "absolute max-w-lg",
    "lg:top-1/2 lg:-left-48 lg:right-[unset] bottom-[unset] lg:-translate-y-1/2",
    "left-[unset] -right-36"
  )}>
    <img className="w-full" src="/assets/diamond.png" alt="diamond" />
  </div>
);