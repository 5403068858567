import React from 'react';

// Components
import { BlockPlate } from '../../../layout';

type DecideIDCardLayoutTypes<
  Props = Record<string, never | React.ReactNode>
> = React.FC<Props & { children: React.ReactNode }>;

export const DecideIDCardLayout: DecideIDCardLayoutTypes & {
  RadialGradient: DecideIDCardLayoutTypes;
  ContentWrap: DecideIDCardLayoutTypes;
  LablesList: DecideIDCardLayoutTypes;
} = ({ children }) => (
  <BlockPlate className="relative p-6 overflow-hidden">
    {children}
  </BlockPlate>
);

DecideIDCardLayout.RadialGradient = ({ children }) => (
  <div className="w-full absolute left-1/2 top-1/3 -translate-x-1/2 -transpate-y-1/3 max-w-7xl">
    {children}
  </div>
);

DecideIDCardLayout.ContentWrap = ({ children }) => (
  <div className="flex flex-col justify-end gap-40 relative z-20 p-4 lg:p-6">
    {children}
  </div>
);

DecideIDCardLayout.LablesList = ({ children }) => (
  <div className="flex flex-col gap-2 justify-end">{children}</div>
);