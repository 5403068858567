import { create } from 'zustand'
import { devtools } from 'zustand/middleware';

type State = {
  isOpenRegFeePaymentModal: boolean;
  isOpenDepositModal: boolean;
  isOpenWithdrawModal: boolean;
}

type Action = {
  setIsOpenRegFeePaymentModal: () => void;
  setIsOpenDepositModal: () => void;
  setIsOpenWithdrawModal: () => void;
}

export const useDepositStore = create<State & Action>()(
  devtools((set) => ({
    isOpenDepositModal: false,
    isOpenWithdrawModal: false,
    isOpenRegFeePaymentModal: false,
    setIsOpenRegFeePaymentModal: () =>
      set((state) => ({
        ...state,
        isOpenRegFeePaymentModal: !state.isOpenRegFeePaymentModal,
      })),
    setIsOpenDepositModal: () =>
      set((state) => ({
        ...state,
        isOpenDepositModal: !state.isOpenDepositModal,
      })),
    setIsOpenWithdrawModal: () =>
      set((state) => ({
        ...state,
        isOpenWithdrawModal: !state.isOpenWithdrawModal,
      })),
  }))
);