import React from 'react';

// Components
import { Modal, Portal } from '../../../components/uikit';
import { Button } from "@modclub-app/uikit";

// Types
import { ErrorMessageModalTypes } from '../types';

export const ErrorMessageModal: React.FC<ErrorMessageModalTypes> = ({ 
  onCloseModal, 
  errorMsg, 
  onButtonClick 
}) => (
  <Portal>
    <Modal closeModal={onCloseModal}>
      <p>{`Oops! Something went wrong: "${errorMsg}". If this keeps happening, please contact our support team.`}</p>
      <Button 
        buttonText={"Try again"}
        onClick={onButtonClick}
        size="small"
        skin="standard"
        theme="blue"
      />
    </Modal>
  </Portal>
);