import React from 'react';
import { Button } from '@decide-ai/uikit';
import { useConfig } from '../../../hooks/useConfig';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const DeveloperLoginButton: React.FC = () => {
  const { config } = useConfig();
  const navigate = useNavigate();
  const { t } = useTranslation();
  if (!config?.developer_app_enabled) return null;

  return (
    <Button 
      size="small" 
      buttonText={t('developer_app.login_button_label')}
      onClick={() => navigate('/developer')}
    />
  );
};