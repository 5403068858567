import { useEffect } from "react";
import {
  useQuery,
  useMutation,
  useQueryClient,
  UseMutationResult,
  UseQueryResult,
} from "@tanstack/react-query";
import { useActors } from "./actors";
import { decideid_types } from "../canister_types";
import { useIdentity } from "./useIdentity";
import {useToken} from './useToken';

type EnhancedAccount = decideid_types.Account & {
  lastVerifiedAt: decideid_types.Timestamp | undefined;
  onboardingType: 'reverification' | 'init' | 'retry';
}

interface AccountInfo {
  account: EnhancedAccount,
  profile: decideid_types.Profile,
}

export enum VerificationStatus {
  TokenExpired = "TokenExpired",
  NotVerified = "NotVerified",
  InProgress = "InProgress",
  Verified = "Verified",
  StatusError = "StatusError",
  SentVerificationError = "SentVerificationError",
  SentVerificationSuccess = "SentVerificationSuccess"
}

type UpdateProfileStatus = { ok: string;} | { err: string; };

type EmailResponseType = 
  | { status: "success"; message: string }
  | { status: "error"; message: string };

type UseProfileInterface = {
  profile: decideid_types.Profile | undefined,
  account: EnhancedAccount | undefined,
  isLoading: boolean,
  isSuccess: boolean,
  isError: boolean,
  error: Error | null,
  updateProfile: (data: { firstName: string, lastName: string, email: [string] | [] }) => Promise<UpdateProfileStatus>,
  updateProfileLoadingStatus: string,
  createProfile: (data: { firstName: string, lastName: string, email: string }) => void,
  sendVerificationEmail: () => Promise<VerificationStatus>,
  getVerificationStatus: (email: string) => Promise<VerificationStatus>,
  confirmEmail: (token: string) => Promise<EmailResponseType>,
  hardRefreshProfileCache: () => Promise<void>,
  refreshProfile: () => void,
  clearProfile: () => void,
  principal: string | null
}

export function useProfile() : UseProfileInterface {
  const queryClient = useQueryClient();
  const { decideid } = useActors();
  const { isConnected, principal } = useIdentity();
  const { setToken } = useToken();

  async function fetchProfile(): Promise<AccountInfo> {
    if (decideid) {
      const [getAccByCallerResult, lastVerifiedAt,  onboardingType] = await Promise.all([
        decideid.getAccByCaller(),
        decideid.lastVerifiedAt().catch((error)=>{
          console.warn("Failed to fetch lastVerifiedAt (expected for new users who haven't completed proof of humanity yet):", error);
          return []; // For any error of lastVerifiedAt, we will just return empty.
        }),
        decideid.onboardingType().catch((error)=>{
          console.error("Failed to fetch onboardingType:", error);
          return { 'init': null }; // default to "init"
        })
      ]);
      if ("err" in getAccByCallerResult) {
        throw new Error(getAccByCallerResult.err);
      } else if ("ok" in getAccByCallerResult) {
        const accountExtra: EnhancedAccount = {

          
          ...getAccByCallerResult.ok.acc,
          lastVerifiedAt: lastVerifiedAt[0] ?? undefined,
          onboardingType: Object.keys(onboardingType)[0] ?? undefined,
        };

        return {
          account: accountExtra,
          profile: getAccByCallerResult.ok.profile,
        };
      }
    }
    throw new Error("decideid is not ready");
  }

  async function updateProfileOnServer(userData: {
    firstName: string;
    lastName: string;
    email: [string] | [];
  }): Promise<UpdateProfileStatus> { // TODO: implement optimistic updates here
    const { firstName, lastName, email } = userData;

    try {
      const result = await decideid.updateProfile(
        [firstName], 
        [lastName], 
        email
      );

      if (result && 'err' in result) {
        throw new Error(`ERROR IN UPDATE PROFILE RESPONCE::: ${result.err}`);
      }

      return result;
    } catch (error) {
      throw new Error(`FAILED TO UPDATE PROFILE:: ${error}`);
    }
  }

  async function getVerificationStatus(email: string) {
    try {
      const result = await decideid.getVerificationEmailStatus([email]);
      if (result && "ok" in result) {
        const status = Object.keys(result.ok)[0];
        return status;
      }
      
      return VerificationStatus.StatusError;
    } catch (error) {
      console.error("Failed to fetch verification email status:", error);
    }
  }

  const {
    data: accountInfo,
    error,
    isError,
    isLoading,
    isSuccess,
  }: UseQueryResult<AccountInfo, Error> = useQuery({
    queryKey: ["profile"],
    queryFn: fetchProfile,
    retry: false, // Do not retry on failure
  });

  const updateProfileMutation: UseMutationResult<
    decideid_types.Profile,
    Error,
    decideid_types.Profile
  > = useMutation({
    mutationFn: updateProfileOnServer,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["profile"] });
    },
  });

  async function createProfileOnServer(userData: {
    firstName: string;
    lastName: string;
    email: string;
  }): Promise<string> {
    const { firstName, lastName, email } = userData;
    try {
      const result = await decideid.registerAccount([firstName], [lastName], [email]);
      // Process result
      if ("ok" in result) {
        const { decide_id, token } = result.ok;
        setToken(token);

        return decide_id;
      } else if ("err" in result) {
        throw new Error(result.err);
      } else {
        throw new Error("Unexpected result from registerAccount");
      }
    } catch (error) {
      console.error("Failed to create profile on server:", error);
      throw error;
    }
  }

  async function sendVerificationEmail(): Promise<VerificationStatus> {
    try {
      const result = await decideid.sendVerificationEmail();
      if ("ok" in result) {
        return VerificationStatus.SentVerificationSuccess;
      } else if ("err" in result) {
        return VerificationStatus.SentVerificationError;
      } else {
        throw new Error("Unexpected result from sendVerificationEmail");
      }
    } catch (error) {
      console.error("Failed to send email verification token:", error);
      throw error;
    }
  }

  async function confirmEmail(tokenKey: string): Promise<EmailResponseType> {
    try {
      const result = await decideid.confirmEmail(tokenKey);
      if ("ok" in result) {
        return { status: "success", message: result.ok };
      } else if ("err" in result) {
        return { status: "error", message: result.err };
      } else {
        throw new Error("Unexpected result from confirmEmail");
      }
    } catch (error) {
      console.error("Failed to confirm email:", error);
      throw error;
    }
  }

  // TODO: should consider optimistic mutations
  const createProfileMutation = useMutation({
    mutationFn: createProfileOnServer,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["profile"] });
    },
  });

  const refreshProfile = () => {
    queryClient.invalidateQueries({ queryKey: ["profile"] });
  };

  const hardRefreshProfileCache = async (): Promise<void> => {
    await queryClient.invalidateQueries({ queryKey: ["profile"] });
    await queryClient.refetchQueries({ queryKey: ["profile"] });
  };

  const clearProfile = () => {
    queryClient.clear();
    queryClient.setQueryData(["profile"], null);
  };

  useEffect(() => {
    if (isConnected && decideid) {
      console.log(principal);
      refreshProfile();
    }
  }, [decideid, isConnected]);

  return {
    profile: accountInfo?.profile,
    account: accountInfo?.account,
    isLoading,
    isSuccess,
    isError,
    error,
    updateProfile: updateProfileMutation.mutateAsync,
    updateProfileLoadingStatus: updateProfileMutation.status,
    createProfile: createProfileMutation.mutateAsync,
    sendVerificationEmail,
    getVerificationStatus,
    confirmEmail,
    hardRefreshProfileCache,
    refreshProfile,
    clearProfile,
    principal,
  };
}
