import React from 'react';

export const ImgSvg: React.FC<{ className: string; }> = ({ className }) => (
  <svg className={className} width="274" height="274" viewBox="0 0 274 274" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M254.412 89.7246C254.412 150.309 207.549 199.403 149.764 199.403C91.9786 199.403 45.1149 150.309 45.1149 89.7246C45.1149 88.4345 45.7278 86.9991 46.9583 85.4641C48.1869 83.9314 50.015 82.32 52.4029 80.6836C57.1782 77.4111 64.1592 74.0608 72.9604 71.0483C90.5604 65.024 115.403 60.363 144.345 60.363C173.282 60.363 200.835 65.9352 221.153 72.4189C231.312 75.6609 239.655 79.1288 245.454 82.2378C248.355 83.793 250.61 85.2539 252.137 86.5467C252.9 87.1933 253.474 87.7918 253.856 88.3328C254.239 88.8767 254.412 89.3411 254.412 89.7246Z" stroke="url(#paint0_linear_449_29162)" strokeWidth="0.5"/>
<path d="M256.969 97.9881C252.674 158.405 202.459 203.714 144.834 199.215C87.2082 194.717 43.9545 142.11 48.2496 81.6933C48.341 80.4068 49.054 79.0231 50.3899 77.5881C51.7238 76.1553 53.661 74.6907 56.1584 73.2447C61.1525 70.3531 68.3517 67.5555 77.3422 65.2365C95.3206 60.5992 120.425 57.8851 149.287 60.1384C178.144 62.3912 205.226 70.0932 225.028 78.1408C234.929 82.1648 243.004 86.2726 248.566 89.8246C251.348 91.6013 253.494 93.2337 254.925 94.6418C255.64 95.3461 256.17 95.9877 256.512 96.5569C256.856 97.129 256.996 97.6056 256.969 97.9881Z" stroke="url(#paint1_linear_449_29162)" strokeWidth="0.5"/>
<path d="M258.789 105.692C250.585 165.663 197.547 207.291 140.346 198.697C83.1457 190.103 43.4048 134.537 51.609 74.5665C51.7837 73.2896 52.5847 71.96 54.0106 70.6235C55.4343 69.289 57.4621 67.9658 60.0475 66.701C65.2176 64.1719 72.5817 61.8936 81.7019 60.2204C99.9396 56.8746 125.162 55.9552 153.811 60.2593C182.455 64.5626 208.975 74.1761 228.209 83.6158C237.826 88.3358 245.616 93.0093 250.935 96.9493C253.596 98.9201 255.631 100.702 256.967 102.208C257.635 102.962 258.122 103.64 258.426 104.232C258.732 104.827 258.841 105.313 258.789 105.692Z" stroke="url(#paint2_linear_449_29162)" strokeWidth="0.5"/>
<path d="M260.054 113.117C248.174 172.408 192.684 210.363 136.131 197.919C79.5779 185.475 43.3418 127.337 55.2217 68.0462C55.4746 66.7841 56.3557 65.5115 57.8609 64.2742C59.3639 63.0387 61.4689 61.8554 64.1268 60.7681C69.4419 58.5938 76.931 56.8183 86.1352 55.7653C104.541 53.6597 129.767 54.4478 158.092 60.6803C186.411 66.9118 212.284 78.2986 230.897 89.0193C240.204 94.3798 247.689 99.5704 252.755 103.862C255.288 106.008 257.209 107.924 258.45 109.518C259.07 110.315 259.515 111.025 259.782 111.636C260.05 112.251 260.129 112.742 260.054 113.117Z" stroke="url(#paint3_linear_449_29162)" strokeWidth="0.5"/>
<path d="M260.817 120.15C245.532 178.577 187.951 212.94 132.22 196.929C76.4894 180.918 43.6809 120.586 58.9658 62.1596C59.2911 60.9161 60.244 59.7019 61.8179 58.5624C63.3895 57.4247 65.5591 56.3771 68.275 55.4605C73.7061 53.6276 81.2841 52.3307 90.5323 51.8639C109.026 50.9305 134.161 53.3184 162.073 61.3374C189.98 69.3551 215.148 82.3634 233.107 94.2459C242.086 100.187 249.258 105.844 254.066 110.449C256.471 112.752 258.278 114.786 259.424 116.456C259.998 117.291 260.4 118.027 260.631 118.655C260.864 119.285 260.914 119.781 260.817 120.15Z" stroke="url(#paint4_linear_449_29162)" strokeWidth="0.5"/>
<path d="M261.2 128.316C242.053 185.479 182.319 215.537 127.793 195.479C73.2662 175.422 44.5663 112.832 63.7136 55.6702C64.121 54.454 65.1523 53.3128 66.7985 52.2915C68.4422 51.2717 70.6764 50.3858 73.4469 49.6705C78.9871 48.2402 86.6332 47.502 95.89 47.7143C114.401 48.1389 139.315 52.3637 166.623 62.4092C193.927 72.4532 218.165 87.2747 235.287 100.445C243.848 107.03 250.624 113.198 255.114 118.144C257.359 120.619 259.025 122.78 260.057 124.53C260.573 125.405 260.926 126.169 261.115 126.812C261.304 127.458 261.321 127.956 261.2 128.316Z" stroke="url(#paint5_linear_449_29162)" strokeWidth="0.5"/>
<path d="M261.07 135.637C238.543 191.429 177.13 217.507 123.908 193.908C70.6873 170.309 45.7868 105.958 68.3137 50.1666C68.7928 48.98 69.8901 47.9089 71.5941 46.9976C73.2956 46.0878 75.5783 45.3503 78.3862 44.8183C84.0011 43.7545 91.6764 43.5198 100.902 44.3395C119.352 45.9787 143.964 51.831 170.619 63.6499C197.269 75.467 220.573 91.8509 236.873 106.119C245.024 113.254 251.419 119.855 255.603 125.086C257.696 127.703 259.23 129.97 260.156 131.784C260.618 132.691 260.924 133.477 261.074 134.131C261.225 134.788 261.212 135.285 261.07 135.637Z" stroke="url(#paint6_linear_449_29162)" strokeWidth="0.5"/>
<path d="M260.272 144.985C233.542 198.698 170.328 219.523 119.087 191.519C67.8454 163.514 47.9601 97.2744 74.6905 43.5613C75.2588 42.4195 76.4342 41.4444 78.2026 40.6795C79.9682 39.9159 82.3002 39.3729 85.1398 39.0791C90.8183 38.4915 98.4869 38.9037 107.62 40.4975C125.885 43.6847 149.969 51.5902 175.631 65.6151C201.29 79.6378 223.263 97.9308 238.417 113.526C245.994 121.323 251.862 128.441 255.632 134.008C257.518 136.793 258.873 139.181 259.656 141.068C260.048 142.011 260.293 142.82 260.392 143.484C260.492 144.152 260.441 144.646 260.272 144.985Z" stroke="url(#paint7_linear_449_29162)" strokeWidth="0.5"/>
<path d="M258.729 154.375C227.914 205.626 163.292 220.987 114.396 188.701C65.4995 156.414 50.8309 88.6966 81.6468 37.4456C82.3014 36.3568 83.5482 35.4854 85.3703 34.874C87.1894 34.2636 89.5559 33.9216 92.409 33.8711C98.1143 33.7702 105.726 34.8357 114.705 37.2039C132.661 41.9397 156.051 51.875 180.538 68.0441C205.021 84.2107 225.499 104.319 239.391 121.155C246.337 129.574 251.632 137.169 254.957 143.04C256.62 145.976 257.785 148.473 258.42 150.42C258.737 151.393 258.918 152.22 258.965 152.891C259.013 153.565 258.923 154.053 258.729 154.375Z" stroke="url(#paint8_linear_449_29162)" strokeWidth="0.5"/>
<path d="M256.816 162.362C222.63 211.208 157.136 221.759 110.532 185.94C63.9273 150.121 53.8517 81.4875 88.0371 32.6419C88.763 31.6047 90.0648 30.8276 91.9236 30.3524C93.7792 29.8779 96.1627 29.7115 99.0123 29.8719C104.71 30.1927 112.231 31.8178 121.029 34.8434C138.622 40.8939 161.286 52.5324 184.625 70.4702C207.96 88.4052 227.034 109.976 239.759 127.797C246.122 136.708 250.893 144.676 253.815 150.777C255.276 153.829 256.271 156.406 256.772 158.395C257.023 159.389 257.148 160.228 257.15 160.9C257.152 161.576 257.03 162.056 256.816 162.362Z" stroke="url(#paint9_linear_449_29162)" strokeWidth="0.5"/>
<path d="M254.275 170.449C216.785 216.553 150.732 222.077 106.74 182.793C62.7486 143.509 57.4734 74.2892 94.9628 28.1851C95.7583 27.2068 97.1102 26.5312 98.9971 26.1991C100.881 25.8676 103.27 25.8836 106.1 26.2612C111.761 27.0162 119.149 29.2113 127.713 32.9007C144.839 40.2785 166.634 53.6167 188.663 73.289C210.69 92.9583 228.214 115.929 239.666 134.674C245.392 144.047 249.597 152.358 252.087 158.667C253.332 161.822 254.145 164.467 254.507 166.49C254.688 167.501 254.754 168.347 254.709 169.017C254.665 169.692 254.509 170.16 254.275 170.449Z" stroke="url(#paint10_linear_449_29162)" strokeWidth="0.5"/>
<path d="M251.358 177.904C210.927 221.19 144.667 221.932 103.362 179.563C62.0578 137.194 61.3491 67.7571 101.78 24.4717C102.638 23.5537 104.03 22.9774 105.935 22.7824C107.835 22.5877 110.218 22.7762 113.017 23.3574C118.615 24.5194 125.841 27.243 134.142 31.5422C150.742 40.1395 171.608 55.0202 192.292 76.237C212.972 97.4506 228.944 121.632 239.136 141.16C244.233 150.925 247.881 159.52 249.95 165.993C250.984 169.231 251.621 171.929 251.849 173.972C251.963 174.995 251.974 175.843 251.885 176.509C251.796 177.178 251.611 177.634 251.358 177.904Z" stroke="url(#paint11_linear_449_29162)" strokeWidth="0.5"/>
<path d="M248.082 184.884C204.994 225.254 138.834 221.378 100.309 176.222C61.7837 131.066 65.4854 61.7339 108.573 21.364C109.486 20.5084 110.912 20.0304 112.824 19.9685C114.733 19.9068 117.098 20.2609 119.855 21.0359C125.367 22.5857 132.404 25.8069 140.414 30.6753C156.432 40.4108 176.307 56.7131 195.598 79.3249C214.887 101.933 229.289 127.175 238.219 147.37C242.684 157.468 245.778 166.299 247.432 172.902C248.259 176.205 248.723 178.942 248.821 180.996C248.87 182.024 248.827 182.872 248.696 183.529C248.565 184.191 248.351 184.633 248.082 184.884Z" stroke="url(#paint12_linear_449_29162)" strokeWidth="0.5"/>
<path d="M244.695 191.069C199.337 228.606 133.526 220.504 97.7031 172.965C61.8804 125.427 69.6169 56.4612 114.975 18.9252C115.936 18.1301 117.387 17.7442 119.299 17.8048C121.208 17.8653 123.548 18.3702 126.254 19.3201C131.666 21.2198 138.502 24.8854 146.214 30.2574C161.634 40.9999 180.523 58.5439 198.46 82.3482C216.396 106.149 229.3 132.265 237.036 152.995C240.905 163.36 243.479 172.372 244.744 179.069C245.377 182.419 245.681 185.18 245.659 187.237C245.648 188.266 245.556 189.109 245.387 189.757C245.217 190.41 244.978 190.836 244.695 191.069Z" stroke="url(#paint13_linear_449_29162)" strokeWidth="0.5"/>
<path d="M215.45 197.277C168.006 231.863 102.784 219.599 69.7745 169.872C36.7652 120.145 48.4764 51.7977 95.9204 17.2116C96.9249 16.4794 98.3944 16.1862 100.3 16.3682C102.202 16.5498 104.509 17.2023 107.155 18.3224C112.446 20.5621 119.059 24.6552 126.445 30.5071C141.217 42.209 159.056 60.9203 175.585 85.82C192.111 110.716 203.482 137.604 210.005 158.786C213.268 169.378 215.316 178.537 216.192 185.302C216.63 188.686 216.774 191.462 216.633 193.514C216.562 194.54 216.421 195.376 216.215 196.012C216.008 196.653 215.745 197.062 215.45 197.277Z" stroke="url(#paint14_linear_449_29162)" strokeWidth="0.5"/>
<path d="M211.44 202.892C162.103 234.433 97.7 218.094 67.5946 166.381C37.4893 114.668 53.0913 47.1797 102.428 15.6379C103.472 14.9704 104.955 14.77 106.847 15.0714C108.735 15.3723 111 16.1685 113.577 17.4529C118.731 20.0211 125.098 24.5224 132.136 30.8279C146.212 43.4372 162.949 63.2358 178.023 89.1295C193.095 115.019 202.907 142.573 208.207 164.128C210.857 174.906 212.377 184.177 212.865 190.985C213.109 194.391 213.093 197.17 212.835 199.21C212.706 200.23 212.517 201.055 212.275 201.677C212.032 202.304 211.746 202.696 211.44 202.892Z" stroke="url(#paint15_linear_449_29162)" strokeWidth="0.5"/>
<path d="M207.91 207.316C157.147 236.263 93.5931 216.61 65.9625 163.4C38.332 110.19 57.0984 43.5921 107.861 14.6445C108.935 14.0321 110.425 13.9087 112.301 14.3077C114.172 14.7059 116.397 15.6185 118.911 17.0348C123.937 19.8669 130.084 24.6924 136.816 31.3548C150.28 44.6777 166.063 65.319 179.898 91.9618C193.731 118.601 202.231 146.629 206.508 168.432C208.647 179.334 209.728 188.673 209.894 195.498C209.977 198.912 209.831 201.687 209.477 203.711C209.3 204.724 209.072 205.538 208.801 206.147C208.529 206.76 208.224 207.136 207.91 207.316Z" stroke="url(#paint16_linear_449_29162)" strokeWidth="0.5"/>
<path d="M204.619 211.077C152.701 237.676 90.0247 215.137 64.6347 160.714C39.2448 106.291 60.766 40.6146 112.684 14.0152C113.782 13.4528 115.276 13.3975 117.133 13.8818C118.986 14.3653 121.171 15.3786 123.624 16.9085C128.528 19.9677 134.467 25.0696 140.916 32.0335C153.813 45.9591 168.721 67.3023 181.434 94.5523C194.145 121.798 201.471 150.189 204.837 172.167C206.519 183.156 207.211 192.535 207.093 199.361C207.034 202.776 206.772 205.542 206.334 207.548C206.115 208.551 205.854 209.354 205.558 209.95C205.26 210.55 204.94 210.912 204.619 211.077Z" stroke="url(#paint17_linear_449_29162)" strokeWidth="0.5"/>
<path d="M201.011 214.827C147.99 238.917 86.3598 213.407 63.3644 157.826C40.369 102.245 64.727 37.6658 117.748 13.5758C118.868 13.0666 120.363 13.0827 122.197 13.6553C124.028 14.2268 126.166 15.3435 128.549 16.9891C133.315 20.2795 139.027 25.66 145.166 32.9249C157.443 47.4525 171.407 69.4862 182.921 97.3155C194.433 125.141 200.516 153.852 202.921 175.968C204.124 187.026 204.407 196.429 203.992 203.242C203.784 206.651 203.402 209.401 202.877 211.384C202.615 212.376 202.319 213.166 201.997 213.747C201.673 214.332 201.339 214.678 201.011 214.827Z" stroke="url(#paint18_linear_449_29162)" strokeWidth="0.5"/>
<path d="M197.19 218.476C143.161 239.963 82.7257 211.485 62.2145 154.846C41.7033 98.2061 68.8936 34.8808 122.923 13.3939C124.064 12.9398 125.556 13.0284 127.363 13.6896C129.166 14.3494 131.253 15.5689 133.561 17.3285C138.176 20.8471 143.643 26.4994 149.455 34.0549C161.076 49.1637 174.05 71.8523 184.32 100.211C194.588 128.566 199.393 157.542 200.818 179.751C201.53 190.856 201.396 200.263 200.68 207.049C200.321 210.443 199.818 213.172 199.206 215.128C198.9 216.106 198.57 216.88 198.223 217.445C197.873 218.014 197.524 218.343 197.19 218.476Z" stroke="url(#paint19_linear_449_29162)" strokeWidth="0.5"/>
<path d="M191.621 223.197C136.388 241.002 77.8227 208.516 60.8251 150.612C43.8275 92.7077 74.8439 31.3438 130.077 13.5384C131.244 13.1622 132.727 13.3509 134.49 14.1324C136.249 14.9122 138.256 16.2696 140.452 18.181C144.842 22.003 149.951 28.0117 155.288 35.9428C165.959 51.8027 177.515 75.3179 186.026 104.31C194.535 133.297 197.555 162.537 197.615 184.796C197.645 195.926 196.935 205.304 195.804 212.028C195.238 215.392 194.569 218.081 193.838 219.991C193.473 220.947 193.096 221.698 192.715 222.238C192.331 222.782 191.962 223.087 191.621 223.197Z" stroke="url(#paint20_linear_449_29162)" strokeWidth="0.5"/>
<path d="M185.773 227.555C129.562 241.598 73.1121 205.232 59.705 146.3C46.298 87.3687 81.0187 28.2239 137.23 14.1802C138.417 13.8836 139.885 14.1718 141.596 15.0705C143.304 15.9673 145.224 17.4573 147.297 19.5128C151.444 23.6229 156.174 29.9638 161.012 38.2384C170.687 54.785 180.776 79.031 187.489 108.537C194.2 138.038 195.418 167.421 194.111 189.638C193.458 200.747 192.174 210.058 190.632 216.692C189.861 220.01 189.028 222.649 188.181 224.506C187.758 225.435 187.336 226.159 186.922 226.672C186.506 227.189 186.119 227.468 185.773 227.555Z" stroke="url(#paint21_linear_449_29162)" strokeWidth="0.5"/>
<path d="M181.79 230.197C125.064 241.741 70.1295 202.908 59.1094 143.435C48.0892 83.9627 85.1629 26.4076 141.889 14.8643C143.087 14.6205 144.543 14.9734 146.216 15.947C147.886 16.9186 149.744 18.4922 151.733 20.6377C155.71 24.9277 160.18 31.4723 164.68 39.9536C173.68 56.9138 182.783 81.5846 188.3 111.361C193.817 141.134 193.85 170.544 191.649 192.684C190.548 203.754 188.89 212.999 187.082 219.559C186.178 222.84 185.239 225.44 184.319 227.258C183.859 228.167 183.407 228.872 182.974 229.366C182.537 229.865 182.14 230.126 181.79 230.197Z" stroke="url(#paint22_linear_449_29162)" strokeWidth="0.5"/>
<path d="M176.902 233.108C119.693 241.638 66.6976 199.951 58.5544 139.971C50.4111 79.9912 90.2085 24.4695 147.418 15.9398C148.626 15.7598 150.062 16.189 151.687 17.2499C153.307 18.3085 155.088 19.9783 156.971 22.2262C160.736 26.721 164.886 33.4937 168.972 42.2021C177.144 59.6165 185.047 84.7372 189.125 114.768C193.201 144.794 191.818 174.169 188.554 196.164C186.922 207.162 184.821 216.308 182.7 222.764C181.639 225.993 180.576 228.54 179.569 230.307C179.065 231.19 178.581 231.87 178.124 232.341C177.664 232.816 177.254 233.056 176.902 233.108Z" stroke="url(#paint23_linear_449_29162)" strokeWidth="0.5"/>
<path d="M170.9 236.238C113.305 241.147 62.7976 196.196 58.111 135.811C53.4245 75.4257 96.3362 22.5127 153.931 17.6038C155.147 17.5002 156.556 18.0191 158.117 19.1804C159.674 20.3392 161.355 22.1183 163.106 24.4808C166.606 29.2048 170.36 36.2267 173.939 45.1769C181.097 63.0748 187.545 88.6482 189.891 118.881C192.237 149.11 189.171 178.345 184.65 200.094C182.39 210.969 179.768 219.967 177.28 226.277C176.036 229.434 174.829 231.909 173.722 233.609C173.169 234.46 172.646 235.107 172.163 235.549C171.677 235.994 171.254 236.207 170.9 236.238Z" stroke="url(#paint24_linear_449_29162)" strokeWidth="0.5"/>
<path d="M165.413 238.704C107.65 240.371 59.514 192.648 57.9225 132.086C56.331 71.5246 101.887 21.098 159.651 19.431C160.87 19.3958 162.25 19.9931 163.749 21.2405C165.245 22.4851 166.833 24.356 168.46 26.8134C171.714 31.7272 175.103 38.9499 178.219 48.0883C184.451 66.3625 189.582 92.2613 190.379 122.583C191.175 152.9 186.618 181.92 180.992 203.385C178.179 214.117 175.1 222.954 172.293 229.116C170.889 232.198 169.557 234.602 168.366 236.238C167.769 237.056 167.214 237.673 166.71 238.087C166.201 238.504 165.768 238.694 165.413 238.704Z" stroke="url(#paint25_linear_449_29162)" strokeWidth="0.5"/>
<path d="M159.012 241.144C101.259 239.114 55.9928 188.401 57.9312 127.851C59.8697 67.3001 108.278 19.881 166.03 21.9113C167.249 21.9542 168.593 22.6387 170.016 23.9795C171.436 25.3174 172.912 27.2864 174.393 29.8433C177.355 34.9561 180.317 42.382 182.896 51.7027C188.052 70.3415 191.665 96.5201 190.694 126.836C189.724 157.147 183.485 185.822 176.619 206.887C173.186 217.42 169.599 226.043 166.438 232.014C164.857 235.001 163.388 237.315 162.103 238.871C161.46 239.65 160.87 240.231 160.342 240.611C159.811 240.995 159.367 241.156 159.012 241.144Z" stroke="url(#paint26_linear_449_29162)" strokeWidth="0.5"/>
<path d="M152.273 243.218C94.7567 237.378 52.6296 183.777 58.205 123.475C63.7805 63.1733 114.944 19.0449 172.46 24.8849C173.674 25.0082 174.973 25.78 176.313 27.2122C177.65 28.6413 179.005 30.7038 180.329 33.3534C182.978 38.6516 185.487 46.2583 187.5 55.7308C191.525 74.6729 193.557 101.038 190.765 131.23C187.974 161.417 180.023 189.623 171.905 210.192C167.845 220.477 163.746 228.847 160.233 234.597C158.476 237.473 156.87 239.686 155.494 241.154C154.806 241.889 154.182 242.429 153.633 242.774C153.079 243.122 152.627 243.254 152.273 243.218Z" stroke="url(#paint27_linear_449_29162)" strokeWidth="0.5"/>
<path d="M145.342 244.868C88.2973 235.198 49.5158 178.893 58.7471 119.086C67.9785 59.2796 121.721 18.6575 178.765 28.327C179.97 28.5311 181.22 29.3883 182.47 30.9071C183.717 32.4225 184.944 34.5713 186.104 37.304C188.425 42.7683 190.467 50.5271 191.9 60.1147C194.765 79.2871 195.189 105.734 190.567 135.678C185.945 165.617 176.296 193.235 166.943 213.22C162.266 223.213 157.666 231.292 153.811 236.796C151.882 239.549 150.145 241.65 148.683 243.023C147.951 243.71 147.296 244.208 146.726 244.516C146.152 244.826 145.693 244.927 145.342 244.868Z" stroke="url(#paint28_linear_449_29162)" strokeWidth="0.5"/>
<path d="M138.439 246.027C82.0847 232.628 46.749 173.885 59.5404 114.803C72.3319 55.7217 128.399 18.7104 184.753 32.1093C185.943 32.3923 187.139 33.3301 188.296 34.9279C189.451 36.5224 190.546 38.7475 191.541 41.5511C193.53 47.157 195.104 55.0347 195.96 64.6975C197.672 84.0204 196.513 110.443 190.108 140.024C183.705 169.601 172.422 196.53 161.891 215.861C156.626 225.527 151.552 233.288 147.374 238.528C145.285 241.148 143.426 243.131 141.884 244.406C141.113 245.043 140.429 245.497 139.842 245.767C139.251 246.038 138.786 246.11 138.439 246.027Z" stroke="url(#paint29_linear_449_29162)" strokeWidth="0.5"/>
<path d="M131.345 246.743C75.9214 229.6 44.2386 168.611 60.6044 110.507C76.9702 52.4028 135.178 19.2189 190.601 36.3624C191.772 36.7246 192.909 37.7407 193.966 39.4127C195.021 41.0812 195.978 43.3751 196.8 46.2394C198.444 51.9671 199.534 59.9339 199.8 69.6343C200.331 89.0322 197.563 115.323 189.369 144.415C181.176 173.503 168.275 199.622 156.588 218.209C150.744 227.502 145.208 234.908 140.72 239.857C138.475 242.333 136.498 244.187 134.882 245.356C134.074 245.94 133.364 246.348 132.761 246.577C132.155 246.809 131.687 246.849 131.345 246.743Z" stroke="url(#paint30_linear_449_29162)" strokeWidth="0.5"/>
<path d="M123.637 246.978C69.4769 225.857 41.897 162.717 62.059 105.94C82.221 49.1623 142.48 20.2769 196.64 41.3978C197.784 41.8442 198.851 42.9406 199.795 44.6852C200.737 46.4261 201.541 48.7839 202.172 51.701C203.434 57.5339 203.995 65.5606 203.62 75.2569C202.869 94.6467 198.373 120.674 188.278 149.102C178.185 177.526 163.59 202.647 150.704 220.342C144.261 229.19 138.249 236.176 133.445 240.788C131.042 243.095 128.948 244.801 127.258 245.85C126.413 246.375 125.678 246.73 125.062 246.915C124.441 247.102 123.971 247.108 123.637 246.978Z" stroke="url(#paint31_linear_449_29162)" strokeWidth="0.5"/>
<path d="M117.603 246.763C64.6139 222.598 40.3394 157.985 63.4064 102.436C86.4735 46.8882 148.137 21.4653 201.126 45.6303C202.246 46.1412 203.255 47.2969 204.108 49.0924C204.959 50.8843 205.639 53.2842 206.118 56.2328C207.077 62.1287 207.222 70.1755 206.346 79.8361C204.594 99.1547 198.759 124.889 187.21 152.702C175.662 180.51 159.79 204.766 146.008 221.703C139.117 230.172 132.753 236.807 127.717 241.139C125.199 243.306 123.02 244.891 121.278 245.843C120.408 246.319 119.655 246.631 119.03 246.782C118.401 246.933 117.931 246.913 117.603 246.763Z" stroke="url(#paint32_linear_449_29162)" strokeWidth="0.5"/>
<path d="M110.829 246.132C59.3428 218.627 38.8729 152.583 65.1276 98.6123C91.3823 44.642 154.409 23.2042 205.896 50.7099C206.985 51.2916 207.925 52.51 208.671 54.3566C209.416 56.1995 209.956 58.6384 210.263 61.612C210.876 67.5581 210.554 75.5991 209.117 85.1858C206.245 104.356 198.924 129.67 185.778 156.693C172.634 183.712 155.381 206.908 140.639 222.934C133.269 230.947 126.53 237.162 121.252 241.165C118.612 243.167 116.345 244.61 114.552 245.449C113.655 245.868 112.886 246.132 112.253 246.242C111.616 246.353 111.148 246.303 110.829 246.132Z" stroke="url(#paint33_linear_449_29162)" strokeWidth="0.5"/>
<path d="M104.228 245.076C54.4044 214.388 37.7465 147.177 67.0376 94.9528C96.3286 42.7282 160.467 25.2831 210.291 55.9713C211.345 56.6206 212.214 57.8961 212.853 59.7862C213.492 61.6726 213.891 64.141 214.027 67.1285C214.3 73.1023 213.519 81.1085 211.537 90.5877C207.574 109.543 198.82 134.352 184.153 160.501C169.489 186.647 150.941 208.719 135.311 223.79C127.495 231.327 120.414 237.108 114.917 240.772C112.167 242.605 109.822 243.903 107.984 244.627C107.064 244.99 106.281 245.205 105.644 245.275C105.002 245.346 104.537 245.266 104.228 245.076Z" stroke="url(#paint34_linear_449_29162)" strokeWidth="0.5"/>
<path d="M97.0092 243.438C49.2328 209.359 36.8602 141.14 69.3863 91.0638C101.912 40.9881 167.012 28.031 214.789 62.1105C215.8 62.832 216.586 64.1655 217.105 66.0958C217.623 68.0223 217.865 70.5129 217.813 73.5032C217.707 79.4828 216.421 87.417 213.845 96.7379C208.693 115.377 198.39 139.523 182.104 164.597C165.819 189.667 145.918 210.403 129.369 224.357C121.095 231.334 113.664 236.611 107.947 239.886C105.088 241.524 102.665 242.656 100.786 243.252C99.8458 243.549 99.0508 243.71 98.4101 243.736C97.7653 243.762 97.306 243.65 97.0092 243.438Z" stroke="url(#paint35_linear_449_29162)" strokeWidth="0.5"/>
<path d="M90.2454 241.417C44.6154 204.244 36.3773 135.342 71.8534 87.519C107.329 39.6957 173.08 31.0685 218.71 68.241C219.676 69.0284 220.381 70.4118 220.782 72.3724C221.183 74.3294 221.275 76.8311 221.042 79.8119C220.577 85.7723 218.816 93.6057 215.683 102.737C209.419 120.998 197.683 144.414 179.919 168.361C162.157 192.305 141.047 211.683 123.691 224.514C115.013 230.93 107.279 235.705 101.376 238.595C98.4238 240.041 95.9383 241.011 94.0268 241.48C93.0707 241.715 92.2676 241.823 91.6265 241.806C90.9814 241.79 90.5294 241.648 90.2454 241.417Z" stroke="url(#paint36_linear_449_29162)" strokeWidth="0.5"/>
<path d="M83.0529 238.711C39.9588 198.349 36.2461 129.017 74.764 83.8546C113.282 38.692 179.442 34.804 222.536 75.1664C223.449 76.0219 224.062 77.4531 224.334 79.4379C224.606 81.419 224.534 83.9214 224.107 86.8784C223.253 92.7913 220.984 100.48 217.262 109.365C209.819 127.133 196.579 149.651 177.291 172.267C158.007 194.878 135.679 212.695 117.525 224.25C108.448 230.028 100.42 234.237 94.3428 236.696C91.3029 237.926 88.7599 238.715 86.8222 239.047C85.853 239.212 85.0448 239.262 84.4063 239.199C83.7638 239.136 83.3216 238.963 83.0529 238.711Z" stroke="url(#paint37_linear_449_29162)" strokeWidth="0.5"/>
<path d="M75.1072 235.022C35.1467 191.259 36.6067 121.836 78.3667 79.96C120.127 38.0846 186.374 39.6135 226.335 83.3766C227.182 84.3048 227.687 85.7823 227.81 87.7832C227.934 89.7806 227.676 92.2694 227.03 95.1824C225.739 101.007 222.905 108.487 218.533 117.041C209.79 134.146 194.915 155.514 174.004 176.483C153.095 197.45 129.509 213.388 110.551 223.424C101.072 228.442 92.7553 231.982 86.5136 233.937C83.3915 234.915 80.7976 235.494 78.8412 235.666C77.8627 235.752 77.0532 235.735 76.4212 235.62C75.7854 235.505 75.3569 235.296 75.1072 235.022Z" stroke="url(#paint38_linear_449_29162)" strokeWidth="0.5"/>
<path d="M68.2155 231.151C31.2793 184.558 37.3811 115.413 81.838 76.7093C126.295 38.0057 192.277 44.3955 229.214 90.9879C229.997 91.9767 230.401 93.4882 230.391 95.4932C230.381 97.4947 229.957 99.9585 229.118 102.817C227.44 108.532 224.113 115.785 219.18 123.997C209.314 140.418 193.047 160.64 170.784 180.022C148.525 199.4 123.931 213.566 104.349 222.185C94.557 226.495 86.0242 229.415 79.6672 230.907C76.4874 231.653 73.8612 232.04 71.8982 232.067C70.9164 232.081 70.11 232.005 69.4872 231.845C68.8607 231.683 68.4468 231.443 68.2155 231.151Z" stroke="url(#paint39_linear_449_29162)" strokeWidth="0.5"/>
<path d="M86.0045 225.682C52.8538 176.059 64.3702 107.675 111.716 72.9407C159.061 38.2064 224.318 50.2667 257.468 99.8902C258.172 100.944 258.456 102.486 258.288 104.483C258.121 106.477 257.505 108.896 256.444 111.672C254.323 117.223 250.437 124.164 244.875 131.922C233.754 147.435 215.953 166.185 192.242 183.579C168.536 200.971 142.913 212.967 122.72 219.866C112.623 223.316 103.89 225.49 97.4373 226.428C94.2098 226.897 91.5622 227.056 89.6038 226.915C88.6242 226.844 87.8265 226.698 87.2184 226.484C86.6067 226.269 86.2126 225.994 86.0045 225.682Z" stroke="url(#paint40_linear_449_29162)" strokeWidth="0.5"/>
<path d="M77.4308 218.739C49.0358 165.974 66.8388 99.0861 117.178 69.3371C167.517 39.5882 231.348 58.2326 259.743 110.998C260.346 112.12 260.486 113.684 260.133 115.654C259.782 117.622 258.945 119.966 257.631 122.621C255.006 127.93 250.495 134.443 244.241 141.597C231.734 155.905 212.279 172.754 187.069 187.652C161.862 202.548 135.249 211.878 114.512 216.69C104.143 219.095 95.25 220.369 88.7412 220.646C85.4855 220.785 82.8358 220.673 80.8998 220.333C79.9315 220.163 79.151 219.937 78.5656 219.662C77.9768 219.386 77.6095 219.071 77.4308 218.739Z" stroke="url(#paint41_linear_449_29162)" strokeWidth="0.5"/>
<path d="M69.4922 210.911C46.1497 155.489 70.103 90.7442 122.972 66.2906C175.841 41.8369 237.63 66.9233 260.972 122.345C261.468 123.524 261.461 125.095 260.927 127.02C260.393 128.942 259.342 131.19 257.788 133.698C254.68 138.712 249.584 144.732 242.693 151.212C228.912 164.171 207.981 178.948 181.503 191.195C155.029 203.44 127.674 210.001 106.588 212.667C96.0441 214 87.0747 214.358 80.5713 213.967C77.3183 213.772 74.6918 213.39 72.7968 212.853C71.8491 212.585 71.0934 212.28 70.5362 211.946C69.976 211.611 69.6393 211.26 69.4922 210.911Z" stroke="url(#paint42_linear_449_29162)" strokeWidth="0.5"/>
<path d="M63.7597 204.163C44.5734 147.015 73.2307 84.4042 127.743 64.3056C182.256 44.2071 242.01 74.2211 261.197 131.369C261.605 132.585 261.482 134.151 260.807 136.027C260.133 137.9 258.919 140.056 257.185 142.43C253.717 147.178 248.192 152.767 240.844 158.67C226.149 170.474 204.19 183.511 176.889 193.577C149.591 203.642 121.834 207.968 100.615 208.919C90.0045 209.394 81.0357 209.024 74.5806 208.108C71.3518 207.65 68.7613 207.056 66.9117 206.368C65.9866 206.023 65.2556 205.658 64.7247 205.281C64.1909 204.901 63.8808 204.524 63.7597 204.163Z" stroke="url(#paint43_linear_449_29162)" strokeWidth="0.5"/>
<path d="M59.0349 197.714C43.6878 139.305 76.432 78.9353 132.146 62.8591C187.859 46.7828 245.477 81.0787 260.824 139.488C261.151 140.731 260.924 142.285 260.126 144.107C259.33 145.926 257.976 147.989 256.088 150.231C252.313 154.714 246.431 159.887 238.709 165.24C223.266 175.944 200.496 187.349 172.592 195.401C144.693 203.451 116.716 205.744 95.4851 205.145C84.8692 204.846 75.9466 203.824 69.568 202.439C66.3775 201.747 63.8327 200.966 62.0333 200.144C61.1334 199.733 60.4283 199.316 59.9238 198.9C59.4165 198.482 59.1319 198.083 59.0349 197.714Z" stroke="url(#paint44_linear_449_29162)" strokeWidth="0.5"/>
<path d="M54.185 189.946C43.3131 130.444 80.5301 72.9901 137.285 61.6021C194.04 50.214 248.877 89.1966 259.749 148.699C259.981 149.966 259.636 151.496 258.703 153.246C257.772 154.994 256.265 156.937 254.214 159.015C250.111 163.17 243.856 167.836 235.752 172.53C219.547 181.915 195.984 191.389 167.559 197.092C139.138 202.795 111.075 202.752 89.9569 200.388C79.3972 199.206 70.5803 197.444 64.3269 195.534C61.1989 194.578 58.7215 193.587 56.99 192.619C56.1241 192.134 55.4529 191.659 54.9814 191.203C54.5074 190.744 54.2538 190.323 54.185 189.946Z" stroke="url(#paint45_linear_449_29162)" strokeWidth="0.5"/>
<path d="M49.6875 181.287C43.6444 121.034 85.3543 67.0764 142.823 60.7465C200.292 54.4167 251.796 98.1078 257.839 158.36C257.967 159.643 257.501 161.138 256.431 162.799C255.361 164.458 253.704 166.261 251.493 168.15C247.07 171.928 240.461 176.024 232.009 179.984C215.106 187.903 190.865 195.26 162.081 198.431C133.303 201.6 105.344 199.077 84.4912 194.854C74.0644 192.743 65.4208 190.208 59.3435 187.751C56.3037 186.522 53.9148 185.316 52.2676 184.198C51.4437 183.638 50.8131 183.106 50.38 182.61C49.9446 182.111 49.7258 181.668 49.6875 181.287Z" stroke="url(#paint46_linear_449_29162)" strokeWidth="0.5"/>
<path d="M46.6317 174.154C44.4504 113.612 89.5132 62.7011 147.257 60.4163C205.001 58.1316 253.599 105.337 255.78 165.878C255.827 167.168 255.266 168.626 254.092 170.209C252.919 171.789 251.15 173.472 248.823 175.201C244.169 178.66 237.313 182.284 228.627 185.642C211.256 192.358 186.6 197.998 157.678 199.143C128.762 200.287 101.027 195.808 80.4908 190.132C70.2222 187.294 61.7599 184.159 55.8532 181.281C52.8987 179.842 50.5922 178.471 49.0201 177.239C48.2338 176.624 47.6387 176.048 47.2382 175.523C46.8356 174.994 46.6455 174.537 46.6317 174.154Z" stroke="url(#paint47_linear_449_29162)" strokeWidth="0.5"/>
<path d="M42.9739 163.295C46.4935 102.823 96.1224 56.8105 153.8 60.4972C211.478 64.1838 255.403 116.176 251.883 176.648C251.808 177.936 251.113 179.329 249.796 180.783C248.48 182.235 246.562 183.726 244.084 185.207C239.127 188.169 231.964 191.068 223.004 193.513C205.087 198.403 180.02 201.471 151.132 199.624C122.249 197.778 95.0697 190.459 75.1665 182.691C65.2146 178.807 57.0882 174.813 51.4807 171.34C48.6758 169.602 46.5093 168 45.0606 166.612C44.3361 165.918 43.7979 165.284 43.4488 164.72C43.0978 164.153 42.9516 163.678 42.9739 163.295Z" stroke="url(#paint48_linear_449_29162)" strokeWidth="0.5"/>
<path d="M40.6693 153.751C49.0326 93.8042 102.18 52.3313 159.358 61.0916C216.536 69.8518 256.129 125.534 247.766 185.48C247.588 186.757 246.783 188.084 245.354 189.416C243.926 190.747 241.895 192.064 239.306 193.321C234.13 195.835 226.76 198.092 217.635 199.739C199.388 203.031 174.164 203.877 145.526 199.49C116.894 195.103 90.399 185.412 71.1903 175.916C61.5855 171.168 53.8087 166.472 48.4999 162.517C45.8445 160.538 43.8144 158.751 42.4822 157.24C41.816 156.484 41.3306 155.805 41.0281 155.212C40.7239 154.616 40.6164 154.13 40.6693 153.751Z" stroke="url(#paint49_linear_449_29162)" strokeWidth="0.5"/>
<path d="M39.2486 144.969C51.9486 85.8659 107.958 48.7594 164.333 62.0625C220.708 75.3656 256.135 134.049 243.435 193.152C243.164 194.41 242.266 195.669 240.744 196.884C239.224 198.096 237.102 199.247 234.43 200.294C229.085 202.387 221.572 204.049 212.354 204.961C193.921 206.787 168.708 205.615 140.474 198.952C112.243 192.29 86.5304 180.511 68.0683 169.508C58.837 164.007 51.4243 158.703 46.4187 154.335C43.915 152.15 42.0208 150.205 40.8024 148.593C40.1931 147.786 39.7586 147.07 39.5 146.454C39.2401 145.836 39.1683 145.343 39.2486 144.969Z" stroke="url(#paint50_linear_449_29162)" strokeWidth="0.5"/>
<path d="M231.188 104.33C231.188 164.915 184.324 214.008 126.539 214.008C68.7541 214.008 21.8904 164.915 21.8904 104.33C21.8904 103.04 22.5033 101.605 23.7338 100.07C24.9624 98.5372 26.7905 96.9258 29.1784 95.2894C33.9537 92.017 40.9347 88.6666 49.7359 85.6541C67.3359 79.6299 92.1782 74.9689 121.12 74.9689C150.057 74.9689 177.611 80.541 197.928 87.0248C208.087 90.2668 216.431 93.7346 222.23 96.8437C225.13 98.3988 227.386 99.8597 228.912 101.153C229.676 101.799 230.25 102.398 230.631 102.939C231.014 103.483 231.188 103.947 231.188 104.33Z" stroke="url(#paint51_linear_449_29162)" strokeWidth="0.5"/>
<path d="M233.744 112.594C229.449 173.011 179.235 218.32 121.609 213.821C63.9837 209.323 20.73 156.716 25.0251 96.2992C25.1165 95.0127 25.8295 93.6291 27.1654 92.1941C28.4993 90.7612 30.4365 89.2966 32.9339 87.8506C37.928 84.959 45.1272 82.1614 54.1177 79.8425C72.0962 75.2051 97.2003 72.4911 126.062 74.7443C154.919 76.9972 182.002 84.6992 201.803 92.7468C211.705 96.7708 219.779 100.879 225.342 104.431C228.124 106.207 230.27 107.84 231.7 109.248C232.416 109.952 232.946 110.594 233.288 111.163C233.631 111.735 233.772 112.212 233.744 112.594Z" stroke="url(#paint52_linear_449_29162)" strokeWidth="0.5"/>
<path d="M235.564 120.296C227.36 180.267 174.323 221.894 117.122 213.301C59.9212 204.707 20.1803 149.141 28.3845 89.17C28.5592 87.8931 29.3602 86.5635 30.7861 85.227C32.2098 83.8925 34.2376 82.5693 36.823 81.3045C41.9931 78.7754 49.3572 76.4971 58.4774 74.8239C76.7151 71.4781 101.937 70.5587 130.586 74.8628C159.231 79.1662 185.751 88.7796 204.985 98.2193C214.602 102.939 222.391 107.613 227.711 111.553C230.371 113.524 232.406 115.305 233.742 116.812C234.41 117.566 234.898 118.243 235.202 118.836C235.507 119.431 235.616 119.916 235.564 120.296Z" stroke="url(#paint53_linear_449_29162)" strokeWidth="0.5"/>
<path d="M236.824 127.721C224.945 187.011 169.455 224.966 112.902 212.522C56.3487 200.078 20.1127 141.94 31.9925 82.6497C32.2454 81.3876 33.1265 80.115 34.6317 78.8777C36.1347 77.6422 38.2397 76.4589 40.8976 75.3716C46.2127 73.1973 53.7018 71.4218 62.906 70.3688C81.3118 68.2632 106.538 69.0513 134.862 75.2838C163.182 81.5153 189.055 92.9021 207.668 103.623C216.974 108.983 224.46 114.174 229.525 118.465C232.059 120.612 233.98 122.527 235.221 124.122C235.841 124.919 236.285 125.628 236.552 126.24C236.821 126.854 236.9 127.346 236.824 127.721Z" stroke="url(#paint54_linear_449_29162)" strokeWidth="0.5"/>
<path d="M237.593 134.756C222.308 193.183 164.726 227.546 108.996 211.535C53.2649 195.524 20.4564 135.192 35.7413 76.7654C36.0666 75.522 37.0195 74.3078 38.5934 73.1683C40.165 72.0305 42.3346 70.983 45.0505 70.0664C50.4816 68.2334 58.0596 66.9365 67.3078 66.4698C85.8017 65.5363 110.936 67.9243 138.848 75.9433C166.756 83.9609 191.924 96.9692 209.882 108.852C218.862 114.793 226.034 120.449 230.842 125.055C233.247 127.358 235.054 129.392 236.2 131.062C236.773 131.897 237.176 132.633 237.407 133.261C237.639 133.891 237.689 134.387 237.593 134.756Z" stroke="url(#paint55_linear_449_29162)" strokeWidth="0.5"/>
<path d="M237.971 142.922C218.824 200.084 159.09 230.143 104.564 210.085C50.0371 190.028 21.3372 127.438 40.4845 70.276C40.8919 69.0599 41.9232 67.9186 43.5694 66.8973C45.2131 65.8775 47.4473 64.9916 50.2178 64.2764C55.758 62.846 63.4041 62.1078 72.6609 62.3202C91.1719 62.7448 116.086 66.9695 143.394 77.0151C170.698 87.059 194.936 101.881 212.058 115.05C220.619 121.636 227.395 127.804 231.884 132.75C234.13 135.224 235.796 137.386 236.828 139.136C237.344 140.011 237.697 140.775 237.885 141.418C238.075 142.064 238.092 142.561 237.971 142.922Z" stroke="url(#paint56_linear_449_29162)" strokeWidth="0.5"/>
<path d="M237.845 150.243C215.318 206.035 153.905 232.113 100.684 208.514C47.4629 184.915 22.5624 120.564 45.0893 64.7725C45.5684 63.586 46.6657 62.5148 48.3697 61.6036C50.0711 60.6937 52.3539 59.9562 55.1618 59.4243C60.7767 58.3605 68.452 58.1258 77.678 58.9455C96.1273 60.5847 120.74 66.437 147.394 78.2559C174.044 90.073 197.348 106.457 213.649 120.725C221.8 127.86 228.194 134.461 232.379 139.692C234.472 142.309 236.006 144.576 236.931 146.39C237.394 147.297 237.7 148.083 237.85 148.736C238.001 149.394 237.987 149.891 237.845 150.243Z" stroke="url(#paint57_linear_449_29162)" strokeWidth="0.5"/>
<path d="M237.048 159.591C210.318 213.304 147.104 234.129 95.8624 206.125C44.6209 178.12 24.7356 111.88 51.4661 58.1673C52.0343 57.0255 53.2097 56.0503 54.9781 55.2855C56.7437 54.5218 59.0757 53.9789 61.9154 53.685C67.5938 53.0974 75.2624 53.5097 84.3958 55.1035C102.66 58.2907 126.744 66.1962 152.407 80.2211C178.065 94.2438 200.038 112.537 215.192 128.131C222.77 135.929 228.637 143.047 232.408 148.614C234.293 151.399 235.649 153.787 236.432 155.674C236.824 156.617 237.068 157.426 237.168 158.09C237.268 158.758 237.216 159.252 237.048 159.591Z" stroke="url(#paint58_linear_449_29162)" strokeWidth="0.5"/>
<path d="M235.505 168.981C204.689 220.232 140.067 235.593 91.1711 203.307C42.275 171.02 27.6064 103.302 58.4223 52.0515C59.0769 50.9627 60.3237 50.0912 62.1458 49.4798C63.9649 48.8695 66.3314 48.5274 69.1845 48.4769C74.8899 48.376 82.5011 49.4415 91.4804 51.8097C109.436 56.5455 132.826 66.4809 157.314 82.65C181.797 98.8165 202.275 118.925 216.167 135.761C223.113 144.18 228.408 151.775 231.733 157.646C233.396 160.582 234.561 163.078 235.195 165.025C235.512 165.999 235.693 166.826 235.741 167.497C235.788 168.171 235.699 168.659 235.505 168.981Z" stroke="url(#paint59_linear_449_29162)" strokeWidth="0.5"/>
<path d="M233.586 176.965C199.401 225.811 133.907 236.363 87.3024 200.543C40.6982 164.724 30.6225 96.0911 64.808 47.2454C65.5338 46.2083 66.8356 45.4312 68.6944 44.9559C70.55 44.4814 72.9336 44.315 75.7831 44.4754C81.4811 44.7962 89.0019 46.4213 97.7997 49.447C115.393 55.4974 138.056 67.1359 161.395 85.0737C184.731 103.009 203.805 124.58 216.53 142.401C222.893 151.312 227.664 159.279 230.585 165.381C232.047 168.433 233.041 171.009 233.543 172.998C233.794 173.993 233.919 174.831 233.921 175.503C233.923 176.18 233.801 176.659 233.586 176.965Z" stroke="url(#paint60_linear_449_29162)" strokeWidth="0.5"/>
<path d="M231.046 185.052C193.556 231.156 127.502 236.68 83.511 197.397C39.5195 158.113 34.2442 88.8927 71.7337 42.7886C72.5292 41.8103 73.8811 41.1347 75.768 40.8026C77.6516 40.4711 80.0404 40.4871 82.8713 40.8647C88.5321 41.6197 95.92 43.8148 104.484 47.5042C121.61 54.882 143.404 68.2202 165.434 87.8925C187.461 107.562 204.985 130.532 216.437 149.277C222.163 158.651 226.368 166.961 228.858 173.27C230.103 176.426 230.916 179.071 231.278 181.093C231.459 182.104 231.525 182.95 231.48 183.621C231.435 184.295 231.28 184.763 231.046 185.052Z" stroke="url(#paint61_linear_449_29162)" strokeWidth="0.5"/>
<path d="M228.134 192.508C187.702 235.793 121.443 236.535 80.1379 194.166C38.8333 151.797 38.1246 82.3606 78.5557 39.0752C79.4131 38.1573 80.8057 37.581 82.71 37.3859C84.6109 37.1912 86.9931 37.3797 89.7925 37.9609C95.3902 39.123 102.616 41.8465 110.918 46.1457C127.518 54.743 148.383 69.6237 169.067 90.8405C189.748 112.054 205.72 136.236 215.912 155.764C221.008 165.528 224.656 174.123 226.725 180.597C227.76 183.835 228.397 186.533 228.625 188.576C228.739 189.598 228.75 190.446 228.661 191.112C228.572 191.782 228.386 192.237 228.134 192.508Z" stroke="url(#paint62_linear_449_29162)" strokeWidth="0.5"/>
<path d="M224.857 199.476C181.77 239.846 115.609 235.97 77.0843 190.814C38.5592 145.658 42.2609 76.3257 85.3486 35.9558C86.2618 35.1002 87.6874 34.6222 89.6 34.5603C91.509 34.4986 93.8739 34.8527 96.6303 35.6277C102.142 37.1775 109.179 40.3987 117.189 45.2671C133.207 55.0026 153.082 71.3049 172.374 93.9167C191.662 116.525 206.064 141.767 214.994 161.962C219.459 172.06 222.554 180.89 224.207 187.494C225.034 190.797 225.499 193.533 225.597 195.588C225.645 196.616 225.602 197.463 225.472 198.121C225.34 198.783 225.126 199.224 224.857 199.476Z" stroke="url(#paint63_linear_449_29162)" strokeWidth="0.5"/>
<path d="M221.471 205.673C176.113 243.209 110.301 235.107 74.4787 187.569C38.6559 140.03 46.3924 71.0648 91.7509 33.5287C92.7117 32.7336 94.162 32.3477 96.0747 32.4083C97.9837 32.4689 100.324 32.9737 103.03 33.9237C108.441 35.8233 115.278 39.4889 122.989 44.8609C138.41 55.6034 157.298 73.1474 175.236 96.9518C193.171 120.752 206.075 146.869 213.812 167.598C217.68 177.963 220.254 186.975 221.52 193.672C222.153 197.022 222.457 199.783 222.435 201.84C222.424 202.87 222.331 203.712 222.162 204.361C221.993 205.013 221.753 205.439 221.471 205.673Z" stroke="url(#paint64_linear_449_29162)" strokeWidth="0.5"/>
<path d="M62.7801 240.288C29.6294 190.665 41.1458 122.281 88.4912 87.5466C135.837 52.8122 201.093 64.8725 234.244 114.496C234.948 115.55 235.232 117.092 235.064 119.089C234.896 121.083 234.28 123.502 233.22 126.278C231.098 131.829 227.213 138.77 221.651 146.528C210.529 162.041 192.728 180.791 169.018 198.185C145.311 215.577 119.689 227.573 99.4956 234.472C89.3986 237.922 80.6653 240.096 74.2128 241.034C70.9853 241.503 68.3377 241.662 66.3793 241.52C65.3997 241.45 64.602 241.304 63.9939 241.09C63.3823 240.875 62.9881 240.6 62.7801 240.288Z" stroke="url(#paint65_linear_449_29162)" strokeWidth="0.5"/>
<path d="M54.2064 233.345C25.8113 180.579 43.6143 113.692 93.9535 83.943C144.293 54.194 208.123 72.8384 236.518 125.604C237.122 126.726 237.261 128.29 236.909 130.26C236.557 132.228 235.72 134.572 234.407 137.227C231.781 142.536 227.271 149.048 221.016 156.203C208.509 170.511 189.054 187.359 163.844 202.258C138.638 217.154 112.025 226.484 91.2877 231.295C80.9188 233.701 72.0255 234.975 65.5167 235.252C62.261 235.391 59.6113 235.279 57.6753 234.939C56.707 234.769 55.9266 234.543 55.3411 234.268C54.7523 233.992 54.385 233.677 54.2064 233.345Z" stroke="url(#paint66_linear_449_29162)" strokeWidth="0.5"/>
<path d="M46.2677 225.517C22.9252 170.095 46.8785 105.35 99.7476 80.8964C152.617 56.4427 214.405 81.5292 237.748 136.951C238.244 138.129 238.237 139.701 237.702 141.626C237.169 143.548 236.117 145.796 234.563 148.303C231.455 153.318 226.36 159.338 219.468 165.818C205.688 178.777 184.756 193.554 158.278 205.8C131.804 218.045 104.449 224.607 83.3631 227.273C72.8196 228.606 63.8502 228.964 57.3468 228.573C54.0938 228.378 51.4673 227.996 49.5723 227.459C48.6246 227.19 47.8689 226.886 47.3117 226.552C46.7516 226.217 46.4148 225.866 46.2677 225.517Z" stroke="url(#paint67_linear_449_29162)" strokeWidth="0.5"/>
<path d="M40.5352 218.769C21.3489 161.621 50.0062 99.01 104.519 78.9115C159.032 58.8129 218.786 88.827 237.972 145.975C238.38 147.191 238.257 148.757 237.583 150.633C236.909 152.506 235.695 154.662 233.96 157.036C230.492 161.784 224.968 167.373 217.619 173.276C202.924 185.08 180.966 198.117 153.664 208.183C126.367 218.248 98.6098 222.574 77.3902 223.524C66.7801 224 57.8112 223.63 51.3561 222.714C48.1273 222.256 45.5369 221.662 43.6872 220.974C42.7621 220.629 42.0311 220.264 41.5002 219.886C40.9664 219.507 40.6563 219.129 40.5352 218.769Z" stroke="url(#paint68_linear_449_29162)" strokeWidth="0.5"/>
<path d="M35.8104 212.318C20.4633 153.909 53.2075 93.5389 108.921 77.4626C164.635 61.3864 222.253 95.6823 237.6 154.091C237.926 155.334 237.699 156.888 236.902 158.71C236.106 160.53 234.751 162.592 232.864 164.834C229.089 169.318 223.207 174.49 215.484 179.843C200.042 190.548 177.271 201.953 149.368 210.004C121.469 218.055 93.4917 220.348 72.2606 219.749C61.6446 219.449 52.722 218.427 46.3435 217.043C43.1529 216.35 40.6082 215.569 38.8088 214.748C37.9088 214.337 37.2038 213.919 36.6992 213.504C36.1919 213.086 35.9074 212.687 35.8104 212.318Z" stroke="url(#paint69_linear_449_29162)" strokeWidth="0.5"/>
<path d="M30.9605 204.552C20.0886 145.049 57.3056 87.596 114.061 76.208C170.816 64.8199 225.653 103.802 236.525 163.305C236.756 164.572 236.412 166.102 235.479 167.852C234.547 169.6 233.041 171.543 230.989 173.621C226.886 177.776 220.631 182.442 212.527 187.135C196.322 196.521 172.76 205.994 144.334 211.698C115.913 217.401 87.8509 217.358 66.7323 214.994C56.1726 213.812 47.3558 212.05 41.1023 210.139C37.9744 209.184 35.4969 208.193 33.7655 207.224C32.8995 206.74 32.2284 206.265 31.7569 205.809C31.2828 205.35 31.0293 204.929 30.9605 204.552Z" stroke="url(#paint70_linear_449_29162)" strokeWidth="0.5"/>
<path d="M26.463 195.893C20.4199 135.64 62.1298 81.6823 119.599 75.3524C177.067 69.0226 228.571 112.714 234.614 172.966C234.743 174.249 234.277 175.744 233.206 177.405C232.137 179.064 230.48 180.867 228.268 182.756C223.845 186.533 217.237 190.63 208.784 194.59C191.881 202.509 167.64 209.866 138.857 213.036C110.078 216.206 82.1197 213.683 61.2667 209.46C50.8399 207.349 42.1963 204.814 36.119 202.357C33.0792 201.128 30.6903 199.922 29.0431 198.804C28.2192 198.244 27.5887 197.712 27.1555 197.216C26.7201 196.717 26.5013 196.274 26.463 195.893Z" stroke="url(#paint71_linear_449_29162)" strokeWidth="0.5"/>
<path d="M23.4072 188.757C21.2259 128.216 66.2888 77.3046 124.033 75.0198C181.777 72.7351 230.375 119.941 232.556 180.482C232.602 181.771 232.041 183.23 230.867 184.812C229.695 186.393 227.926 188.075 225.598 189.805C220.944 193.264 214.089 196.888 205.402 200.246C188.032 206.962 163.375 212.602 134.454 213.746C105.537 214.89 77.8027 210.411 57.2663 204.736C46.9977 201.898 38.5354 198.762 32.6287 195.885C29.6742 194.445 27.3677 193.075 25.7956 191.843C25.0093 191.227 24.4142 190.652 24.0137 190.126C23.6111 189.598 23.421 189.141 23.4072 188.757Z" stroke="url(#paint72_linear_449_29162)" strokeWidth="0.5"/>
<path d="M19.7494 177.899C23.269 117.427 72.8979 71.414 130.576 75.1007C188.254 78.7873 232.178 130.779 228.659 191.252C228.584 192.539 227.889 193.933 226.571 195.387C225.256 196.838 223.338 198.33 220.859 199.811C215.903 202.773 208.74 205.671 199.78 208.117C181.863 213.007 156.796 216.074 127.907 214.228C99.0241 212.382 71.8452 205.062 51.942 197.294C41.9901 193.41 33.8637 189.416 28.2562 185.943C25.4513 184.206 23.2848 182.604 21.8361 181.216C21.1116 180.522 20.5734 179.888 20.2243 179.323C19.8733 178.756 19.7271 178.281 19.7494 177.899Z" stroke="url(#paint73_linear_449_29162)" strokeWidth="0.5"/>
<path d="M17.4449 168.357C25.8082 108.41 78.9558 66.9372 136.134 75.6974C193.311 84.4577 232.905 140.139 224.541 200.086C224.363 201.362 223.559 202.69 222.129 204.022C220.702 205.352 218.671 206.67 216.082 207.927C210.905 210.441 203.535 212.698 194.411 214.344C176.164 217.637 150.939 218.483 122.302 214.096C93.6693 209.709 67.1746 200.018 47.9658 190.522C38.3611 185.774 30.5842 181.078 25.2755 177.123C22.6201 175.144 20.5899 173.357 19.2578 171.846C18.5915 171.09 18.1062 170.411 17.8037 169.818C17.4995 169.222 17.392 168.736 17.4449 168.357Z" stroke="url(#paint74_linear_449_29162)" strokeWidth="0.5"/>
<path d="M16.0289 159.573C28.7288 100.469 84.7386 63.3629 141.113 76.666C197.488 89.9691 232.915 148.652 220.215 207.756C219.945 209.014 219.046 210.273 217.524 211.487C216.004 212.7 213.883 213.851 211.21 214.897C205.865 216.991 198.352 218.652 189.135 219.565C170.701 221.39 145.489 220.218 117.254 213.556C89.0233 206.894 63.3106 195.115 44.8486 184.112C35.6172 178.61 28.2045 173.306 23.199 168.938C20.6952 166.753 18.801 164.809 17.5826 163.196C16.9733 162.39 16.5388 161.674 16.2802 161.058C16.0204 160.439 15.9485 159.947 16.0289 159.573Z" stroke="url(#paint75_linear_449_29162)" strokeWidth="0.5"/>
<path d="M12.8266 133.809C31.0105 76.3019 90.2301 45.1457 145.085 64.194C199.94 83.2424 229.687 145.292 211.503 202.799C211.116 204.023 210.104 205.183 208.475 206.235C206.849 207.284 204.63 208.211 201.872 208.978C196.357 210.51 188.724 211.389 179.465 211.348C160.95 211.265 135.969 207.501 108.496 197.96C81.0276 188.422 56.5446 174.05 39.2044 161.198C30.5339 154.772 23.6548 148.73 19.0833 143.867C16.7967 141.435 15.0941 139.304 14.0329 137.574C13.5022 136.708 13.137 135.951 12.9375 135.312C12.7369 134.669 12.7118 134.172 12.8266 133.809Z" stroke="url(#paint76_linear_449_29162)" strokeWidth="0.5"/>
<path d="M13.0879 121.496C36.9456 66.3163 98.9648 41.8632 151.604 66.8568C204.242 91.8504 227.591 156.837 203.734 212.017C203.226 213.191 202.104 214.233 200.378 215.099C198.656 215.963 196.356 216.641 193.536 217.098C187.897 218.014 180.219 218.046 171.015 216.984C152.611 214.859 128.147 208.361 101.784 195.843C75.4259 183.328 52.5221 166.335 36.5688 151.642C28.5918 144.295 22.3577 137.528 18.2999 132.188C16.2702 129.517 14.791 127.21 13.9095 125.372C13.4686 124.453 13.1815 123.66 13.0473 123.002C12.9124 122.341 12.9375 121.844 13.0879 121.496Z" stroke="url(#paint77_linear_449_29162)" strokeWidth="0.5"/>
<path d="M14.9443 107.247C45.0892 55.5591 109.505 39.274 158.817 70.8571C208.129 102.44 223.68 169.942 193.535 221.629C192.894 222.728 191.659 223.617 189.845 224.254C188.034 224.891 185.672 225.267 182.82 225.358C177.117 225.541 169.492 224.584 160.483 222.345C142.467 217.866 118.949 208.267 94.2538 192.45C69.5621 176.636 48.8238 156.823 34.7138 140.187C27.6586 131.868 22.265 124.349 18.8639 118.527C17.1627 115.615 15.965 113.135 15.3057 111.198C14.9759 110.229 14.784 109.404 14.728 108.734C14.6716 108.061 14.7548 107.572 14.9443 107.247Z" stroke="url(#paint78_linear_449_29162)" strokeWidth="0.5"/>
<path d="M17.4394 96.7295C52.0272 48.1961 117.606 38.2394 163.913 74.4805C210.219 110.722 219.727 179.444 185.139 227.977C184.405 229.007 183.097 229.773 181.234 230.231C179.375 230.689 176.99 230.833 174.142 230.647C168.447 230.275 160.94 228.581 152.167 225.476C134.625 219.266 112.058 207.422 88.8683 189.273C65.6822 171.127 46.7865 149.384 34.2095 131.448C27.9207 122.479 23.2157 114.469 20.3445 108.341C18.9083 105.276 17.9352 102.69 17.4501 100.697C17.2075 99.6999 17.0894 98.8605 17.0928 98.1883C17.0963 97.5122 17.2225 97.0338 17.4394 96.7295Z" stroke="url(#paint79_linear_449_29162)" strokeWidth="0.5"/>
<defs>
<linearGradient id="paint0_linear_449_29162" x1="149.764" y1="60.113" x2="149.764" y2="199.653" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear_449_29162" x1="154.708" y1="60.3109" x2="143.851" y2="199.389" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint2_linear_449_29162" x1="159.209" y1="60.8175" x2="138.498" y2="198.672" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint3_linear_449_29162" x1="163.444" y1="61.6022" x2="133.519" y2="197.6" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint4_linear_449_29162" x1="167.362" y1="62.597" x2="128.964" y2="196.254" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint5_linear_449_29162" x1="171.816" y1="64.0531" x2="123.899" y2="194.313" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint6_linear_449_29162" x1="175.703" y1="65.6311" x2="119.557" y2="192.252" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint7_linear_449_29162" x1="180.548" y1="68.0173" x2="114.32" y2="189.198" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint8_linear_449_29162" x1="185.252" y1="70.8573" x2="109.413" y2="185.71" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint9_linear_449_29162" x1="189.138" y1="73.624" x2="105.528" y2="182.409" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint10_linear_449_29162" x1="192.946" y1="76.7783" x2="101.865" y2="178.774" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint11_linear_449_29162" x1="196.335" y1="80.0268" x2="98.7354" y2="175.174" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint12_linear_449_29162" x1="199.393" y1="83.3876" x2="96.0206" y2="171.58" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint13_linear_449_29162" x1="202.011" y1="86.6457" x2="93.7944" y2="168.193" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint14_linear_449_29162" x1="178.882" y1="90.3347" x2="66.2898" y2="165.074" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint15_linear_449_29162" x1="181.056" y1="93.8429" x2="64.5589" y2="161.663" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint16_linear_449_29162" x1="182.705" y1="96.8262" x2="63.3064" y2="158.827" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint17_linear_449_29162" x1="184.037" y1="99.5402" x2="62.3128" y2="156.328" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint18_linear_449_29162" x1="185.304" y1="102.422" x2="61.3837" y2="153.692" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint19_linear_449_29162" x1="186.475" y1="105.428" x2="60.5651" y2="151.025" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint20_linear_449_29162" x1="187.857" y1="109.661" x2="59.5958" y2="147.312" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint21_linear_449_29162" x1="188.988" y1="114" x2="58.8367" y2="143.61" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint22_linear_449_29162" x1="189.578" y1="116.886" x2="58.4384" y2="141.186" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint23_linear_449_29162" x1="190.135" y1="120.353" x2="58.0729" y2="138.283" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint24_linear_449_29162" x1="190.579" y1="124.52" x2="57.7839" y2="134.827" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint25_linear_449_29162" x1="190.778" y1="128.252" x2="57.6636" y2="131.75" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint26_linear_449_29162" x1="190.762" y1="132.52" x2="57.668" y2="128.26" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint27_linear_449_29162" x1="190.491" y1="136.907" x2="57.846" y2="124.643" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint28_linear_449_29162" x1="189.948" y1="141.326" x2="58.2003" y2="120.99" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint29_linear_449_29162" x1="189.153" y1="145.62" x2="58.7263" y2="117.382" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint30_linear_449_29162" x1="188.074" y1="149.936" x2="59.4439" y2="113.705" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint31_linear_449_29162" x1="186.621" y1="154.515" x2="60.454" y2="109.713" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint32_linear_449_29162" x1="185.275" y1="158.013" x2="61.4158" y2="106.579" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint33_linear_449_29162" x1="183.537" y1="161.87" x2="62.6782" y2="103.076" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint34_linear_449_29162" x1="181.62" y1="165.528" x2="64.1275" y2="99.6306" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint35_linear_449_29162" x1="179.258" y1="169.437" x2="65.9759" y2="95.8557" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint36_linear_449_29162" x1="176.786" y1="173.003" x2="67.9957" y2="92.3001" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint37_linear_449_29162" x1="173.863" y1="176.672" x2="70.4767" y2="88.4966" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint38_linear_449_29162" x1="170.257" y1="180.594" x2="73.6912" y2="84.2948" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint39_linear_449_29162" x1="166.771" y1="183.846" x2="76.9302" y2="80.6508" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint40_linear_449_29162" x1="187.942" y1="187.044" x2="106.702" y2="76.3089" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint41_linear_449_29162" x1="182.467" y1="190.662" x2="112.3" y2="71.9298" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint42_linear_449_29162" x1="176.642" y1="193.718" x2="118.526" y2="68.0716" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint43_linear_449_29162" x1="171.856" y1="195.699" x2="123.844" y2="65.477" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint44_linear_449_29162" x1="167.431" y1="197.15" x2="128.878" y2="63.5418" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint45_linear_449_29162" x1="162.281" y1="198.406" x2="134.877" y2="61.8304" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint46_linear_449_29162" x1="156.717" y1="199.273" x2="141.448" y2="60.6465" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint47_linear_449_29162" x1="152.272" y1="199.607" x2="146.756" y2="60.186" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint48_linear_449_29162" x1="145.708" y1="199.528" x2="154.608" y2="60.2982" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint49_linear_449_29162" x1="140.13" y1="198.916" x2="161.241" y2="61.1271" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint50_linear_449_29162" x1="135.134" y1="197.949" x2="167.106" y2="62.4601" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint51_linear_449_29162" x1="126.539" y1="74.7189" x2="126.539" y2="214.258" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint52_linear_449_29162" x1="131.484" y1="74.9169" x2="120.626" y2="213.995" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint53_linear_449_29162" x1="135.984" y1="75.421" x2="115.274" y2="213.276" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint54_linear_449_29162" x1="140.215" y1="76.2057" x2="110.29" y2="212.203" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint55_linear_449_29162" x1="144.138" y1="77.2029" x2="105.739" y2="210.859" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint56_linear_449_29162" x1="148.587" y1="78.6589" x2="100.67" y2="208.919" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint57_linear_449_29162" x1="152.478" y1="80.2371" x2="96.3329" y2="206.858" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint58_linear_449_29162" x1="157.323" y1="82.6233" x2="91.0959" y2="203.804" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint59_linear_449_29162" x1="162.027" y1="85.4631" x2="86.1887" y2="200.316" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint60_linear_449_29162" x1="165.909" y1="88.2275" x2="82.2983" y2="197.012" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint61_linear_449_29162" x1="169.717" y1="91.3818" x2="78.6356" y2="193.378" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint62_linear_449_29162" x1="173.11" y1="94.6303" x2="75.511" y2="189.778" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint63_linear_449_29162" x1="176.168" y1="97.9794" x2="72.7961" y2="186.172" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint64_linear_449_29162" x1="178.787" y1="101.249" x2="70.5699" y2="182.797" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint65_linear_449_29162" x1="164.717" y1="201.65" x2="83.4779" y2="90.9147" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint66_linear_449_29162" x1="159.242" y1="205.268" x2="89.0753" y2="86.5357" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint67_linear_449_29162" x1="153.417" y1="208.324" x2="95.3018" y2="82.6774" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint68_linear_449_29162" x1="148.632" y1="210.305" x2="100.62" y2="80.0828" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint69_linear_449_29162" x1="144.206" y1="211.754" x2="105.654" y2="78.1454" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint70_linear_449_29162" x1="139.056" y1="213.012" x2="111.652" y2="76.4363" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint71_linear_449_29162" x1="133.492" y1="213.879" x2="118.223" y2="75.2524" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint72_linear_449_29162" x1="129.048" y1="214.21" x2="123.531" y2="74.7895" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint73_linear_449_29162" x1="122.484" y1="214.132" x2="131.383" y2="74.9018" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint74_linear_449_29162" x1="116.905" y1="213.522" x2="138.016" y2="75.733" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint75_linear_449_29162" x1="111.914" y1="212.552" x2="143.886" y2="77.0636" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint76_linear_449_29162" x1="103.277" y1="196.413" x2="148.83" y2="65.23" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint77_linear_449_29162" x1="96.749" y1="193.729" x2="156.106" y2="68.7179" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint78_linear_449_29162" x1="89.504" y1="189.705" x2="163.778" y2="73.7376" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
<linearGradient id="paint79_linear_449_29162" x1="84.3813" y1="186.079" x2="168.909" y2="78.0738" gradientUnits="userSpaceOnUse">
<stop stopColor="#0A0A0A"/>
<stop offset="1" stopColor="white" stopOpacity="0"/>
</linearGradient>
</defs>
</svg>
);