import React from 'react';

// Types
import { CustomPageStylesPropTypes } from './types';

export const CustomPageStyles: React.FC<CustomPageStylesPropTypes> = ({ 
  mainColor, 
  mainBgColor 
}) => (
  <span 
    dangerouslySetInnerHTML={{ 
      __html: `
      <style>
        body {
          background-color: ${mainBgColor};
          color: ${mainColor};
        }
      </style>`
    }} 
  />
);