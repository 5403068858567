import { decideid_types, decideid_assets_types, ledger_types } from "../canister_types";
import { useDecideidActor, useDecideidAssetsActor, useLedgerActor } from "../contexts/ActorsContext";

export interface IActors {
  decideid: decideid_types._SERVICE;
  decideid_assets: decideid_assets_types._SERVICE;
  ledger: ledger_types._SERVICE;
}

export function useActors(): IActors {
  const { actor: decideid } = useDecideidActor()
  const { actor: decideid_assets } = useDecideidAssetsActor();
  const { actor: ledger } = useLedgerActor();

  if (process.env.DEV_ENV != "prod" && process.env.DEV_ENV != "production") {
      window["_actors"] = { decideid, decideid_assets, ledger };
  }
  return { decideid, decideid_assets, ledger };
}
