import React from 'react';
import cn from 'classnames';

// Data
import { DATA_SOCIAL } from './data';

export const SocialIcons = () => (
  <div className='flex gap-2 xl:gap-[0.55vw]'>
    {DATA_SOCIAL.map(item => (
      <a 
        key={item.alias}
        href={item.url}
        target="_blank"
        className={cn(
          'border-grey-01 bg-F00 hover:bg-F20 hover:border-F10',
          'p-2 border-C00 border-[1px] rounded-lg',
          'xl:p-[0.5vw] xl:w-[2.7vw] xl:h-[2.7vw] xl:border-[0.1vw] xl:rounded-[0.6vw]'
        )} rel="noreferrer"
      >
        <img
          className='w-full'
          src={`/assets/social/${item.alias}.svg`} 
          alt={`${item.alias}_icon`} 
        />
      </a>
    ))}
  </div>
);