import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

// Hooks
import { useIdentity } from "../../../hooks/useIdentity";

// api
import fetchCreateLiveness from '../fetchersApi/fetchCreateLiveness';
import {useFetchStandard} from '../../../hooks/useFetchStandard';

export const useFetchCreateLiveness = (
  user_id: string,
) => {
  const [isRateLimitError, setRateLimitError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [error, setError] = useState<{ title: string; message: string; } | null>(null);
  const { t } = useTranslation();

  const endpoint = process.env.AWS_RECOGNITION_API_URL || '';
  const { deviceId } = useIdentity();

  const fetchStandard = useFetchStandard();

  const createLivenessSession = async () => {
    setLoading(true);
  
    try {
      const data = await fetchCreateLiveness(user_id, deviceId, endpoint, fetchStandard);
  
      if (data.error) {
        console.log('RATE LIMIT ERROR: ', data.error);
        setRateLimitError(true);
        setError({
          title: 'Rate limit error',
          message: data.error,
        });
      } else {
        console.log("CREATED SESSION", data.sessionId);
        setSessionId(data.sessionId);
      }
    } catch (e) {
      if (e instanceof Error) {
        const matchBody = String(e).match(/Response body: (.*)$/);
        const matchStatus = String(e).match(/\[(\d{3})\]/);
    
        if (matchStatus && matchBody) {
          const errorBody = JSON.parse(matchBody[1]);
    
          if (matchStatus[1] === '500' && errorBody.error.includes('Rate limit exceeded')) {
            setRateLimitError(true);
          } else if (matchStatus[1] === '409' && errorBody.message === 'Device not unique') {
            setError({
              title: t('app.pages.poh.face_liveness.device_error.title'),
              message: t('app.pages.poh.face_liveness.device_error.message')
            });
          } else {
            setError({
              title: t('app.pages.poh.face_liveness.error_message.title'),
              message: t('app.pages.poh.face_liveness.error_message.message')
            });
          }
        }
      }
    } finally {
      setLoading(false);
    }
  };  

  useEffect(() => {
    if (deviceId) {
      createLivenessSession();
    }
  }, [endpoint, deviceId, user_id]);

  return {
    error,
    isLoading,
    sessionId,
    isRateLimitError,
    createLivenessSession,
  }
}
