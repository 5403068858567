import {FetchResponse, FetchStandardProps} from '../../../hooks/useFetchStandard';
import { logdna } from '../../../utils/logger';

const fetchCreateLiveness = async (
  user_id: string,
  deviceId: string | null,
  endpoint: string,
  fetchStandard: (data: FetchStandardProps) => Promise<FetchResponse>
) => {
  try {
    let deviceIdHeaders = {};
    if (deviceId !== null) {
      deviceIdHeaders = { 'DeviceId': deviceId };
    }

    const responceData = await fetchStandard({
      endpoint: `${endpoint}start_liveness_session`,
      method: 'POST',
      body: { user_id, deviceId },
      additionalHeaders: deviceIdHeaders,
      authorize: true
    });

    return responceData;
  } catch (error) {
    logdna.error(`fetchCreateLiveness error for uid ${user_id}: ${error} `);
    console.error(error);
    throw error;
  }
};

export default fetchCreateLiveness;
